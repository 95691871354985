import MerchantVenueNavPills from "../../../ui/merchantVenueNavPills/merchantVenueNavPills";

const MerchantVenueAddDetailsYourPage = (props) => {
  return (
    <>
      <MerchantVenueNavPills />
      <h1>Your Page</h1>
    </>
  );
};

export default MerchantVenueAddDetailsYourPage;
