import { useContext } from "react";
import Button from '../../ui/button/button';
import AuthContext from "../../../store/auth-context";

const ForgotPasswordReset = props => {
  const ctx = useContext(AuthContext);
  return (
    <div id="registerLogin__rightSide">
      <div id="registerLogin_rightSide__topLink">
          <span className="registerLogin_rightSide__topLink__copy">
            Don't have an account?
          </span>
          <span className="registerLogin_rightSide__topLink__link" onClick={ctx.signUpOnClick} >
            Sign Up
          </span>
      </div>
      <div id="registerLogin__rightSide__registerForm">
          <div id="registerLogin__rightSide__registerForm__copy">
            <h1>Reset Password</h1>
            <p>Please check your email for your Reset Code and enter your Reset Code and New Password below <br/><br/></p>
          </div>
          <div id="registerLogin__rightSide__registerForm__form">
            <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
              <label htmlFor="forgotPasswordResetCode">Reset Code From Email</label>
              <input type="text" id="forgotPasswordEmail" onChange={ctx.forgotPasswordResetCodeOnChange}></input>
            </div>
            <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
              <label htmlFor="forgotPasswordPassword">New Password</label>
              <input type="text" id="forgotPasswordPassword" onChange={ctx.forgotPasswordResetPasswordOnChange}></input>
            </div>
            <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
              <label htmlFor="forgotPasswordPasswordConfirm">Re-type your new password</label>
              <input type="text" id="forgotPasswordPasswordConfirm" onChange={ctx.forgotPasswordResetPasswordConfirmOnChange}></input>
            </div>
          </div>
        </div>
      <div id="registerLogin__rightSide__registerForm__buttonWrapper">
        <Button className="button-active" symbol="rightArrow" onClick={ctx.forgotPasswordResetPasswordButonOnClick}>Send</Button>
        </div>
    </div>
  )
}

export default ForgotPasswordReset;