import { useContext } from "react";
// import {  useHistory } from 'react-router-dom';
import RightChevronIcon from "../../../globals/icons/rightChevronIcon";
import SidebarBookingsIcon from "../../../globals/icons/sidebarBoookingsIcon";
import SidebarDashboardIcon from "../../../globals/icons/sidebarDashboardIcon";
import SidebarEventsIcon from "../../../globals/icons/sidebarEventsIcon";
import SidebarExitIcon from "../../../globals/icons/sidebarExitIcon";
import SidebarServicesIcon from "../../../globals/icons/sidebarServicesIcon";
import SidebarVenueAccountIcon from "../../../globals/icons/sidebarVenuAccountIcon";
import AuthContext from "../../../store/auth-context";

const Sidebar = (props) => {
  const ctx = useContext(AuthContext);

  return (
    <div id="sidebar">
      <ul id="sidebar__navigation">
        <li
          className={ctx.activeSidebarTab === "Dashboard" ? "active" : ""}
          onClick={ctx.sidebarOnClick}
        >
          <SidebarDashboardIcon
            className={ctx.activeSidebarTab === "Dashboard" ? "active" : ""}
          />
          Dashboard
          <RightChevronIcon
            className={ctx.activeSidebarTab === "Dashboard" ? "active" : ""}
          />
        </li>

        {ctx.signInRole === "merchant" && (
          <>
            <li
              className={ctx.activeSidebarTab === "Venue" ? "active" : ""}
              onClick={ctx.sidebarOnClick}
            >
              <SidebarVenueAccountIcon
                className={ctx.activeSidebarTab === "Venue" ? "active" : ""}
              />
              Venue
              <RightChevronIcon
                className={ctx.activeSidebarTab === "Venue" ? "active" : ""}
              />
            </li>
            <li
              className={ctx.activeSidebarTab === "Services" ? "active" : ""}
              onClick={ctx.sidebarOnClick}
            >
              <SidebarServicesIcon
                className={ctx.activeSidebarTab === "Services" ? "active" : ""}
              />
              Services
              <RightChevronIcon
                className={ctx.activeSidebarTab === "Services" ? "active" : ""}
              />
            </li>
            <li
              className={ctx.activeSidebarTab === "Bookings" ? "active" : ""}
              onClick={ctx.sidebarOnClick}
            >
              <SidebarBookingsIcon
                className={ctx.activeSidebarTab === "Bookings" ? "active" : ""}
              />
              Bookings
              <RightChevronIcon
                className={ctx.activeSidebarTab === "Bookings" ? "active" : ""}
              />
            </li>
          </>
        )}

        {ctx.signInRole === "planner" && (
          <li
            className={ctx.activeSidebarTab === "Events" ? "active" : ""}
            onClick={ctx.sidebarOnClick}
          >
            <SidebarEventsIcon
              className={ctx.activeSidebarTab === "Events" ? "active" : ""}
            />
            Events
            <RightChevronIcon
              className={ctx.activeSidebarTab === "Events" ? "active" : ""}
            />
          </li>
        )}

        <li onClick={ctx.sidebarExitIconOnClick}><SidebarExitIcon  /> Exit</li>
      </ul>
    </div>
  );
};

export default Sidebar;
