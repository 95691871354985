import { React, useContext } from "react";

import Button from "../../../ui/button/button";
import TimeSelect from "../../../ui/time/timeSelect";
import AuthContext from "../../../../store/auth-context";

const MerchantVenueAddDetailsHoursOfOperation = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <>
      <div className="hoursOfOperation__wrapper">
        <h2>Hours of Operation</h2>
        <div className="hoursOfOperation__wrapper__day__wrapper">
          <input
            type="checkbox"
            id="monday"
            onChange={
              ctx.mechantVenueCreateVenueHoursOfOperationCheckboxOnChange
            }
          />
          <label htmlFor="monday">Monday</label>
          <TimeSelect day="monday" period="open" />
          <span>to</span>
          <TimeSelect day="monday" period="close" />
        </div>
        <div className="hoursOfOperation__wrapper__day__wrapper">
          <input
            type="checkbox"
            id="tuesday"
            onChange={
              ctx.mechantVenueCreateVenueHoursOfOperationCheckboxOnChange
            }
          />
          <label htmlFor="tuesday">Tueseday</label>
          <TimeSelect day="tuesday" period="open" />
          <span>to</span>
          <TimeSelect day="tuesday" period="close" />
        </div>

        <div className="hoursOfOperation__wrapper__day__wrapper">
          <input
            type="checkbox"
            id="wednesday"
            onChange={
              ctx.mechantVenueCreateVenueHoursOfOperationCheckboxOnChange
            }
          />
          <label htmlFor="wednesday">Wednesday</label>
          <TimeSelect day="wednesday" period="open" />
          <span>to</span>
          <TimeSelect day="wednesday" period="close" />
        </div>

        <div className="hoursOfOperation__wrapper__day__wrapper">
          <input
            type="checkbox"
            id="thursday"
            onChange={
              ctx.mechantVenueCreateVenueHoursOfOperationCheckboxOnChange
            }
          />
          <label htmlFor="thursday">Thursday</label>
          <TimeSelect day="thursday" period="open" />
          <span>to</span>
          <TimeSelect day="thursday" period="close" />
        </div>

        <div className="hoursOfOperation__wrapper__day__wrapper">
          <input
            type="checkbox"
            id="friday"
            onChange={
              ctx.mechantVenueCreateVenueHoursOfOperationCheckboxOnChange
            }
          />
          <label htmlFor="friday">Friday</label>
          <TimeSelect day="friday" period="open" />
          <span>to</span>
          <TimeSelect day="friday" period="close" />
        </div>

        <div className="hoursOfOperation__wrapper__day__wrapper">
          <input
            type="checkbox"
            id="saturday"
            onChange={
              ctx.mechantVenueCreateVenueHoursOfOperationCheckboxOnChange
            }
          />
          <label htmlFor="saturday">Saturday</label>
          <TimeSelect day="saturday" period="open" />
          <span>to</span>
          <TimeSelect day="saturday" period="close" />
        </div>

        <div className="hoursOfOperation__wrapper__day__wrapper">
          <input
            type="checkbox"
            id="sunday"
            onChange={
              ctx.mechantVenueCreateVenueHoursOfOperationCheckboxOnChange
            }
          />
          <label htmlFor="sunday">Sunday</label>
          <TimeSelect day="sunday" period="open" />
          <span>to</span>
          <TimeSelect day="sunday" period="close" />
        </div>

        <div className="hoursOfOperation__wrapper__buttons__wrapper">
          <Button className="button-passive" onClick={ctx.merchantVenueCreateVenueBackButtonClick}>Back</Button>
          <Button
            className="button-active"
            onClick={ctx.merchantVenueCreateVenueSaveVenueButtonClick}
            disabled={
              (ctx.mechantVenueCreateVenueHoursOfOperationMondayCheckbox ===
                true ||
              ctx.mechantVenueCreateVenueHoursOfOperationTuesdayCheckbox ===
                true ||
              ctx.mechantVenueCreateVenueHoursOfOperationWednesdayCheckbox ===
                true ||
              ctx.mechantVenueCreateVenueHoursOfOperationThursdayCheckbox ===
                true ||
              ctx.mechantVenueCreateVenueHoursOfOperationFridayCheckbox ===
                true ||
              ctx.mechantVenueCreateVenueHoursOfOperationSaturdayCheckbox ===
                true ||
              ctx.mechantVenueCreateVenueHoursOfOperationSundayCheckbox === true
                ? true
                : false) &&
              (ctx.mechantVenueCreateVenueHoursOfOperationMondayCheckbox ===
                true &&
              (ctx.merchantVenueCreateVenueHoursOfOperaitonMondayOpenHour ===
                "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonMondayOpenMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonMondayOpenAmpm ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonMondayCloseHour ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonMondayCloseMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonMondayCloseAmpm ===
                  "")
                ? false
                : true) &&
              (ctx.mechantVenueCreateVenueHoursOfOperationTuesdayCheckbox ===
                true &&
              (ctx.merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenHour ===
                "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenAmpm ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseHour ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseAmpm ===
                  "")
                ? false
                : true) &&
              (ctx.mechantVenueCreateVenueHoursOfOperationWednesdayCheckbox ===
                true &&
              (ctx.merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenHour ===
                "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenAmpm ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseHour ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseAmpm ===
                  "")
                ? false
                : true) &&
              (ctx.mechantVenueCreateVenueHoursOfOperationThursdayCheckbox ===
                true &&
              (ctx.merchantVenueCreateVenueHoursOfOperaitonThursdayOpenHour ===
                "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonThursdayOpenMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonThursdayOpenAmpm ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonThursdayCloseHour ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonThursdayCloseMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonThursdayCloseAmpm ===
                  "")
                ? false
                : true) &&
              (ctx.mechantVenueCreateVenueHoursOfOperationFridayCheckbox ===
                true &&
              (ctx.merchantVenueCreateVenueHoursOfOperaitonFridayOpenHour ===
                "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonFridayOpenMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonFridayOpenAmpm ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonFridayCloseHour ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonFridayCloseMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonFridayCloseAmpm ===
                  "")
                ? false
                : true) &&
              (ctx.mechantVenueCreateVenueHoursOfOperationSaturdayCheckbox ===
                true &&
              (ctx.merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenHour ===
                "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenAmpm ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseHour ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseAmpm ===
                  "")
                ? false
                : true) &&
              (ctx.mechantVenueCreateVenueHoursOfOperationSundayCheckbox ===
                true &&
              (ctx.merchantVenueCreateVenueHoursOfOperaitonSundayOpenHour ===
                "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonSundayOpenMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonSundayOpenAmpm ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonSundayCloseHour ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonSundayCloseMinute ===
                  "" ||
                ctx.merchantVenueCreateVenueHoursOfOperaitonSundayCloseAmpm ===
                  "")
                ? false
                : true)
                ? false
                : true
            }
          >
            Save Venue
          </Button>
        </div>
      </div>
    </>
  );
};

export default MerchantVenueAddDetailsHoursOfOperation;
