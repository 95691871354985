/* eslint-disable react-hooks/exhaustive-deps */
import { React, useContext, useEffect } from "react";
// import ChevronLeftIcon from "../../.././src/globals/icons/chevronLeftIcon";
// import ChevronRightIcon from "../../.././src/globals/icons/chevronRightIcon";
import Button from "../../../ui/button/button";
import AuthContext from "../../../../store/auth-context";

const MerchantServicesList = (props) => {
  const ctx = useContext(AuthContext);
  useEffect(() => {
    ctx.merchantServiceListServices();
  }, []);
  return (
    <>
      <div id="servicesList__topBar">
        <h2>Services</h2>
        {/* <select id="serviceFilter">
          <option value="allServices">All Services</option>
          <option value="0">0</option>
          <option value="1">1</option>
        </select> */}
        <Button
          className="button-active"
          onClick={ctx.merchantServiceCreateServiceButtonOnClick}
        >
          Add Service
        </Button>
      </div>

      <div id="servicesList__wrapper">
        <div id="servicesList__wrapper__header">
          <div id="servicesList__wrapper__header__id">ID</div>
          <div id="servicesList__wrapper__header__name">Name</div>
        </div>
        <div id="servicesList__wrapper__body">
          {ctx.merchantServiceListServicesFilteredList.map((service) => (
            <div className="servicesList__wrapper__row" key={service.id}>
              <div className="servicesList__wrapper__row__id">{service.id}</div>
              <div className="servicesList__wrapper__row__name">{service.name}</div>
            </div>
          ))}

          {/* <div className="servicesList__wrapper__row inactive">
            <div className="servicesList__wrapper__row__type">Recreational</div>
            <div className="servicesList__wrapper__row__code">6325987</div>
            <div className="servicesList__wrapper__row__name">Miami Watersports LLC Surfing Classes</div>
            <div className="servicesList__wrapper__row__status">Inactive</div>
            <div className="servicesList__wrapper__row__action" onClick={editClickHandler}>Edit</div>
          </div> */}
          {/* <div className="servicesList__wrapper__row edit">
            <div className="servicesList__wrapper__row__type">Recreational</div>
            <div className="servicesList__wrapper__row__code">6325987</div>
            <div className="servicesList__wrapper__row__name"><input id="serviceName" type="text" placeholder="Miami Watersports LLC Surfing Classes"></input></div>
            <div className="servicesList__wrapper__row__status"><span className="activeStatus">Inactive</span><span>Active</span></div>
            <div className="servicesList__wrapper__row__action" onClick={editClickHandler}><span className="saveAction">Save</span></div>
          </div> */}
        </div>
      </div>
      {/* <div id="serviceList__pagination__wrapper">
        <ChevronLeftIcon />
        <span id="pageNumber">2</span>
        <ChevronRightIcon />
      </div> */}
    </>
  );
};

export default MerchantServicesList;
