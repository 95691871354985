const RightTriangle = (props) => {
  return (
    <svg
      width="13px"
      height="12px"
      viewBox="0 0 13 12"
      version="1.1"
    >
      <g
        id="Planner-Flow---DESKTOP"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Splitsees-UI---DASH-01"
          transform="translate(-744.000000, -647.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="Text" transform="translate(436.000000, 140.000000)">
            <g id="btn" transform="translate(1.000000, 489.483871)">
              <g id="Group" transform="translate(32.000000, 9.516129)">
                <path
                  id="Line"
                  d="M287.666667,14 L275.666667,8.25 L275.666667,19.75 L287.666667,14 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RightTriangle;
