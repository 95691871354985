import SplitseesLogo from "../../../globals/icons/splitseesLogo";
import { useContext } from "react";
import SplitseesButton from "../../ui/splitsees-button/splitsees-button";
import AuthContext from "../../../store/auth-context";

const FrontDoorForgotPassword = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <div className="front-door__wrapper__content__wrapper front-door__forgot-password">
      <SplitseesLogo color="splitsees-purple" className="front-door__logo" />
      <p className="front-door__copy--primary">
        Let’s reset your password by entering the email address used to log into
        the account. We’ll email you a reset code.
      </p>
      <fieldset>
        <div className="splitsees__inputWrapper">
          <input
            type="email"
            id="forgotPasswordEmail"
            onChange={ctx.forgotPasswordEmailOnChange}
            placeholder="email address"
          ></input>
        </div>
      </fieldset>

      <SplitseesButton
        className="tall"
        onClick={ctx.forgotPasswordButtonOnClick}
      >
        CONFIRM EMAIL ADDRESS
      </SplitseesButton>
    </div>
  );
};

export default FrontDoorForgotPassword;
