import ProfileCard from "../../../ui/profile/profileCard";

const MerchantProfileAccountManageVenue = props => {
  return (
    <>
      <ProfileCard />
      <div id="merchantProfileAccount__manageVenue__wrapper">
        <h1>Manage Venue</h1>
      </div>
    </>
  )
}

export default MerchantProfileAccountManageVenue;