const SplitseesLogo = (props) => {
  return (
    <svg 
    id="splitseesLogo"
    viewBox="0 0 366.4 97.34"
   
    fill={props.color === 'splitsees-purple' ? '#4523C1' : props.color }
    className={props.className}
    
    >
      <defs>
        <clipPath id="clip-path" transform="translate(0)">
          <rect className="cls-1"  />
        </clipPath>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g className="cls-2">
            <path
              className="cls-3"
              d="M32.09,30.34v8H15.29a10.18,10.18,0,0,0-4.75,1,3.24,3.24,0,0,0-2,3,4.27,4.27,0,0,0,.48,2,5.31,5.31,0,0,0,1.09,1.47A8.28,8.28,0,0,0,12,47a18.91,18.91,0,0,0,2,.85l6,2c3.55,1.14,6.17,2.09,7.88,2.84a9.31,9.31,0,0,1,3.94,3.09,10.11,10.11,0,0,1,1.76,3.8A18.32,18.32,0,0,1,34,63.19q0,12.74-17.47,12.73H.09v-8H16.52q8.93,0,8.92-4.75,0-2.28-2.61-3.79a27,27,0,0,0-6.36-2.57q-3.75-1-7.5-2.42A14.94,14.94,0,0,1,2.61,50,11.44,11.44,0,0,1,0,42.31a10.15,10.15,0,0,1,4.46-8.88,18.55,18.55,0,0,1,10.83-3.09Z"
              transform="translate(0)"
            />
            <polygon
              className="cls-3"
              points="151.22 75.92 142.86 75.92 142.86 38.32 131.12 38.32 134.92 30.34 151.22 30.34 151.22 75.92"
            />
            <path
              className="cls-3"
              d="M142.4,19.9a5.69,5.69,0,0,1-1.23-3.51,5.48,5.48,0,0,1,1.23-3.47,5.38,5.38,0,0,1,7.6,0,5.5,5.5,0,0,1,0,6.93,4.56,4.56,0,0,1-3.8,1.57,4.61,4.61,0,0,1-3.8-1.52"
              transform="translate(0)"
            />
            <path
              className="cls-3"
              d="M159.11,38.41l17-17v9h12.81v8H176.11V58a12.26,12.26,0,0,0,1.09,5.51,6.23,6.23,0,0,0,3.18,3.08,16.2,16.2,0,0,0,3.94,1.19,31.07,31.07,0,0,0,4.6.28v8Q178,76,172.78,71.83T167.56,58V38.41Z"
              transform="translate(0)"
            />
            <path
              className="cls-3"
              d="M229.56,30.34v8H212.75a10.18,10.18,0,0,0-4.75,1,3.23,3.23,0,0,0-2,3,4.27,4.27,0,0,0,.48,2,5.31,5.31,0,0,0,1.09,1.47,8.07,8.07,0,0,0,1.9,1.19,18,18,0,0,0,2,.85l6,2q5.31,1.71,7.88,2.84a9.31,9.31,0,0,1,3.94,3.09,10.26,10.26,0,0,1,1.76,3.8,18.3,18.3,0,0,1,.37,3.65q0,12.74-17.46,12.73H197.56v-8H214q8.91,0,8.92-4.75,0-2.28-2.61-3.79a27,27,0,0,0-6.36-2.57q-3.75-1-7.5-2.42A14.94,14.94,0,0,1,200.08,50a11.39,11.39,0,0,1-2.61-7.64,10.13,10.13,0,0,1,4.46-8.88,18.52,18.52,0,0,1,10.82-3.09Z"
              transform="translate(0)"
            />
            <path
              className="cls-3"
              d="M364.5,30.34v8H347.7a10.18,10.18,0,0,0-4.75,1,3.24,3.24,0,0,0-2,3,4.27,4.27,0,0,0,.48,2,5.31,5.31,0,0,0,1.09,1.47,8.28,8.28,0,0,0,1.9,1.19,18.91,18.91,0,0,0,2,.85l6,2c3.55,1.14,6.17,2.09,7.88,2.84a9.31,9.31,0,0,1,3.94,3.09,10.11,10.11,0,0,1,1.76,3.8,18.32,18.32,0,0,1,.38,3.65q0,12.74-17.47,12.73H332.51v-8h16.42q8.93,0,8.92-4.75,0-2.28-2.61-3.79a27,27,0,0,0-6.36-2.57q-3.75-1-7.5-2.42A14.94,14.94,0,0,1,335,50a11.44,11.44,0,0,1-2.61-7.64,10.15,10.15,0,0,1,4.46-8.88,18.55,18.55,0,0,1,10.83-3.09Z"
              transform="translate(0)"
            />
            <polygon
              className="cls-3"
              points="91.82 75.69 128.75 0 138.03 0 101.11 75.69 91.82 75.69"
            />
            <path
              className="cls-3"
              d="M86.88,43.65A23.45,23.45,0,0,0,74.49,31a22.38,22.38,0,0,0-9.06-1.85A22.77,22.77,0,0,0,56.31,31,22.93,22.93,0,0,0,44,43.6a24.16,24.16,0,0,0-1.81,9.3V97.34h8.55V52.9a18.88,18.88,0,0,1,1-6.4,14.54,14.54,0,0,1,3-5,13.41,13.41,0,0,1,4.66-3.23,15.36,15.36,0,0,1,6-1.14,15.12,15.12,0,0,1,6.12,1.19,13.28,13.28,0,0,1,4.6,3.28,14.72,14.72,0,0,1,2.95,5,19.69,19.69,0,0,1,0,12.63,14.49,14.49,0,0,1-3,5,13.69,13.69,0,0,1-4.75,3.28,15.82,15.82,0,0,1-6.22,1.18,19,19,0,0,1-7.76-1.57v8.12a23.51,23.51,0,0,0,8.05,1.43A23,23,0,0,0,82,69.71a23.29,23.29,0,0,0,4.89-7.55,24.34,24.34,0,0,0,1.76-9.26,23.8,23.8,0,0,0-1.81-9.25"
              transform="translate(0)"
            />
            <path
              className="cls-3"
              d="M266.78,48.43c-1.64,1.3-4.59,2-8.83,2h-4.22v7.31h3.56q20.79,0,20.79-15.86,0-12.72-16.14-13.39-11-.37-17.37,6.55t-6.36,18.14q0,10.07,6.45,16.42c4.3,4.23,10,6.37,17.23,6.37h16.28v-8H261.88a14.11,14.11,0,0,1-10.71-4.37Q247,59.21,247,52.18a17.53,17.53,0,0,1,3.84-11.39A12.86,12.86,0,0,1,261.47,36a9.84,9.84,0,0,1,5.6,1.47,5,5,0,0,1,2.18,4.41q0,4.56-2.47,6.51"
              transform="translate(0)"
            />
            <path
              className="cls-3"
              d="M312.94,48.43q-2.47,1.95-8.83,2h-4.23v7.31h3.56q20.79,0,20.8-15.86,0-12.72-16.14-13.39-11-.37-17.38,6.55t-6.36,18.14q0,10.07,6.46,16.42c4.29,4.23,10,6.37,17.23,6.37h16.27v-8H308a14.11,14.11,0,0,1-10.71-4.37q-4.12-4.37-4.13-11.39A17.53,17.53,0,0,1,297,40.79,12.88,12.88,0,0,1,307.62,36a9.81,9.81,0,0,1,5.6,1.47,5,5,0,0,1,2.19,4.41c0,3-.83,5.21-2.47,6.51"
              transform="translate(0)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SplitseesLogo;
