import { Switch, Route } from "react-router-dom";
import ForgotPassword from "./forgotPassword";
import ForgotPasswordReset from "./forgotPasswordReset";
import LogIn from "./logIn";
import Register from "./register";
import Verify from "./verify";
import PublicVenue from "../publicVenue/publicVenue";
import PublicVenueBooking from "../publicVenue/publicVenueBooking";

const RegisterLoginWrapper = (props) => {
  return (
    <>
      <Switch>
        <Route path="/register" exact>
          <div id="registerLogin__wrapper">
            <div id="registerLogin__wrapper__leftSide"></div>
            <Register />
          </div>
        </Route>
        <Route path="/register/verify" exact>
          <div id="registerLogin__wrapper">
            <div id="registerLogin__wrapper__leftSide"></div>
            <Verify />
          </div>
        </Route>
        <Route path="/login" exact>
          <div id="registerLogin__wrapper">
            <div id="registerLogin__wrapper__leftSide"></div>

            <LogIn />
          </div>
        </Route>
        <Route path="/forgot-password" exact>
          <div id="registerLogin__wrapper">
            <div id="registerLogin__wrapper__leftSide"></div>

            <ForgotPassword />
          </div>
        </Route>
        <Route path="/forgot-password-reset">
          <div id="registerLogin__wrapper">
            <div id="registerLogin__wrapper__leftSide"></div>

            <ForgotPasswordReset />
          </div>
        </Route>
        <Route path="/venue/:id" exact>
          <PublicVenue />
        </Route>
        <Route path="/venue/:id/service/:sid" exact>
          <PublicVenueBooking />
        </Route>
        <Route path="/*">
          <div id="registerLogin__wrapper">
            <div id="registerLogin__wrapper__leftSide"></div>
            <Register />
          </div>
        </Route>
      </Switch>
    </>
  );
};

export default RegisterLoginWrapper;
