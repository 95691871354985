/* eslint-disable react-hooks/exhaustive-deps */
import { React, useContext, useEffect } from "react";
// import EditPencilIcon from "../../../../globals/icons/editPencilIcon";
import AuthContext from "../../../../store/auth-context";

const ViewMerchantBookings = (props) => {
  const ctx = useContext(AuthContext);
  useEffect(() => {
    ctx.merchantEventLister();
  }, []);
  return (
    <>
      <div id="merchantBookings__wrapper">
        <div id="merchantBookings__wrapper__topBar">
          <h2>Bookings</h2>
        </div>

        <div id="merchantBookings__wrapper__List__wrapper">
          <div id="merchantBookings__wrapper__List__wrapper__header">
            <div id="merchantBookings__wrapper__List__wrapper__header__id">ID</div>
            <div id="merchantBookings__wrapper__List__wrapper__header__date">Date</div>
            <div id="merchantBookings__wrapper__List__wrapper__header__name">Name</div>
            <div id="merchantBookings__wrapper__List__wrapper__header__status">Status</div>
          </div>
          <div id="merchantBookings__wrapper__body">
            {ctx.merchantBookings.map((booking) => (
              <div className="merchantBookings__wrapper__row" key={booking.id}>
                <div className="merchantBookings__wrapper__row__id">
                  {booking.id}
                </div>
                <div className="merchantBookings__wrapper__row__date">
                  {booking.date}
                </div>
                <div className="merchantBookings__wrapper__row__name">
                  {booking.service.name}
                </div>
                <div className="merchantBookings__wrapper__row__status">
                  {(!ctx.merchantBookingStatusEditFlag || ctx.merchantBookingStatusEditId !== booking.id) && booking.status} 
                  {(booking.status !== 'approved' && ctx.merchantBookingStatusEditId !== booking.id && !ctx.merchantBookingStatusEditFlag) && <span onClick={() => {ctx.merchantBookingStatusEditOnClick(booking.id)}}>Edit</span> }
                  {(ctx.merchantBookingStatusEditFlag && ctx.merchantBookingStatusEditId === booking.id) && <><span onClick={() => {ctx.merchantBookingStatusEditChoiceOnClick('approved')}}>Approve</span><span onClick={() => {ctx.merchantBookingStatusEditChoiceOnClick('denied')}}>Deny</span></>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewMerchantBookings;

