import { React, useContext } from "react";
import Button from "../../../ui/button/button";
import AuthContext from "../../../../store/auth-context";

const MerchantVenueAddDetails = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <>
      <div id="merchantVenueAddDetails__wrapper">
        <div className="merchantVenueAddDetails__inputWrapper">
          <label htmlFor="venueName">Venue Name *</label>
          <input
            id="venueName"
            type="text"
            placeholder="Venue Name"
            onChange={ctx.merchantVenueCreateVenueNameFieldOnChange}
          ></input>
        </div>

        <div className="merchantVenueAddDetails__inputWrapper">
          <label htmlFor="website">Venue Website *</label>
          <input
            id="website"
            type="url"
            placeholder="Venue Website Address"
            onChange={ctx.merchantVenueCreateVenueWebsiteFieldOnChange}
          ></input>
        </div>

        <div className="merchantVenueAddDetails__inputWrapper">
          <label htmlFor="addressLine1">Venue Address *</label>
          <input
            id="addressLine1"
            type="text"
            placeholder="Venue Address Line 1"
            onChange={ctx.merchantVenueCreateVenueAddressLine1FieldOnChange}
          ></input>
          <input
            id="addressLine2"
            type="text"
            placeholder="Venue Address Line 2"
            onChange={ctx.merchantVenueCreateVenueAddressLine2FieldOnChange}
          ></input>
          <input
            id="city"
            type="text"
            placeholder="Venue City"
            onChange={ctx.merchantVenueCreateVenueAddressCityFieldOnChange}
          ></input>
          <select
            id="state"
            onChange={ctx.merchantVenueCreateVenueAddressStateFieldOnChange}
            defaultValue=""
          >
            <option value="" disabled>
              Please Pick A State
            </option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
          <input
            id="phone"
            type="tel"
            placeholder="Venue Phone Number *"
            onChange={ctx.merchantVenueCreateVenuePhoneNumberFieldOnChange}
          ></input>
          <input
            id="zip"
            type="number"
            placeholder="Venue Zip Code *"
            onChange={ctx.merchantVenueCreateVenueAddressZipCodeFieldOnChange}
          ></input>
        </div>

        <div className="merchantVenueAddDetails__inputWrapper">
          <label htmlFor="facebook">Your business facebook page</label>
          <input
            id="facebook"
            type="url"
            placeholder="Venue Facebook Page"
            onChange={ctx.merchantVenueCreateVenueFacebookPageFieldOnChange}
          ></input>
        </div>

        <div className="merchantVenueAddDetails__inputWrapper">
          <label htmlFor="logo">Venue Logo (120px by 120px) *</label>
          <input
            id="logo"
            type="file"
            onChange={ctx.merchantVenueCreateVenueLogoFieldOnChange}
          />
        </div>

        <div className="merchantVenueAddDetails__inputWrapper">
          <label htmlFor="backgroundImage">
            Venue Background Image (900px by 300px) *
          </label>
          <input
            id="BackgroundImage"
            type="file"
            onChange={ctx.merchantVenueCreateVenueBackgroundImageFieldOnChange}
          />
        </div>

        <Button
          className="button-active"
          symbol="rightArrow"
          onClick={ctx.merchantVenueCreateVenueNextButtonOnClick}
          disabled={
            ctx.merchantVenueCreateVenueName === "" ||
            ctx.merchantVenueCreateVenueWebsite === "" ||
            ctx.merchantVenueCreateVenueAddressLine1 === "" ||
            ctx.merchantVenueCreateVenueAddressCity === "" || 
            ctx.merchantVenueCreateVenueAddressState === "" ||
            ctx.merchantVenueCreateVenuePhoneNumber === "" ||
            ctx.merchantVenueCreateVenueAddressZipCode === "" ||
            ctx.merchantVenueCreateVenueLogoFile === undefined ||
            ctx.merchantVenueCreateVenueBackgroundImageFile === undefined
          }
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default MerchantVenueAddDetails;
