/* eslint-disable no-unused-vars */

import React, { createContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../components/ui/button/button";
import { loadStripe } from "@stripe/stripe-js";

const AuthContext = React.createContext({
  isLoggedIn: false,
  onLogout: () => {},
  onLogin: (email, password) => {},
  onRegister: (
    email,
    firstName,
    lastName,
    password,
    passwordVerify,
    gender
  ) => {},
});

export const AuthContextProvider = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // const logoutHandler = () => {
  //   setIsLoggedIn(false);
  // };

  // const loginHandler = () => {
  //   setIsLoggedIn(true);
  // };

  //////////////////////////////////////////////////
  ///// Modal State/Handlers
  //////////////////////////////////////////////////

  const [modalTitle, setModalTitle] = useState("");
  const [modalCopy, setModalCopy] = useState("");
  const [modalDisplay, setModalDisplay] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalError, setModalError] = useState("");

  const setModalCopyContentHandler = (props) => {
    setModalCopy(props.copy);
  };

  const setModalTitleContentHandler = (props) => {
    setModalTitle(props.title);
  };

  const modalCloseButtonOnClickHandler = (event) => {
    setModalTitle("");
    setModalCopy("");
    setModalMessage("");
    setModalError("");
    setModalDisplay(false);
  };

  const setModalDisplayStateHandler = (props) => {
    setModalDisplay(true);
  };

  //////////////////////////////////////////////////
  ///// Sidebar State/Handlers
  //////////////////////////////////////////////////

  const [activeSidebarTab, setActiveSidebarTab] = useState("Dashboard");

  const sidebarOnClickHandler = (event) => {
    setActiveSidebarTab(event.target.textContent);
    history.push(`/${signInRole}/${event.target.textContent.toLowerCase()}`);
    // console.log(event.target.textContent.toLowerCase());
  };

  //////////////////////////////////////////////////
  ///// Register State/Handlers
  //////////////////////////////////////////////////

  ///// Register States
  const [registerRole, setRegisterRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userVerifyPassword, setUserVerifyPassword] = useState("");
  const [registerOrgin, setRegisterOrgin] = useState();

  ///// Register Handlers

  const signInTopLinkClickHandler = (event) => {
    history.push("/login");
  };

  const merchantPlannerClickHandler = (event) => {
    setRegisterRole(event.target.value.toLowerCase());
  };

  const registerButtonClickHandler = (props) => {
    async function registerUser() {
      const response = await fetch(
        "https://api.splitsees.com/api/auth/register",
        {
          method: "POST",
          body: JSON.stringify({
            email: userEmail,
            first_name: userFirstName,
            last_name: userLastName,
            type: registerRole,
            password: userPassword,
            password_confirmation: userVerifyPassword,
            gender_id: "1",
            birth_date: "1901-01-01",
            city: "0",
            zipcode: "00000",
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        history.push("/register/verify");
      } else {
        setModalTitle("Register Error");
        setModalCopy(
          "Please fill in every field and make sure your passwords match and try again."
        );
        setModalDisplay(true);
      }
      console.log(data);
    }
    registerUser();
  };

  const emailInputChangeHandler = (event) => {
    setUserEmail(event.target.value);
  };

  const firstNameInputChangeHandler = (event) => {
    setUserFirstName(event.target.value);
  };

  const lastNameInputChangeHandler = (event) => {
    setUserLastName(event.target.value);
  };

  const passwordInputChangeHandler = (event) => {
    setUserPassword(event.target.value);
  };

  const passwordVerifyInputChangeHandler = (event) => {
    setUserVerifyPassword(event.target.value);
  };

  //////////////////////////////////////////////////
  ///// Verify State/Handlers
  //////////////////////////////////////////////////

  const [verifyCode, setVerifyCode] = useState("");

  const verifyCodeOnChangeHandler = (event) => {
    setVerifyCode(event.target.value);
  };

  const resendOnClickHandler = (event) => {
    async function resendVerifyCode() {
      const response = await fetch(
        "https://api.splitsees.com/api/auth/resend",
        {
          method: "POST",
          body: JSON.stringify({ email: userEmail }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response.status === 200) {
        setModalTitle("Resend Verification Code");
        setModalCopy("A new verification code has been sent to your email.");
        setModalDisplay(true);
      } else {
        setModalTitle("Resend Verification Code Error");
        setModalCopy(
          "Please make sure the email address field has your correct email address."
        );
        setModalDisplay(true);
      }
    }
    resendVerifyCode();
  };

  const launchClickHandler = (event) => {
    async function verifyUser() {
      const response = await fetch(
        "https://api.splitsees.com/api/auth/verify",
        {
          method: "POST",
          body: JSON.stringify({ code: verifyCode, email: userEmail }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response.status === 200) {
        // const data = await response.json();
        setModalTitle("Verifed");
        setModalCopy("You are now verified! You can proceed.");
        setModalDisplay(true);
        history.push("/login");
        // async function registerSignIn() {
        //   const response = await fetch(
        //     "https://chillhound.sandbox.polymorphic.io/api/auth/login",
        //     {
        //       method: "POST",
        //       body: JSON.stringify({
        //         email: userEmail,
        //         password: userPassword,
        //         type: registerRole,
        //       }),
        //       headers: {
        //         "Content-Type": "application/json",
        //         Accept: "application/json",
        //       },
        //     }
        //   );
        //   if (response.status === 200) {
        //     const data = await response.json();
        //     setSignedInUserAccessToken(data.data.access_token);
        //     setSignedInUserAccessTokenExpiration(data.data.expires_in);
        //     data.data.user.first_name !== null
        //       ? setSignedInUserFirstName(data.data.user.first_name)
        //       : setSignedInUserFirstName("");
        //     data.data.user.last_name !== null
        //       ? setSignedInUserLastName(data.data.user.last_name)
        //       : setSignedInUserLastName("");
        //     setSignedInUserEmail(userEmail);
        //     data.data.user.profile_image !== null
        //       ? setSignedInUserProfileImage(data.data.user.profile_image)
        //       : setSignedInUserProfileImage(null);
        //     data.data.user.city !== null
        //       ? setSignedInUserCity(data.data.user.city)
        //       : setSignedInUserCity(null);
        //     data.data.user.zipcode !== null
        //       ? setSignedInUserZipCode(data.data.user.zipcode)
        //       : setSignedInUserZipCode(null);
        //     setSignedInUserRole(registerRole);
        //     setIsLoggedIn(true);
        //     if (registerRole !== undefined) {
        //       history.push(registerOrgin);
        //     } else {
        //       if (signInRole === "merchant") {
        //         setSignedInUserMerchantId(data.data.user.merchant.id);
        //         setSignInMerchantOnboardingLink(
        //           data.data.user.merchant.links.url
        //         );
        //         history.push("/merchant/dashboard");
        //       } else {
        //         history.push("/planner/dashboard");
        //       }
        //     }
        //   } else {
        //     setModalTitle("Sign In Error");
        //     setModalCopy(
        //       "Your email address or password was incorrect. Please try again."
        //     );
        //     setModalDisplay(true);
        //   }
        // }
        // registerSignIn();
      } else {
        setModalTitle("Verify Error");
        setModalCopy(
          "Your verify code did not work. Please click 'Resend' if you need a new code"
        );
        setModalDisplay(true);
      }
    }
    verifyUser();
  };

  //////////////////////////////////////////////////
  ///// Signed In User State/Handlers
  //////////////////////////////////////////////////

  const [signedInUserFirstName, setSignedInUserFirstName] = useState("");
  const [signedInUserLastName, setSignedInUserLastName] = useState("");
  const [signedInUserEmail, setSignedInUserEmail] = useState("");
  // const [signedInUserPrivacyPolicy, setSignedInUserPrivacyPolicy] = useState(''); // @TODO What is this?
  const [signedInUserProfileImage, setSignedInUserProfileImage] = useState(
    null
  );
  const [signedInUserAccessToken, setSignedInUserAccessToken] = useState(
    "",
    []
  );
  const [
    signedInUserAccessTokenExpiration,
    setSignedInUserAccessTokenExpiration,
  ] = useState("");
  const [signedInUserRole, setSignedInUserRole] = useState("");
  const [signedInUserNotifications, setSignedInUserNotifications] = useState(
    ""
  );
  const [
    signedInUserNotificationsEmail,
    setSignedInUserNotificationsEmail,
  ] = useState(false);
  const [
    signedInUserNotificationsPhone,
    setSignedInUserNotificationsPhone,
  ] = useState(false);

  const [signedInUserCity, setSignedInUserCity] = useState("");
  const [signedInUserZipCode, setSignedInUserZipCode] = useState("");

  const [signedInUserMerchantId, setSignedInUserMerchantId] = useState("");

  //////////////////////////////////////////////////
  ///// Sign In State/Handlers
  //////////////////////////////////////////////////

  const [signInRole, setSignInRole] = useState("");
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const [
    signInMerchantOnboardingLink,
    setSignInMerchantOnboardingLink,
  ] = useState("");

  const signUpOnClickHandler = (props) => {
    history.push("/register");
  };

  const signInMerchantPlannerClickHandler = (event) => {
    setSignInRole(event.target.value.toLowerCase());
  };

  const signInEmailOnChangeHandler = (event) => {
    setSignInEmail(event.target.value);
  };

  const signInPasswordOnChangeHandler = (event) => {
    setSignInPassword(event.target.value);
  };

  const signInButtonOnClickHandler = (event) => {
    async function signIn() {
      const response = await fetch(
        "https://api.splitsees.com/api/auth/login",
        {
          method: "POST",
          body: JSON.stringify({
            email: signInEmail,
            password: signInPassword,
            type: signInRole,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log(data);
        setSignedInUserAccessToken(data.data.access_token);
        setSignedInUserAccessTokenExpiration(data.data.expires_in);
        data.data.user.first_name !== null
          ? setSignedInUserFirstName(data.data.user.first_name)
          : setSignedInUserFirstName("");
        data.data.user.last_name !== null
          ? setSignedInUserLastName(data.data.user.last_name)
          : setSignedInUserLastName("");
        setSignedInUserEmail(signInEmail);
        data.data.user.profile_image !== null
          ? setSignedInUserProfileImage(data.data.user.profile_image)
          : setSignedInUserProfileImage(null);
        data.data.user.city !== null
          ? setSignedInUserCity(data.data.user.city)
          : setSignedInUserCity(null);
        data.data.user.zipcode !== null
          ? setSignedInUserZipCode(data.data.user.zipcode)
          : setSignedInUserZipCode(null);
        setSignedInUserRole(signInRole);
        setIsLoggedIn(true);
        if (signInRole === "merchant") {
          setSignedInUserMerchantId(data.data.user.merchant.id);
          setSignInMerchantOnboardingLink(data.data.user.merchant.links.url);
          history.push("/merchant/dashboard");
        } else {
          history.push("/planner/dashboard");
        }
      } else {
        setModalTitle("Sign In Error");
        setModalCopy(
          "Your email address or password was incorrect. Please try again."
        );
        setModalDisplay(true);
      }
    }
    signIn();
  };

  const forgotPasswordLinkOnClickHandler = (props) => {
    history.push("/forgot-password");
  };

  //////////////////////////////////////////////////
  ///// Forgot Password State/Handlers
  //////////////////////////////////////////////////

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  const forgotPasswordEmailOnChangeHandler = (event) => {
    setForgotPasswordEmail(event.target.value);
  };

  const forgotPasswordButtonClickHandler = (event) => {
    async function forgotPassword() {
      const response = await fetch(
        "https://api.splitsees.com/api/auth/forgot-password",
        {
          method: "POST",
          body: JSON.stringify({ email: forgotPasswordEmail }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response.status === 200) {
        // const data = await response.json();
        history.push("/forgot-password/reset");
      } else {
        setModalTitle("Forgot Password Error");
        setModalCopy("Please enter your correct email address and try again");
        setModalDisplay(true);
      }
    }
    forgotPassword();
  };

  //////////////////////////////////////////////////
  ///// Forgot Password Reset State/Handlers
  //////////////////////////////////////////////////

  const [forgotPasswordResetCode, setForgotPasswordResetCode] = useState("");
  const [
    forgotPasswordResetPassword,
    setForgotPasswordResetPassword,
  ] = useState("");
  const [
    forgotPasswordResetPasswordConfirm,
    setForgotPasswordResetPasswordConfirm,
  ] = useState("");

  const forgotPasswordResetCodeOnChangeHandler = (event) => {
    setForgotPasswordResetCode(event.target.value);
  };

  const forgotPasswordResetPasswordOnChangeHandler = (event) => {
    setForgotPasswordResetPassword(event.target.value);
  };

  const forgotPasswordResetPasswordConfirmOnChangeHandler = (event) => {
    setForgotPasswordResetPasswordConfirm(event.target.value);
  };

  const forgotPasswordResetPasswordButonOnClickHandler = (props) => {
    async function forgotPasswordReset() {
      const response = await fetch(
        "https://api.splitsees.com/api/auth/reset-password",
        {
          method: "POST",
          body: JSON.stringify({
            email: forgotPasswordEmail,
            code: forgotPasswordResetCode,
            password: forgotPasswordResetPassword,
            password_confirmation: forgotPasswordResetPasswordConfirm,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log(data);
        setModalTitle("Reset Password");
        setModalCopy(
          "Success! Your password has been reset. Please proceed and sign in with your new password."
        );
        setModalDisplay(true);
        history.push("/login");
      } else {
        setModalTitle("Reset Password Error");
        setModalCopy(
          "Please make sure you fill out every field and that your passwords match."
        );
        setModalDisplay(true);
      }
    }
    forgotPasswordReset();
  };

  //////////////////////////////////////////////////
  ///// Header/Profile  State/Handlers
  //////////////////////////////////////////////////

  const [profileVisibility, setProfileVisibility] = useState(false);
  const [previousLocation, setPreviousLocation] = useState("");

  const profileIconOnClickHandler = (props) => {
    if (profileVisibility === false) {
      setPreviousLocation(location.pathname);
      setProfileVisibility(!profileVisibility);
      history.push("/" + signInRole + "/profile");
      setProfileCardActiveLink("");
    } else {
      history.push(previousLocation);
      setPreviousLocation("");
      setProfileVisibility(false);
    }
  };

  //////////////////////////////////////////////////
  ///// Profile Card  State/Handlers
  ///// @TODO change the links to include 'signInRole'
  ///// instead of hard coded '/merchant or /planner'
  //////////////////////////////////////////////////

  const [profileCardActiveLink, setProfileCardActiveLink] = useState("");

  const profileCardAccountLinkOnClickHandler = (event) => {
    history.push(`/${signInRole}/profile/account`);
    setProfileCardActiveLink("account");
  };

  const profileCardNotificationsLinkOnClickHandler = (event) => {
    history.push(`/${signInRole}/profile/notifications`);
    setProfileCardActiveLink("notifications");
  };

  const profileCardLegalLinkOnClickHandler = (event) => {
    history.push(`/${signInRole}/profile/legal`);
    setProfileCardActiveLink("legal");
  };

  //////////////////////////////////////////////////
  ///// Notifications Handlers
  //////////////////////////////////////////////////

  const getSignedInUserNotificationsHandler = (props) => {
    async function getSignedInUserNotifications() {
      const response = await fetch(
        "https://api.splitsees.com/api/notifications",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + signedInUserAccessToken,
          },
        }
      );
      const data = await response.json();
      await setSignedInUserNotifications(data.data);
      console.log(data);
      Object.keys(data.data).forEach((key) => {
        console.log(key, data.data[key]);
        console.log(data.data[key].name);
        data.data[key].name === "email" &&
          setSignedInUserNotificationsEmail(true);
        data.data[key].name === "phone" &&
          setSignedInUserNotificationsPhone(true);
      });
    }
    getSignedInUserNotifications();
  };

  const signedInUserEmailNotificiationCheckBoxOnChangeHandler = (props) => {
    setSignedInUserNotificationsEmail(!signedInUserNotificationsEmail);
  };

  const signedInUserNotificationSaveButtonOnClickHandler = (props) => {
    //@TODO This needs to be more elegant. This is crap but I was in a rush
    let tempSignedInUserNotificationsArray = [];
    if (signedInUserNotificationsEmail && signedInUserNotificationsPhone) {
      tempSignedInUserNotificationsArray = [1, 2];
    } else if (
      signedInUserNotificationsEmail &&
      !signedInUserNotificationsPhone
    ) {
      tempSignedInUserNotificationsArray = [1];
    } else if (
      !signedInUserNotificationsEmail &&
      signedInUserNotificationsPhone
    ) {
      tempSignedInUserNotificationsArray = [2];
    } else {
      tempSignedInUserNotificationsArray = [];
    }

    async function setSignedInUserNotifications() {
      console.log(tempSignedInUserNotificationsArray);
      const response = await fetch(
        "https://api.splitsees.com/api/notifications",
        {
          method: "POST",
          body: JSON.stringify({
            notification_ids: tempSignedInUserNotificationsArray,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + signedInUserAccessToken,
          },
        }
      );
      const data = await response.json();
      // await setSignedInUserNotifications(data.data);
      console.log(data);
      console.log(response);
    }
    setSignedInUserNotifications();
  };

  //////////////////////////////////////////////////
  ///// Legal / Privacy Policy State/Handlers
  //////////////////////////////////////////////////

  const signedInUserLegalCloseButtonOnClickHandler = (event) => {
    setProfileCardActiveLink("");
    history.push(`/${signedInUserRole}/profile`);
  };

  //////////////////////////////////////////////////
  ///// Account State/Handlers
  //////////////////////////////////////////////////

  // const [signedInUserAccountBankInformationEditStatus, setSignedInAccountBankInformationEditStatus] = useState(true);
  // const [signedInUserAccountPersonalInformationEditStatus, setSignedInAccountPersonalInformationEditStatus] = useState(false);

  const signedInUserAccountCloseButtonOnClickHandler = (event) => {
    setProfileCardActiveLink("");
    history.push(`/${signedInUserRole}/profile`);
    // setSignedInUserAccountPersonalDetailsCity("test");
  };

  const signedInUserAccountBankInformationEditOnClickHandler = (props) => {
    history.push(`/${signInRole}/profile/account/banking-information`);
  };

  const signedInUserAccountPersonalDetailsEditOnClickHandler = (props) => {
    history.push(`/${signInRole}/profile/account/personal-details`);
  };

  // const signedInUserAccountPersonalDetailsEditOnClickHandler = event => {
  //   console.log('reconnect to the personal details page');
  // }

  const signedInUserAccountPasswordResetOnClickHandler = (props) => {
    history.push("/merchant/profile/password-reset");
  };

  // #region Bank Info
  //////////////////////////////////////////////////
  ///// User Bank Information State/Handlers
  //////////////////////////////////////////////////

  // const [
  //   signedInUserBankInfoBankName,
  //   setSignedInUserBankInfoBankName,
  // ] = useState("");
  // const [
  //   signedInUserBankInfoRoutingNumber,
  //   setSignedInUserBankInfoRoutingNumber,
  // ] = useState("");
  // const [
  //   signedInUserBankInfoAccountNumber,
  //   setSignedInUserBankInfoAccountNumber,
  // ] = useState("");
  // const [
  //   signedInUserBankInfoAccountHolderName,
  //   setSignedInBankInfoAccountHolderName,
  // ] = useState("");

  // const signedInuserBankInfoBankNameOnChangeHandler = (event) => {
  //   setSignedInUserBankInfoBankName(event.target.value);
  // };

  // const signedInBankInfoRoutingNumberOnChangeHandler = (event) => {
  //   setSignedInUserBankInfoRoutingNumber(event.target.value);
  // };

  // const signedInUserBankInfoAccountNumberOnChangeHandler = (event) => {
  //   setSignedInUserBankInfoAccountNumber(event.target.value);
  // };

  // const signedInUserBankInfoAccountHolderNameOnChangeHandler = (event) => {
  //   setSignedInBankInfoAccountHolderName(event.target.value);
  // };

  // const signedInUserBankInfoButtonOnClickHandler = (props) => {
  //   // console.log(signedInUserBankInfoBankName);
  //   // console.log(signedInUserBankInfoRoutingNumber);
  //   // console.log(signedInUserBankInfoAccountNumber);
  //   // console.log(signedInUserBankInfoAccountHolderName);

  //   async function setSignedInUserBankInfo() {
  //     const response = await fetch(
  //       "https://api.splitsees.com/api/account/bank-account",
  //       {
  //         method: "PUT",
  //         // body: JSON.stringify({ notification_ids: tempSignedInUserNotificationsArray}),
  //         body: JSON.stringify({
  //           bank_name: signedInUserBankInfoBankName,
  //           holder_name: signedInUserBankInfoAccountHolderName,
  //           routing_number: signedInUserBankInfoRoutingNumber,
  //           account_number: signedInUserBankInfoAccountNumber,
  //         }),
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: "Bearer " + signedInUserAccessToken,
  //         },
  //       }
  //     );
  //     const data = await response.json();
  //     if (response.status === 200) {
  //       alert("Bank Info Set SUCCESS! - @TODO replace this error message");
  //       history.push("/merchant/profile/account");
  //     } else {
  //       alert("Bank Info Set did not work - @TODO replace this error message");
  //     }
  //     // await setSignedInUserNotifications(data.data);
  //     console.log(data);
  //   }
  //   setSignedInUserBankInfo();
  // };
  // #endregion

  //////////////////////////////////////////////////
  ///// Profile Photo State/Handlers
  //////////////////////////////////////////////////

  const [
    signedInUserAccountProfilePhotoFile,
    setSignedInUserAccountProfilePhotoFile,
  ] = useState();

  const signedInUserProfilePhotoUploadLinkOnClickHandler = (props) => {
    history.push(`/${signedInUserRole}/profile/profile-photo`);
  };

  const signedInUserProfilePhotoUploadFileOnChangeHandler = (event) => {
    // console.log(event.target.files[0]);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_preset", "ru0xeipm");
    setSignedInUserAccountProfilePhotoFile(formData);
  };

  const signedInUserProfilePhotoUploadFileButtonOnClickHandler = (props) => {
    async function uploadSignedInUserAccountProfilePhotoFile() {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dpk0itkv7/image/upload",
        {
          method: "POST",
          body: signedInUserAccountProfilePhotoFile,
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setSignedInUserProfileImage(data.secure_url);
        async function setSignedInUserAccountProfilePhotoUrlApi() {
          const response0 = await fetch(
            "https://api.splitsees.com/api/account",
            {
              method: "PUT",
              body: JSON.stringify({
                user: { profile_image: data.secure_url },
              }),
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + signedInUserAccessToken,
              },
            }
          );
          if (response0.status === 200) {
            // const data0 = await response0.json();
            setModalTitle("Profile Photo Upload");
            setModalCopy("Your profile photo has been uploaded successfully!");
            setModalDisplay(true);
            history.push(`/${signedInUserRole}/profile/account`);
          } else {
            setModalTitle("Upload Error");
            setModalCopy(
              "Please try a different image, smaller file sizes may work better for you."
            );
            setModalDisplay(true);
          }
        }
        await setSignedInUserAccountProfilePhotoUrlApi();
      } else {
        setModalTitle("Upload Error");
        setModalCopy(
          "Please try a different image, smaller file sizes may work better for you."
        );
        setModalDisplay(true);
      }
    }
    uploadSignedInUserAccountProfilePhotoFile();
  };

  //////////////////////////////////////////////////
  ///// Profile Photo State/Handlers
  //////////////////////////////////////////////////

  const [
    signedInUserAccountPersonalDetailsFirstName,
    setSignedInUserAccountPersonalDetailsFirstName,
  ] = useState("");
  const [
    signedInUserAccountPersonalDetailsLastName,
    setSignedInUserAccountPersonalDetailsLastName,
  ] = useState("");
  const [
    signedInUserAccountPersonalDetailsEmail,
    setSignedInUserAccountPersonalDetailsEmail,
  ] = useState("");
  const [
    signedInUserAccountPersonalDetailsAddress,
    setSignedInUserAccountPersonalDetailsAddress,
  ] = useState("");
  const [
    signedInUserAccountPersonalDetailsCity,
    setSignedInUserAccountPersonalDetailsCity,
  ] = useState("");
  const [
    signedInUserAccountPersonalDetailsState,
    setSignedInUserAccountPersonalDetailsState,
  ] = useState("");
  const [
    signedInUserAccountPersonalDetilsZipCode,
    setSignedInUserAccountPersonalDetailsZipCode,
  ] = useState("");

  const signedInUserAccountPersonalDetailsFirstNameFieldOnChangeHandler = (
    event
  ) => {
    setSignedInUserAccountPersonalDetailsFirstName(event.target.value);
  };

  const signedInUserAccountPersonalDetailsLastNameFieldOnChangeHandler = (
    event
  ) => {
    setSignedInUserAccountPersonalDetailsLastName(event.target.value);
  };

  const signedInUserAccountPersonalDetailsEmailFieldOnChangeHandler = (
    event
  ) => {
    setSignedInUserAccountPersonalDetailsEmail(event.target.value);
  };

  const signedInUserAccountPersonalDetailsAddressFieldOnChangeHandler = (
    event
  ) => {
    setSignedInUserAccountPersonalDetailsAddress(event.target.value);
  };

  const signedInUserAccountPersonalDetailsCityFieldOnChangeHandler = (
    event
  ) => {
    setSignedInUserAccountPersonalDetailsCity(event.target.value);
  };

  const signedInUserAccountPersonalDetailsStateFieldOnChangeHandler = (
    event
  ) => {
    setSignedInUserAccountPersonalDetailsState(event.target.value);
    console.log(event);
  };

  const signedInUserAccountPersonalDetilsZipCodeFieldChangeHandler = (
    event
  ) => {
    setSignedInUserAccountPersonalDetailsZipCode(event.target.value);
  };

  const signedInUserAccountPersonalDetailsSaveButtonOnClickHandler = (
    props
  ) => {
    async function getSignedInUserPersonalDetailsApi() {
      const response = await fetch(
        "https://api.splitsees.com/api/account",
        {
          method: "PUT",
          body: JSON.stringify({
            user: {
              first_name:
                signedInUserAccountPersonalDetailsFirstName ===
                (undefined || "")
                  ? signedInUserFirstName
                  : signedInUserAccountPersonalDetailsFirstName,
              last_name:
                signedInUserAccountPersonalDetailsLastName === (undefined || "")
                  ? signedInUserLastName
                  : signedInUserAccountPersonalDetailsLastName,
              city:
                signedInUserAccountPersonalDetailsCity === (undefined || "")
                  ? signedInUserCity
                  : signedInUserAccountPersonalDetailsCity,
              zipcode:
                signedInUserAccountPersonalDetilsZipCode === (undefined || "")
                  ? signedInUserZipCode
                  : signedInUserAccountPersonalDetilsZipCode,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + signedInUserAccessToken,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log(data.data);
        setModalTitle("Personal Details Updated");
        setModalCopy("Your new Personal Details have been updated!");
        setModalDisplay(true);
        setSignedInUserFirstName(data.data.user.first_name);
        setSignedInUserLastName(data.data.user.last_name);
        history.push(`/${signedInUserRole}/profile/account`);
      } else {
        setModalTitle("Personal Details Error");
        setModalCopy(
          "Something went wrong - please ensure your personal details are correct and try again"
        );
        setModalDisplay(true);
      }
    }
    getSignedInUserPersonalDetailsApi();
  };

  const getSignedInUserAccountPersonalDetailsHandler = (props) => {
    // async function getSignedInUserPersonalDetailsApi() {
    //   const response = await fetch(
    //     "https://chillhound.sandbox.polymorphic.io/api/account",
    //     {
    //       method: "PUT",
    //       body: JSON.stringify({
    //         user: { first_name: signedInUserFirstName },
    //       }),
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //         Authorization: "Bearer " + signedInUserAccessToken,
    //       },
    //     }
    //   );
    //   if (response.status === 200) {
    //     const data = await response.json();
    //     console.log(data.data);
    //     setSignedInUserAccountPersonalDetailsFirstName(await data.data.user.first_name);
    //     setSignedInUserAccountPersonalDetailsLastName(await data.data.user.last_name);
    //     setSignedInUserAccountPersonalDetailsEmail(await data.data.user.email);
    //     setSignedInUserAccountPersonalDetailsAddress(await data.data.user.merchant.company_address_1);
    //     setSignedInUserAccountPersonalDetailsCity(await data.data.user.merchant.company_city);
    //     setSignedInUserAccountPersonalDetailsState(await data.data.user.merchant.company_state_abbreviation);
    //     setSignedInUserAccountPersonalDetailsZipCode(await data.data.user.merchant.company_zipcode);
    //   } else {
    //     alert(
    //       " splitsee API did not work - @TODO replace this error message"
    //     );
    //   }
    // }
    // getSignedInUserPersonalDetailsApi();
  };

  //////////////////////////////////////////////////
  ///// Profile Password Reset State/Handlers
  //////////////////////////////////////////////////
  const [
    signedInUserProfileCurrentPassword,
    setSignedInUserProfileCurrentPassword,
  ] = useState("");
  const [
    signedInUserProfileNewPassword,
    setSignedInUserProfileNewPassword,
  ] = useState("");
  const [
    signedInUserProfileNewPasswordConfirm,
    setSignedInUserProfileNewPasswordConfirm,
  ] = useState("");

  const signedInUserProfileCurrentPasswordOnChangeHandler = (event) => {
    setSignedInUserProfileCurrentPassword(event.target.value);
  };

  const signedInUserProfileNewPasswordOnChangeHandler = (event) => {
    setSignedInUserProfileNewPassword(event.target.value);
  };

  const signedInUserProfileNewPasswordConfirmOnChangeHandler = (event) => {
    setSignedInUserProfileNewPasswordConfirm(event.target.value);
  };

  const signedInUserProfilePasswordResetButtonOnClickHandler = (props) => {
    // console.log(signedInUserProfileCurrentPassword);
    // console.log(signedInUserProfileNewPassword);
    // console.log(signedInUserProfileNewPasswordConfirm);

    async function signedInUserProfilePasswordResetApi() {
      const response = await fetch(
        "https://api.splitsees.com/api/account/change-password",
        {
          method: "PUT",
          body: JSON.stringify({
            password: signedInUserProfileCurrentPassword,
            new_password: signedInUserProfileNewPassword,
            new_password_confirmation: signedInUserProfileNewPasswordConfirm,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + signedInUserAccessToken,
          },
        }
      );
      // const data = await response.json();
      if (response.status === 200) {
        setModalTitle("Password Reset");
        setModalCopy("Your password has successfully been reset!");
        setModalDisplay(true);
        history.push(`/${signedInUserRole}/profile/account`);
      } else {
        setModalTitle("Password Reset Error");
        setModalCopy(
          "Your password was not reset. Please ensure your password information is correct and try again"
        );
        setModalDisplay(true);
      }
      // console.log(data);
    }
    signedInUserProfilePasswordResetApi();
  };

  //////////////////////////////////////////////////
  ///// Merchant Venue Create Venue ( Venue Details) State/Handlers
  //////////////////////////////////////////////////

  const [
    merchantVenueCreateVenueName,
    setMerchantVenueCreateVenueName,
  ] = useState("");
  const [
    merchantVenueCreateVenueWebsite,
    setMerchantVenueCreateVenueWebsite,
  ] = useState("");
  const [
    merchantVenueCreateVenueAddressLine1,
    setMerchantVenueCreateVenueAddressLine1,
  ] = useState("");
  const [
    merchantVenueCreateVenueAddressLine2,
    setMerchantVenueCreateVenueAddressLine2,
  ] = useState("");
  const [
    merchantVenueCreateVenueAddressCity,
    setMerchantVenueCreateVenueAddressCity,
  ] = useState("");
  const [
    merchantVenueCreateVenueAddressState,
    setMerchantVenueCreateVenueAddressState,
  ] = useState("");
  const [
    merchantVenueCreateVenuePhoneNumber,
    setMerchantVenueCreateVenuePhoneNumber,
  ] = useState("");
  const [
    merchantVenueCreateVenueAddressZipCode,
    setMerchantVenueCreateVenueAddressZipCode,
  ] = useState("");
  const [
    merchantVenueCreateVenueFacebookPage,
    setMerchantVenueCreateVenueFacebookPage,
  ] = useState("");

  const [
    merchantVenueCreateVenueLogoFile,
    setMerchantVenueCreateVenueLogoFile,
  ] = useState();

  const [
    merchantVenueCreateVenueBackgroundImageFile,
    setMerchantVenueCreateVenueBackgroundImageFile,
  ] = useState();

  // const [merchantVenueCreateVenueLogoUrl, setMerchantVenueCreateVenueLogoUrl] =
  //   useState("");
  // const [
  //   merchantVenueCreateVenueBackgroundImageUrl,
  //   setMerchantVenueCreateVenueBackgroundImageUrl,
  // ] = useState("");

  const merchantVenueCreateVenueNameFieldOnChangeHandler = (event) => {
    setMerchantVenueCreateVenueName(event.target.value);
  };

  const merchantVenueCreateVenueWebsiteFieldOnChangeHandler = (event) => {
    setMerchantVenueCreateVenueWebsite(event.target.value);
  };

  const merchantVenueCreateVenueAddressLine1FieldOnChangeHandler = (event) => {
    setMerchantVenueCreateVenueAddressLine1(event.target.value);
  };

  const merchantVenueCreateVenueAddressLine2FieldOnChangeHandler = (event) => {
    setMerchantVenueCreateVenueAddressLine2(event.target.value);
  };

  const merchantVenueCreateVenueAddressCityFieldOnChangeHandler = (event) => {
    setMerchantVenueCreateVenueAddressCity(event.target.value);
  };

  const merchantVenueCreateVenueAddressStateFieldOnChangeHandler = (event) => {
    setMerchantVenueCreateVenueAddressState(event.target.value);
  };

  const merchantVenueCreateVenuePhoneNumberFieldOnChangeHandler = (event) => {
    setMerchantVenueCreateVenuePhoneNumber(event.target.value);
  };

  const merchantVenueCreateVenueAddressZipCodeFieldOnChangeHandler = (
    event
  ) => {
    setMerchantVenueCreateVenueAddressZipCode(event.target.value);
  };

  const merchantVenueCreateVenueFacebookPageFieldOnChangeHandler = (event) => {
    setMerchantVenueCreateVenueFacebookPage(event.target.value);
  };

  const merchantVenueCreateVenueLogoFieldOnChangeHandler = (event) => {
    console.log(event.target.files[0]);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_preset", "ru0xeipm");
    setMerchantVenueCreateVenueLogoFile(formData);
  };

  const merchantVenueCreateVenueBackgroundImageFieldOnChangeHandler = (
    event
  ) => {
    console.log(event.target.files[0]);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_preset", "ru0xeipm");
    setMerchantVenueCreateVenueBackgroundImageFile(formData);
  };

  const merchantVenueCreateVenueNextButtonOnClickHandler = (event) => {
    // console.log(merchantVenueCreateVenueName);
    // console.log(merchantVenueCreateVenueWebsite);
    // console.log(merchantVenueCreateVenueAddressLine1);
    // console.log(merchantVenueCreateVenueAddressLine2);
    // console.log(merchantVenueCreateVenueAddressCity);
    // console.log(merchantVenueCreateVenueAddressState);
    // console.log(merchantVenueCreateVenuePhoneNumber);
    // console.log(merchantVenueCreateVenueAddressZipCode);
    // console.log(merchantVenueCreateVenueFacebookPage);
    // console.log(merchantVenueCreateVenueLogoFile);
    // console.log(merchantVenueCreateVenueBackgroundImageFile);

    // uploadCreateVenueLogo();
    // uploadCreateVenueBackgroundImage();
    history.push("/merchant/venue/add-details/hours-of-operation");
  };

  ////////// Hours Of Operaiton -- since Safari doesn't really support a Time input, there are 3
  ////////// seperate states for Hour, Minute and AMPM. I put them together and change them to
  ////////// military time for the API endpoint for creating a venue

  // #region Monday
  const [
    merchantVenueCreateVenueHoursOfOperaitonMondayOpenHour,
    setMerchantVenueCreateVenueHoursOfOperaitonMondayOpenHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonMondayOpenMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonMondayOpenMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonMondayOpenAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonMondayOpenAmpm,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonMondayCloseHour,
    setMerchantVenueCreateVenueHoursOfOperaitonMondayCloseHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonMondayCloseMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonMondayCloseMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonMondayCloseAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonMondayCloseAmpm,
  ] = useState("");
  //#endregion
  // #region Tuesday
  // const [
  //   merchantVenueCreateVenueHoursOfOperationTuesdayStatus,
  //   setMerchantVenueCreateVenueHoursOfOperationTuesdayStatus,
  // ] = useState(false);

  const [
    merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenHour,
    setMerchantVenueCreateVenueHoursOfOperaitonTuesdayOpenHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonTuesdayOpenMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonTuesdayOpenAmpm,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseHour,
    setMerchantVenueCreateVenueHoursOfOperaitonTuesdayCloseHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonTuesdayCloseMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonTuesdayCloseAmpm,
  ] = useState("");
  //#endregion
  // #region Wednesday
  const [
    merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenHour,
    setMerchantVenueCreateVenueHoursOfOperaitonWednesdayOpenHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonWednesdayOpenMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonWednesdayOpenAmpm,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseHour,
    setMerchantVenueCreateVenueHoursOfOperaitonWednesdayCloseHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonWednesdayCloseMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonWednesdayCloseAmpm,
  ] = useState("");
  //#endregion
  // #region Thursday
  const [
    merchantVenueCreateVenueHoursOfOperaitonThursdayOpenHour,
    setMerchantVenueCreateVenueHoursOfOperaitonThursdayOpenHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonThursdayOpenMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonThursdayOpenMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonThursdayOpenAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonThursdayOpenAmpm,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonThursdayCloseHour,
    setMerchantVenueCreateVenueHoursOfOperaitonThursdayCloseHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonThursdayCloseMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonThursdayCloseMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonThursdayCloseAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonThursdayCloseAmpm,
  ] = useState("");
  //#endregion
  // #region Friday
  const [
    merchantVenueCreateVenueHoursOfOperaitonFridayOpenHour,
    setMerchantVenueCreateVenueHoursOfOperaitonFridayOpenHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonFridayOpenMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonFridayOpenMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonFridayOpenAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonFridayOpenAmpm,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonFridayCloseHour,
    setMerchantVenueCreateVenueHoursOfOperaitonFridayCloseHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonFridayCloseMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonFridayCloseMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonFridayCloseAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonFridayCloseAmpm,
  ] = useState("");
  // #endregion
  // #region Saturday
  const [
    merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenHour,
    setMerchantVenueCreateVenueHoursOfOperaitonSaturdayOpenHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonSaturdayOpenMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonSaturdayOpenAmpm,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseHour,
    setMerchantVenueCreateVenueHoursOfOperaitonSaturdayCloseHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonSaturdayCloseMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonSaturdayCloseAmpm,
  ] = useState("");
  //#endregion
  // #region Sunday
  const [
    merchantVenueCreateVenueHoursOfOperaitonSundayOpenHour,
    setMerchantVenueCreateVenueHoursOfOperaitonSundayOpenHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonSundayOpenMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonSundayOpenMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonSundayOpenAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonSundayOpenAmpm,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonSundayCloseHour,
    setMerchantVenueCreateVenueHoursOfOperaitonSundayCloseHour,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonSundayCloseMinute,
    setMerchantVenueCreateVenueHoursOfOperaitonSundayCloseMinute,
  ] = useState("");

  const [
    merchantVenueCreateVenueHoursOfOperaitonSundayCloseAmpm,
    setMerchantVenueCreateVenueHoursOfOperaitonSundayCloseAmpm,
  ] = useState("");
  // #endregion

  // #region Checkboxes that enable the open/close time inputs
  const [
    mechantVenueCreateVenueHoursOfOperationMondayCheckbox,
    setMechantVenueCreateVenueHoursOfOperationMondayCheckbox,
  ] = useState(false);

  const [
    mechantVenueCreateVenueHoursOfOperationTuesdayCheckbox,
    setMechantVenueCreateVenueHoursOfOperationTuesdayCheckbox,
  ] = useState(false);

  const [
    mechantVenueCreateVenueHoursOfOperationWednesdayCheckbox,
    setMechantVenueCreateVenueHoursOfOperationWednesdayCheckbox,
  ] = useState(false);

  const [
    mechantVenueCreateVenueHoursOfOperationThursdayCheckbox,
    setMechantVenueCreateVenueHoursOfOperationThursdayCheckbox,
  ] = useState(false);

  const [
    mechantVenueCreateVenueHoursOfOperationFridayCheckbox,
    setMechantVenueCreateVenueHoursOfOperationFridayCheckbox,
  ] = useState(false);

  const [
    mechantVenueCreateVenueHoursOfOperationSaturdayCheckbox,
    setMechantVenueCreateVenueHoursOfOperationSaturdayCheckbox,
  ] = useState(false);
  const [
    mechantVenueCreateVenueHoursOfOperationSundayCheckbox,
    setMechantVenueCreateVenueHoursOfOperationSundayCheckbox,
  ] = useState(false);
  // #endregion

  const merchantVenueCreateVenueHoursOfOperationHourOnChangeHandler = (
    event
  ) => {
    const day = event.target.id.split("-")[0];
    const period = event.target.id.split("-")[1];
    // console.log(day);
    // console.log(period);
    // console.log(event.target.value);

    switch (day) {
      default:
        break;
      case "monday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonMondayOpenHour(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonMondayCloseHour(
            event.target.value
          );
        }
        break;
      case "tuesday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonTuesdayOpenHour(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonTuesdayCloseHour(
            event.target.value
          );
        }
        break;
      case "wednesday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonWednesdayOpenHour(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonWednesdayCloseHour(
            event.target.value
          );
        }
        break;
      case "thursday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonThursdayOpenHour(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonThursdayCloseHour(
            event.target.value
          );
        }
        break;
      case "friday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonFridayOpenHour(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonFridayCloseHour(
            event.target.value
          );
        }
        break;
      case "saturday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonSaturdayOpenHour(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonSaturdayCloseHour(
            event.target.value
          );
        }
        break;
      case "sunday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonSundayOpenHour(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonSundayCloseHour(
            event.target.value
          );
        }
        break;
    }
  };

  const merchantVenueCreateVenueHoursOfOperationMinuteOnChangeHandler = (
    event
  ) => {
    const day = event.target.id.split("-")[0];
    const period = event.target.id.split("-")[1];
    // console.log(day);
    // console.log(period);
    // console.log(event.target.value);

    switch (day) {
      case "monday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonMondayOpenMinute(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonMondayCloseMinute(
            event.target.value
          );
        }
        break;
      case "tuesday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonTuesdayOpenMinute(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonTuesdayCloseMinute(
            event.target.value
          );
        }
        break;
      case "wednesday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonWednesdayOpenMinute(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonWednesdayCloseMinute(
            event.target.value
          );
        }
        break;
      case "thursday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonThursdayOpenMinute(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonThursdayCloseMinute(
            event.target.value
          );
        }
        break;
      case "friday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonFridayOpenMinute(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonFridayCloseMinute(
            event.target.value
          );
        }
        break;
      case "saturday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonSaturdayOpenMinute(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonSaturdayCloseMinute(
            event.target.value
          );
        }
        break;
      case "sunday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonSundayOpenMinute(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonSundayCloseMinute(
            event.target.value
          );
        }
        break;
      default:
        break;
    }
  };

  const merchantVenueCreateVenueHoursOfOperationAmpmOnChangeHanlder = (
    event
  ) => {
    const day = event.target.id.split("-")[0];
    const period = event.target.id.split("-")[1];
    console.log(day);
    console.log(period);
    console.log(event.target.value);
    switch (day) {
      default:
        break;
      case "monday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonMondayOpenAmpm(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonMondayCloseAmpm(
            event.target.value
          );
        }
        break;
      case "tuesday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonTuesdayOpenAmpm(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonTuesdayCloseAmpm(
            event.target.value
          );
        }
        break;
      case "wednesday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonWednesdayOpenAmpm(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonWednesdayCloseAmpm(
            event.target.value
          );
        }
        break;
      case "thursday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonThursdayOpenAmpm(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonThursdayCloseAmpm(
            event.target.value
          );
        }
        break;
      case "friday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonFridayOpenAmpm(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonFridayCloseAmpm(
            event.target.value
          );
        }
        break;
      case "saturday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonSaturdayOpenAmpm(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonSaturdayCloseAmpm(
            event.target.value
          );
        }
        break;
      case "sunday":
        if (period === "open") {
          setMerchantVenueCreateVenueHoursOfOperaitonSundayOpenAmpm(
            event.target.value
          );
        } else {
          setMerchantVenueCreateVenueHoursOfOperaitonSundayCloseAmpm(
            event.target.value
          );
        }
        break;
    }
  };

  // Checkbox On Change Handlers

  const mechantVenueCreateVenueHoursOfOperationCheckboxOnChangeHandler = (
    event
  ) => {
    const day = event.target.id;
    switch (day) {
      default:
        break;
      case "monday":
        setMechantVenueCreateVenueHoursOfOperationMondayCheckbox(
          !mechantVenueCreateVenueHoursOfOperationMondayCheckbox
        );
        break;
      case "tuesday":
        setMechantVenueCreateVenueHoursOfOperationTuesdayCheckbox(
          !mechantVenueCreateVenueHoursOfOperationTuesdayCheckbox
        );
        break;
      case "wednesday":
        setMechantVenueCreateVenueHoursOfOperationWednesdayCheckbox(
          !mechantVenueCreateVenueHoursOfOperationWednesdayCheckbox
        );
        break;
      case "thursday":
        setMechantVenueCreateVenueHoursOfOperationThursdayCheckbox(
          !mechantVenueCreateVenueHoursOfOperationThursdayCheckbox
        );
        break;
      case "friday":
        setMechantVenueCreateVenueHoursOfOperationFridayCheckbox(
          !mechantVenueCreateVenueHoursOfOperationFridayCheckbox
        );
        break;
      case "saturday":
        setMechantVenueCreateVenueHoursOfOperationSaturdayCheckbox(
          !mechantVenueCreateVenueHoursOfOperationSaturdayCheckbox
        );
        break;
      case "sunday":
        setMechantVenueCreateVenueHoursOfOperationSundayCheckbox(
          !mechantVenueCreateVenueHoursOfOperationSundayCheckbox
        );
        break;
    }
  };

  const merchantVenueCreateVenueSaveVenueButtonClickHandler = (event) => {
    const hoursArray = [];
    if (merchantVenueCreateVenueHoursOfOperaitonSundayOpenHour !== "") {
      hoursArray.push({
        weekday_id: 7,
        start_time:
          merchantVenueCreateVenueHoursOfOperaitonSundayOpenAmpm === "am"
            ? parseInt(merchantVenueCreateVenueHoursOfOperaitonSundayOpenHour) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonSundayOpenMinute
            : parseInt(merchantVenueCreateVenueHoursOfOperaitonSundayOpenHour) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonSundayOpenMinute,
        end_time:
          merchantVenueCreateVenueHoursOfOperaitonSundayCloseAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonSundayCloseHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonSundayCloseMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonSundayCloseHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonSundayCloseMinute,
      });
    }
    if (merchantVenueCreateVenueHoursOfOperaitonMondayOpenHour !== "") {
      hoursArray.push({
        weekday_id: 1,
        start_time:
          merchantVenueCreateVenueHoursOfOperaitonMondayOpenAmpm === "am"
            ? parseInt(merchantVenueCreateVenueHoursOfOperaitonMondayOpenHour) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonMondayOpenMinute
            : parseInt(merchantVenueCreateVenueHoursOfOperaitonMondayOpenHour) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonMondayOpenMinute,
        end_time:
          merchantVenueCreateVenueHoursOfOperaitonMondayCloseAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonMondayCloseHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonMondayCloseMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonMondayCloseHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonMondayCloseMinute,
      });
    }
    if (merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenHour !== "") {
      hoursArray.push({
        weekday_id: 2,
        start_time:
          merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenMinute,
        end_time:
          merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseMinute,
      });
    }
    if (merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenHour !== "") {
      hoursArray.push({
        weekday_id: 3,
        start_time:
          merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenMinute,
        end_time:
          merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseMinute,
      });
    }
    if (merchantVenueCreateVenueHoursOfOperaitonThursdayOpenHour !== "") {
      hoursArray.push({
        weekday_id: 4,
        start_time:
          merchantVenueCreateVenueHoursOfOperaitonThursdayOpenAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonThursdayOpenHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonThursdayOpenMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonThursdayOpenHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonThursdayOpenMinute,
        end_time:
          merchantVenueCreateVenueHoursOfOperaitonThursdayCloseAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonThursdayCloseHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonThursdayCloseMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonThursdayCloseHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonThursdayCloseMinute,
      });
    }
    if (merchantVenueCreateVenueHoursOfOperaitonFridayOpenHour !== "") {
      hoursArray.push({
        weekday_id: 5,
        start_time:
          merchantVenueCreateVenueHoursOfOperaitonFridayOpenAmpm === "am"
            ? parseInt(merchantVenueCreateVenueHoursOfOperaitonFridayOpenHour) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonFridayOpenMinute
            : parseInt(merchantVenueCreateVenueHoursOfOperaitonFridayOpenHour) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonFridayOpenMinute,
        end_time:
          merchantVenueCreateVenueHoursOfOperaitonFridayCloseAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonFridayCloseHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonFridayCloseMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonFridayCloseHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonFridayCloseMinute,
      });
    }
    if (merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenHour !== "") {
      hoursArray.push({
        weekday_id: 6,
        start_time:
          merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenMinute,
        end_time:
          merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseAmpm === "am"
            ? parseInt(
                merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseHour
              ) +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseMinute
            : parseInt(
                merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseHour
              ) +
              12 +
              ":" +
              merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseMinute,
      });
    }

    // async function allCreateVenueCalls() {

    //   const response0 = await fetch(
    //     "https://api.cloudinary.com/v1_1/dpk0itkv7/image/upload",
    //     {
    //       method: "POST",
    //       body: merchantVenueCreateVenueLogoFile,
    //     }
    //   );
    //   if ( response0.status === 200) {
    //     // data0 = await response0.json();
    //     // setMerchantVenueCreateVenueLogoUrl(await data0.secure_url);
    //     console.log('logo');
    //   } else {
    //     setModalTitle("Venue Logo Upload Error");
    //     setModalCopy(
    //       "Something went wrong and your Venue Logo did not upload."
    //     );
    //     setModalDisplay(true);
    //   }
    //   const response1 = await fetch(
    //     "https://api.cloudinary.com/v1_1/dpk0itkv7/image/upload",
    //     {
    //       method: "POST",
    //       body: merchantVenueCreateVenueBackgroundImageFile,
    //     }
    //   );
    //   if ( response1.status === 200) {
    //     // data1 = response1.json();
    //     // setMerchantVenueCreateVenueBackgroundImageUrl(await data1.secure_url);
    //     console.log('background');
    //     console.log(merchantVenueCreateVenueBackgroundImageUrl);
    //     console.log(merchantVenueCreateVenueLogoUrl);
    //   } else {
    //     setModalTitle("Venue Background Image Upload Error");
    //     setModalCopy(
    //       "Something went wrong and your Venue Background Image did not upload."
    //     );
    //     setModalDisplay(true);
    //   }
    //   const response2 = await fetch(
    //     "https://chillhound.sandbox.polymorphic.io/api/venues",
    //     {
    //       method: "POST",
    //       body: JSON.stringify({
    //         name: merchantVenueCreateVenueName,
    //         address_1: merchantVenueCreateVenueAddressLine1,
    //         address_2: merchantVenueCreateVenueAddressLine2,
    //         city: merchantVenueCreateVenueAddressCity,
    //         state_abbreviation: merchantVenueCreateVenueAddressState,
    //         zipcode: parseInt(merchantVenueCreateVenueAddressZipCode),
    //         phone: merchantVenueCreateVenuePhoneNumber,
    //         website: merchantVenueCreateVenueWebsite,
    //         facebook_link: merchantVenueCreateVenueFacebookPage,
    //         background_image: (response1.json()).secure_url,
    //         logo: (response0.json()).secure_url,
    //         operation_hours: hoursArray,
    //       }),
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //         Authorization: "Bearer " + signedInUserAccessToken,
    //       },
    //     }
    //   );
    //   if ( response2.status === 200) {
    //     setModalTitle("Venue Created");
    //     setModalCopy("Your venue has sucessfully been created!");
    //     setModalDisplay(true);
    //     history.push("/merchant/venue");
    //   } else {
    //     setModalTitle("Venue Error");
    //     setModalCopy("Your venue was not created. Please try again.");
    //     setModalDisplay(true);
    //     console.log(JSON.stringify({
    //               name: merchantVenueCreateVenueName,
    //               address_1: merchantVenueCreateVenueAddressLine1,
    //               address_2: merchantVenueCreateVenueAddressLine2,
    //               city: merchantVenueCreateVenueAddressCity,
    //               state_abbreviation: merchantVenueCreateVenueAddressState,
    //               zipcode: parseInt(merchantVenueCreateVenueAddressZipCode),
    //               phone: merchantVenueCreateVenuePhoneNumber,
    //               website: merchantVenueCreateVenueWebsite,
    //               facebook_link: merchantVenueCreateVenueFacebookPage,
    //               background_image: merchantVenueCreateVenueBackgroundImageUrl,
    //               logo: merchantVenueCreateVenueLogoUrl,
    //               operation_hours: hoursArray,
    //             }))
    //   }
    // }

    // allCreateVenueCalls();

    let logoFileUrl;
    let backgroundFileUrl;

    async function uploadCreateVenueLogo() {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dpk0itkv7/image/upload",
        {
          method: "POST",
          body: merchantVenueCreateVenueLogoFile,
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        logoFileUrl = await data.secure_url;
        // setMerchantVenueCreateVenueLogoUrl(data.secure_url);
        console.log(logoFileUrl);
        await uploadCreateVenueBackgroundImage();
      } else {
        setModalTitle("Venue Logo Upload Error");
        setModalCopy(
          "Something went wrong and your Venue Logo did not upload."
        );
        setModalDisplay(true);
      }
    }
    async function uploadCreateVenueBackgroundImage() {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dpk0itkv7/image/upload",
        {
          method: "POST",
          body: merchantVenueCreateVenueBackgroundImageFile,
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        backgroundFileUrl = data.secure_url;
        // setMerchantVenueCreateVenueBackgroundImageUrl(data.secure_url);
        console.log(backgroundFileUrl);
        await createVenueApi();
      } else {
        setModalTitle("Venue Background Image Upload Error");
        setModalCopy(
          "Something went wrong and your Venue Background Image did not upload."
        );
        setModalDisplay(true);
      }
    }
    async function createVenueApi() {
      let createVenueApiToken = signedInUserAccessToken;
      const response = await fetch(
        "https://api.splitsees.com/api/venues",
        {
          method: "POST",
          body: JSON.stringify({
            name: merchantVenueCreateVenueName,
            address_1: merchantVenueCreateVenueAddressLine1,
            address_2: merchantVenueCreateVenueAddressLine2,
            city: merchantVenueCreateVenueAddressCity,
            state_abbreviation: merchantVenueCreateVenueAddressState,
            zipcode: parseInt(merchantVenueCreateVenueAddressZipCode),
            phone: merchantVenueCreateVenuePhoneNumber,
            website: merchantVenueCreateVenueWebsite,
            facebook_link: merchantVenueCreateVenueFacebookPage,
            background_image: backgroundFileUrl,
            logo: logoFileUrl,
            operation_hours: hoursArray,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + createVenueApiToken,
          },
        }
      );
      // console.log(response.status);
      if (response.status === 201) {
        const getCreateVenueResponseAuth = response.headers.get(
          "Authorization"
        );
        createVenueApiToken = getCreateVenueResponseAuth.split(" ")[1];
        setSignedInUserAccessToken(createVenueApiToken);
        setModalTitle("Venue Created");
        setModalCopy("Your venue has sucessfully been created!");
        setModalDisplay(true);

        history.push("/merchant/venue");
      } else {
        setModalTitle("Venue Error");
        setModalCopy("Your venue was not created. Please try again.");
        setModalDisplay(true);

        // console.log(
        //   JSON.stringify({
        //     name: merchantVenueCreateVenueName,
        //     address_1: merchantVenueCreateVenueAddressLine1,
        //     address_2: merchantVenueCreateVenueAddressLine2,
        //     city: merchantVenueCreateVenueAddressCity,
        //     state_abbreviation: merchantVenueCreateVenueAddressState,
        //     zipcode: parseInt(merchantVenueCreateVenueAddressZipCode),
        //     phone: merchantVenueCreateVenuePhoneNumber,
        //     website: merchantVenueCreateVenueWebsite,
        //     facebook_link: merchantVenueCreateVenueFacebookPage,
        //     background_image: backgroundFileUrl,
        //     logo: logoFileUrl,
        //     operation_hours: hoursArray,
        //   })
        // );
      }
    }

    uploadCreateVenueLogo();
  };

  const merchantVenueCreateVenueBackButtonClickHandler = (event) => {
    console.log("back");
    // history.push("/merchant/venue/add-details/hours-of-operation");
  };

  const merchantVenueCreateVenueButtonOnClickHandler = (props) => {
    history.push("/merchant/venue/add-details");
  };

  //////////////////////////////////////////////////
  ///// Merchant Venue - List Venues State/Handlers
  //////////////////////////////////////////////////

  const [
    merchantVenueListVenuesZeroLengthFlag,
    setMerchantVenueListVenuesZeroLengthFlag,
  ] = useState(true);
  const [
    merchantVenueListVenuesFilteredList,
    setMerchantVenueListVenuesFilteredList,
  ] = useState([]);

  const merchantVenueListVenues = (props) => {
    async function listMerchantVenues() {
      let listMerchantVenuesToken = signedInUserAccessToken;
      const response = await fetch(
        "https://api.splitsees.com/api/lookups/venues?page=1&rpp=100",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + listMerchantVenuesToken,
          },
        }
      );
      const data = await response.json();
      const data0 = await data.data;
      const data1 = await data0.filter((venue) => {
        return venue.merchant_id === signedInUserMerchantId;
      });
      if ((await data1.length) === 0) {
        setMerchantVenueListVenuesZeroLengthFlag(true);
      } else {
        setMerchantVenueListVenuesZeroLengthFlag(false);
        setMerchantVenueListVenuesFilteredList(data1);
        // const getCreateVenueResponseAuth = response.headers.get('Authorization');
        // createVenueApiToken = getCreateVenueResponseAuth.split(' ')[1];
        // setSignedInUserAccessToken(createVenueApiToken);
      }
    }

    listMerchantVenues();
  };

  //////////////////////////////////////////////////
  ///// Merchant Services - List Services State/Handlers
  //////////////////////////////////////////////////

  const merchantServiceCreateServiceButtonOnClickHandler = (props) => {
    history.push("/merchant/services/add-service");
  };

  //////////////////////////////////////////////////
  ///// Merchant Services - Add Service State/Handlers
  //////////////////////////////////////////////////

  const [
    merchantServiceCreateServiceSelectedVenue,
    setMerchantServiceCreateServiceSelectedVenue,
  ] = useState("");
  const [
    merchantServiceCreateServiceImage,
    setMerchantServiceCreateServiceImage,
  ] = useState("");
  const [
    merhcantServiceCreateServiceName,
    setMerchantServiceCreateServiceName,
  ] = useState("");
  const [
    merchantServiceCreateServiceQuota,
    setMerchantServiceCreateServiceQuota,
  ] = useState("");
  const [
    merchantServiceCreateServiceMinParticipants,
    setMerchantServiceCreateServiceMinParticipants,
  ] = useState("");
  const [
    merchantServicesCreateServiceMaxParticipants,
    setMerchantServiceCreateServiceMaxParticipants,
  ] = useState("");
  const [
    merchantServiceCreateServiceShortDescription,
    setMerchantServiceCreateServiceShortDescription,
  ] = useState("");
  const [
    merchantServiceCreateServiceLongDescription,
    setMerchantServiceCreateServiceLongDescription,
  ] = useState("");
  const [
    merchantServiceCreateServicePrice,
    setMerchantServiceCreateServicePrice,
  ] = useState("");
  const [
    merchantServiceCreateServicePerUnit,
    setMerchantServiceCreateServicePerUnit,
  ] = useState("");

  const [
    merchantServiceCreateServiceSelectedVenueValidationStatus,
    setMerchantServiceCreateServiceSelectedVenueValidationStatus,
  ] = useState(false);

  const [
    merchantServiceCreateServiceImageValidationStatus,
    setMerchantServiceCreateServiceImageValidationStatus,
  ] = useState(false);
  const [
    merchantServiceCreateServiceNameValidationStatus,
    setMerchantServiceCreateServiceNameValidationStatus,
  ] = useState(false);
  const [
    merchantServiceCreateServiceQuotaValidationStatus,
    setMerchantServiceCreateServiceQuotaValidationStatus,
  ] = useState(false);
  const [
    merchantServiceCreateServiceMinParticipantsValidationStatus,
    setMerchantServiceCreateServiceMinParticipantsValidationStatus,
  ] = useState(false);
  const [
    merchantServiceCreateServiceMaxParticipantsValidationStatus,
    setMerchantServiceCreateServiceMaxParticipantsValidationStatus,
  ] = useState(false);
  const [
    merchantServiceCreateServiceShortDescriptionValidationStatus,
    setMerchantServiceCreateServiceShortDescriptionValidationStatus,
  ] = useState(false);
  const [
    merchantServiceCreateServiceLongDescriptionValidationStatus,
    setMerchantServiceCreateServiceLongDescriptionValidationStatus,
  ] = useState(false);
  const [
    merchantServiceCreateServicePriceValidationStatus,
    setMerchantServiceCreateServicePriceValidationStatus,
  ] = useState(false);
  const [
    merchantServiceCreateServicePerUnitValidationStatus,
    setMerchantServiceCreateServicePerUnitValidationStatus,
  ] = useState(false);

  const merchantServiceCreateServiceSelectedVenueOnChangeHandler = (event) => {
    setMerchantServiceCreateServiceSelectedVenue(event.target.value);
    event.target.value !== ""
      ? setMerchantServiceCreateServiceSelectedVenueValidationStatus(true)
      : setMerchantServiceCreateServiceSelectedVenueValidationStatus(false);
  };

  const merchantServiceCreateServiceImageInputOnChangeHandler = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_preset", "ru0xeipm");
    setMerchantServiceCreateServiceImage(formData);
    event.target.value !== ""
      ? setMerchantServiceCreateServiceImageValidationStatus(true)
      : setMerchantServiceCreateServiceImageValidationStatus(false);
  };

  const merchantServiceCreateServiceNameInputOnChangeHandler = (event) => {
    setMerchantServiceCreateServiceName(event.target.value);
    event.target.value !== ""
      ? setMerchantServiceCreateServiceNameValidationStatus(true)
      : setMerchantServiceCreateServiceNameValidationStatus(false);
  };

  const merchantServiceCreateServiceQuotaInputOnChangeHandler = (event) => {
    setMerchantServiceCreateServiceQuota(event.target.value);
    event.target.value !== ""
      ? setMerchantServiceCreateServiceQuotaValidationStatus(true)
      : setMerchantServiceCreateServiceQuotaValidationStatus(false);
  };

  const merchantServiceCreateServiceMinParticipantsInputOnChangeHandler = (
    event
  ) => {
    setMerchantServiceCreateServiceMinParticipants(event.target.value);
    event.target.value !== ""
      ? setMerchantServiceCreateServiceMinParticipantsValidationStatus(true)
      : setMerchantServiceCreateServiceMinParticipantsValidationStatus(false);
  };

  const merchantServiceCreateServiceMaxParticipantsInputOnChangeHandler = (
    event
  ) => {
    setMerchantServiceCreateServiceMaxParticipants(event.target.value);
    event.target.value !== ""
      ? setMerchantServiceCreateServiceMaxParticipantsValidationStatus(true)
      : setMerchantServiceCreateServiceMaxParticipantsValidationStatus(false);
  };

  const merchantServiceCreateServiceShortDescriptionInputOnChangeHandler = (
    event
  ) => {
    setMerchantServiceCreateServiceShortDescription(event.target.value);
    event.target.value !== ""
      ? setMerchantServiceCreateServiceShortDescriptionValidationStatus(true)
      : setMerchantServiceCreateServiceShortDescriptionValidationStatus(false);
  };

  const merchantServiceCreateServiceLongDescriptionInputOnChangeHandler = (
    event
  ) => {
    setMerchantServiceCreateServiceLongDescription(event.target.value);
    event.target.value !== ""
      ? setMerchantServiceCreateServiceLongDescriptionValidationStatus(true)
      : setMerchantServiceCreateServiceLongDescriptionValidationStatus(false);
  };

  const merchantServiceCreateServicePriceInputOnChangeHandler = (event) => {
    setMerchantServiceCreateServicePrice(event.target.value);
    event.target.value !== ""
      ? setMerchantServiceCreateServicePriceValidationStatus(true)
      : setMerchantServiceCreateServicePriceValidationStatus(false);
  };

  const merchantServiceCreateServicePerUnitInputOnChangeHandler = (event) => {
    setMerchantServiceCreateServicePerUnit(event.target.value);
    event.target.value !== ""
      ? setMerchantServiceCreateServicePerUnitValidationStatus(true)
      : setMerchantServiceCreateServicePerUnitValidationStatus(false);
  };

  const merchantServiceCreateServiceSaveServiceButtonOnClickHandler = (
    props
  ) => {
    let serviceImageUrl;
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const formatYmd = (date) => date.toISOString().slice(0, 10);
    async function uploadCreateServicePhoto() {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dpk0itkv7/image/upload",
        {
          method: "POST",
          body: merchantServiceCreateServiceImage,
        }
      );
      if (response.status === 200) {
        const data = await response.json();

        serviceImageUrl = await data.secure_url;
        await merchantServiceCreateServiceApi();
      } else {
        setModalTitle("Service Image Upload Error");
        setModalCopy(
          "Something went wrong and your Service Image did not upload."
        );
        setModalDisplay(true);
      }
    }

    async function merchantServiceCreateServiceApi() {
      let merchantServiceCreateServiceApiToken = signedInUserAccessToken;
      const response = await fetch(
        `https://api.splitsees.com/api/venues/${merchantServiceCreateServiceSelectedVenue}/services`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + merchantServiceCreateServiceApiToken,
          },
          body: JSON.stringify({
            type: "service-type",
            name: merhcantServiceCreateServiceName,
            description: merchantServiceCreateServiceShortDescription,
            information_content: merchantServiceCreateServiceLongDescription,
            price: merchantServiceCreateServicePrice,
            min_participants: merchantServiceCreateServiceMinParticipants,
            max_participants: merchantServicesCreateServiceMaxParticipants,
            quota: merchantServiceCreateServiceQuota,
            extra_note: "",
            same_address: 1,
            phone: "0000000000",
            website: "www.google.com",
            launch_date: formatYmd(tomorrow),
            image_url: serviceImageUrl,
          }),
        }
      );
      if (response.status === 201) {
        const getCreateServiceResponseAuth = response.headers.get(
          "Authorization"
        );
        // console.log(getCreateServiceResponseAuth.split(' ')[1]);
        merchantServiceCreateServiceApiToken = getCreateServiceResponseAuth.split(
          " "
        )[1];
        setSignedInUserAccessToken(merchantServiceCreateServiceApiToken);
        setModalTitle("Service Created");
        setModalCopy("Your service has been created!");
        history.push("/merchant/services");
        setModalDisplay(true);
      } else {
        console.log(response);
        console.log(
          JSON.stringify({
            type: "",
            name: merhcantServiceCreateServiceName,
            description: merchantServiceCreateServiceShortDescription,
            information_content: merchantServiceCreateServiceLongDescription,
            price: merchantServiceCreateServicePrice,
            min_participants: merchantServiceCreateServiceMinParticipants,
            max_participants: merchantServicesCreateServiceMaxParticipants,
            quota: merchantServiceCreateServiceQuota,
            extra_note: "",
            same_address: 1,
            phone: "",
            website: "",
            launch_date: "",
            image_url: serviceImageUrl,
          })
        );
        setModalTitle("Service Error");
        setModalCopy(
          "Your service was not created. Please double check your information and try again"
        );
        setModalDisplay(true);
      }
    }

    uploadCreateServicePhoto();
  };

  //////////////////////////////////////////////////
  ///// Merchant Services - Get Services State/Handlers
  //////////////////////////////////////////////////

  const [
    merchantServiceListServicesZeroLengthFlag,
    setMerchantServiceListServicesZeroLengthFlag,
  ] = useState(true);
  const [
    merchantServiceListServicesFilteredList,
    setMerchantServiceListServicesFilteredList,
  ] = useState([]);

  const merchantServiceListServices = (props) => {
    let venueIdList = [];
    let serviceIdList = [];

    async function getMerchantVenuesForServices() {
      let getMerchantVenuesForServicesToken = signedInUserAccessToken;
      const response = await fetch(
        "https://api.splitsees.com/api/lookups/venues?page=1&rpp=100",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getMerchantVenuesForServicesToken,
          },
        }
      );
      const data = await response.json();
      const data0 = await data.data;
      const venueIdList = await data0.filter((venue) => {
        return venue.merchant_id === signedInUserMerchantId;
      });
      if ((await venueIdList.length) === 0) {
        console.log("No Services cuz there no venues");
      } else {
        console.log(venueIdList);
        listMerchantServices(venueIdList);
      }
    }
    async function listMerchantServices(venueList) {
      let listServicesToken = signedInUserAccessToken;
      // console.log(venueList);
      for await (const venueId of venueList) {
        // console.log(listServicesToken);
        // console.log(venueId.id);
        const response = await fetch(
          `https://api.splitsees.com/api/venues/${venueId.id}/services?page=1&rpp=30`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + listServicesToken,
            },
          }
        );

        const serviceData = await response.json();
        const serviceData0 = await serviceData.data;

        const getServicesResponseAuth = response.headers.get("Authorization");
        // console.log(getServicesResponseAuth.split(' ')[1]);
        console.log(getServicesResponseAuth);
        listServicesToken = getServicesResponseAuth.split(" ")[1];
        console.log(listServicesToken);
        setSignedInUserAccessToken(listServicesToken);

        if ((await serviceData0.length) === 0) {
          console.log("no services for this venue");
        } else {
          console.log(serviceData0);
          for (const foundService of serviceData0) {
            serviceIdList.push(foundService);
          }

          // console.log(response);
        }
      }
      setMerchantServiceListServicesFilteredList(serviceIdList);
    }

    getMerchantVenuesForServices();
  };

  //////////////////////////////////////////////////
  ///// Public Venue
  //////////////////////////////////////////////////

  const [publicVenueInfo, setPublicVenueInfo] = useState({});
  const [publicVenueId, setPublicVenueId] = useState("");

  const [
    publicVenueInfoBackgroundImage,
    setPublicVenueInfoBackgroundImage,
  ] = useState("");
  const [publicVenueInfoLogo, setPublicVenueInfoLogo] = useState("");
  const [publicVenueInfoName, setPublicVenueInfoName] = useState("");
  const [
    publicVenueInfoAddressStreet0,
    setPublicVenueInfoAddressStreet0,
  ] = useState("");
  const [
    publicVenueInfoAddressStreet1,
    setPublicVenueInfoAddressStreet1,
  ] = useState("");
  const [publicVenueInfoAddressCity, setPublicVenueInfoAddressCity] = useState(
    ""
  );
  const [
    publicVenueInfoAddressState,
    setPublicVenueInfoAddressState,
  ] = useState("");
  const [
    publicVenueInfoAddressZipCode,
    setPublicVenueInfoAddressZipCode,
  ] = useState("");
  const [
    publicVenueInfoAddressPhoneNumber,
    setPublicVenueInfoAddressPhoneNumber,
  ] = useState("");

  // const [publicVenueSignInMessage, setPublicVenueSignInMessage] = useState("");
  // const [publicVenueSignInError, setPublicVenueSignInError] = useState("");
  const [publicVenueInfoWebsite, setPublicVenueWebsite] = useState("");
  // const [publicVenueEmail, setPublicVenueEmail] = useState("");
  // const [publicVenuePassword, setPublicVenuePassword] = useState("");
  let publicVenueEmail;
  let publicVenuePassword;
  const publicVenueEmailOnChange = (event) => {
    publicVenueEmail = event.target.value;
  };

  const publicVenuePasswordOnChange = (event) => {
    publicVenuePassword = event.target.value;
  };

  const publicVenueForgotPasswordLinkOnClickHandler = () => {
    setModalTitle();
    setModalCopy();
    setModalDisplay(false);
    history.push("/forgot-password");
  }

  const publicVenueSignInButtonOnClickHandler = (props) => {
    setModalTitle("Sign In");
    setModalCopy(
      <>
        <div className="publicVenue__modal__inputWrapper">
          <label htmlFor="username">User Name / Email</label>
          <input
            id="username"
            type="text"
            onChange={publicVenueEmailOnChange}
          ></input>
        </div>
        <div className="publicVenue__modal__inputWrapper">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            onChange={publicVenuePasswordOnChange}
          ></input>
          <span className="publicVenue__modal__inputWrapper__forgotPassword" onClick={publicVenueForgotPasswordLinkOnClickHandler}>Forgot Passowrd?</span>
          <Button
            className="button-active"
            onClick={publivVenueModalSignInButtonOnClickHandler}
          >
            Sign In
          </Button>
        </div>
      </>
    );
    setModalDisplay(true);
  };

  const publicVenueRegisterButtonOnClickHandler = (props) => {
    setRegisterRole("planner");
    setRegisterOrgin(location.pathname);
    console.log(location.pathname);
    history.push("/");
  };

  const [publicVenueServiceList, setPublicVenueServiceList] = useState([]);

  const publicGetVenueList = (venueId) => {
    setPublicVenueId(venueId);

    async function listMerchantServicesPublic() {
      // console.log(accessToken);
      // console.log(publicVenueId);
      const response = await fetch(
        `https://api.splitsees.com/api/lookups/venues/${venueId}/services?page=1&rpp=30`,

        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const serviceData = await response.json();
      const serviceData0 = await serviceData.data;
      if ((await serviceData0.length) === 0) {
        console.log("no services for this venue");
      } else {
        console.log(serviceData0);
        setPublicVenueServiceList(serviceData0);
      }
    }

    async function publicGetVenuesToFilter(venueId) {
      const response = await fetch(
        "https://api.splitsees.com/api/lookups/venues?page=1&rpp=100",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const data = await response.json();
      const data0 = await data.data;
      const venueIdList = await data0.filter((venue) => {
        return venue.id === parseInt(venueId);
      });
      if (venueIdList.length < 1) {
      } else {
        setPublicVenueInfoBackgroundImage(venueIdList[0].background_image);
        setPublicVenueInfoLogo(venueIdList[0].logo);
        setPublicVenueInfoName(venueIdList[0].name);
        setPublicVenueInfoAddressStreet0(venueIdList[0].address_1);
        setPublicVenueInfoAddressStreet1(venueIdList[0].address_2);
        setPublicVenueInfoAddressCity(venueIdList[0].city);
        setPublicVenueInfoAddressState(venueIdList[0].state_abbreviation);
        setPublicVenueInfoAddressZipCode(venueIdList[0].zipcode);
        setPublicVenueInfoAddressPhoneNumber(venueIdList[0].phone);
        setPublicVenueWebsite(venueIdList[0].website);
        listMerchantServicesPublic();
      }
    }
    publicGetVenuesToFilter(venueId);
  };

  const publivVenueModalSignInButtonOnClickHandler = () => {
    // async function listMerchantServicesPublic(accessToken) {
    //   // console.log(accessToken);
    //   // console.log(publicVenueId);
    //   const response = await fetch(
    //     `https://chillhound.sandbox.polymorphic.io/api/venues/${publicVenueId}/services?page=1&rpp=30`,
    //     {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //         Authorization: "Bearer " + accessToken,
    //       },
    //     }
    //   );
    //   const serviceData = await response.json();
    //   const serviceData0 = await serviceData.data;
    //   if ((await serviceData0.length) === 0) {
    //     console.log("no services for this venue");
    //   } else {
    //     console.log(serviceData0);
    //   }
    // }

    async function publicVenueSignInModal() {
      const response = await fetch(
        "https://api.splitsees.com/api/auth/login",
        {
          method: "POST",
          body: JSON.stringify({
            email: publicVenueEmail,
            password: publicVenuePassword,
            type: "planner",
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response.status === 200) {
        setModalError("");
        setModalMessage("");
        const data = await response.json();
        setSignedInUserAccessToken(data.data.access_token);
        setSignedInUserAccessTokenExpiration(data.data.expires_in);
        data.data.user.first_name !== null
          ? setSignedInUserFirstName(data.data.user.first_name)
          : setSignedInUserFirstName("");
        data.data.user.last_name !== null
          ? setSignedInUserLastName(data.data.user.last_name)
          : setSignedInUserLastName("");
        setSignedInUserEmail(signInEmail);
        data.data.user.profile_image !== null
          ? setSignedInUserProfileImage(data.data.user.profile_image)
          : setSignedInUserProfileImage(null);
        data.data.user.city !== null
          ? setSignedInUserCity(data.data.user.city)
          : setSignedInUserCity(null);
        data.data.user.zipcode !== null
          ? setSignedInUserZipCode(data.data.user.zipcode)
          : setSignedInUserZipCode(null);
        setSignedInUserRole(signInRole);
        setIsLoggedIn(true);
        setModalDisplay(false);
        // listMerchantServicesPublic(data.data.access_token);
      } else {
        const data = await response.json();
        setModalError("");
        setModalMessage("");
        setModalMessage(data.message);
        if (Object.keys(data).length > 1) {
          setModalError(data.errors.email[0]);
        }

        // console.log(data.errors.email);
        // console.log(data.errors);
        // console.log(data[0]);
        // console.log(data.message);
        // console.log(Object.keys(data).length);
        // for (let item in data) {
        //   console.log(item);
        // }
      }
    }
    publicVenueSignInModal();
  };

  // Booking section

  const [publicVenueBookService, setPublicVenueBookService] = useState([]);
  const [publicVenueInviteList, setPublicVenueInviteList] = useState([
    { id: 0, firstName: "", lastName: "", email: "" },
  ]);
  const [publicVenueBookingMonth, setPublicVenueBookingMonth] = useState();
  const [publicVenueBookingDay, setPublicVenueBookingDay] = useState();
  const [publicVenueBookingYear, setPublicVenueBookingYear] = useState();
  const [
    publicVenueBookingStartTimeHour,
    setPublicVenueBookingStartTimeHour,
  ] = useState();
  const [
    publicVenueBookingStartTimeMinute,
    setPublicVenueBookingStartTimeMinute,
  ] = useState();
  const [
    publicVenueBookingStartTimeAmPm,
    setPublicVenueBookingStartTimeAmPm,
  ] = useState();
  const [
    publicVenueBookingEndTimeHour,
    setPublicVenueBookingEndTimeHour,
  ] = useState();
  const [
    publicVenueBookingEndTimeMinute,
    setPublicVenueBookingEndTimeMinute,
  ] = useState();
  const [
    publicVenueBookingEndTimeAmPm,
    setPublicVenueBookingEndTimeAmPm,
  ] = useState();

  let selectedGoogleContacts = [];

  const publicVenueBookServiceButtonOnClickHandler = (event) => {
    console.log(event.target.value);
    console.log(publicVenueServiceList);

    let chosenService = publicVenueServiceList.filter((service) => {
      return service.id === parseInt(event.target.value);
    });
    setPublicVenueBookService(chosenService);
  };

  const publicVenueAddGuestButtonOnClickHandler = (event) => {
    const lastGuestId =
      publicVenueInviteList[publicVenueInviteList.length - 1].id;
    console.log(publicVenueInviteList);
    console.log(lastGuestId);
    setPublicVenueInviteList([
      ...publicVenueInviteList,
      { id: lastGuestId + 1, firstName: "", lastName: "", email: "" },
    ]);
  };

  // const publicVenueGoogleContactsOnClickHandler = props => {
    
  // }

  const publicVenueRemoveGuestButtonOnClickHandler = (event) => {
    // console.log(publicVenueInviteList);

    setPublicVenueInviteList(
      publicVenueInviteList.filter(
        (guest) => guest.id !== parseInt(event.target.value)
      )
    );
    console.log(publicVenueInviteList);
    // setPublicVenueInviteListLength(
    //   publicVenueInviteListLength.filter((_,index) => index !== event.target.value)
    // );
    // console.log(event.target.value);
  };

  const publicVenueGuestFirstNameFieldOnChangeHandler = (event) => {
    // console.log(publicVenueInviteList);
    // console.log(event);
    // console.log(event.target.className);
    const currentGuestFirstName = event.target.value;
    const currentGuestId = event.target.className.split("-")[1];
    const previousList = publicVenueInviteList;
    setPublicVenueInviteList(
      previousList.map((guest) =>
        guest.id === parseInt(currentGuestId)
          ? Object.assign(guest, { firstName: currentGuestFirstName })
          : guest
      )
    );
    console.log(publicVenueInviteList);
  };

  const publicVenueGuestLastNameFieldOnChangeHandler = (event) => {
    // console.log(publicVenueInviteList);
    // console.log(event);
    // console.log(event.target.className);
    const currentGuestLastName = event.target.value;
    const currentGuestId = event.target.className.split("-")[1];
    const previousList = publicVenueInviteList;
    setPublicVenueInviteList(
      previousList.map((guest) =>
        guest.id === parseInt(currentGuestId)
          ? Object.assign(guest, { lastName: currentGuestLastName })
          : guest
      )
    );
    console.log(publicVenueInviteList);
  };

  const publicVenueGuestEmailFieldOnChangeHandler = (event) => {
    // console.log(publicVenueInviteList);
    // console.log(event);
    // console.log(event.target.className);
    const currentGuestEmail = event.target.value;
    const currentGuestId = event.target.className.split("-")[1];
    const previousList = publicVenueInviteList;
    setPublicVenueInviteList(
      previousList.map((guest) =>
        guest.id === parseInt(currentGuestId)
          ? Object.assign(guest, { email: currentGuestEmail })
          : guest
      )
    );
    console.log(publicVenueInviteList);
  };

  const publicVenueMonthFieldOnChangeHandler = (event) => {
    setPublicVenueBookingMonth(event.target.value);
    console.log(event.target.value);
  };

  const publicVenueDayFieldOnChangeHandler = (event) => {
    setPublicVenueBookingDay(event.target.value);
    console.log(event.target.value);
  };

  const publicVenueYearFieldOnChangeHandler = (event) => {
    setPublicVenueBookingYear(event.target.value);
    console.log(event.target.value);
  };

  const publicVenueStartTimeHourFieldOnChangeHandler = (event) => {
    setPublicVenueBookingStartTimeHour(event.target.value);
    console.log(event.target.value);
  };

  const publicVenueStartTimeMinuteFieldOnChangeHandler = (event) => {
    setPublicVenueBookingStartTimeMinute(event.target.value);
    console.log(event.target.value);
  };

  const publicVenueStartTimeAmPmFieldOnChangeHandler = (event) => {
    setPublicVenueBookingStartTimeAmPm(event.target.value);
    console.log(event.target.value);
  };

  const publicVenueEndTimeHourFieldOnChangeHandler = (event) => {
    setPublicVenueBookingEndTimeHour(event.target.value);
    console.log(event.target.value);
  };

  const publicVenueEndTimeMinuteFieldOnChangeHandler = (event) => {
    setPublicVenueBookingEndTimeMinute(event.target.value);
    console.log(event.target.value);
  };

  const publicVenueEndTimeAmPmFieldOnChangeHandler = (event) => {
    setPublicVenueBookingEndTimeAmPm(event.target.value);
    console.log(event.target.value);
  };



  // @PIN - Here is the call when you click the 'Create Event' button

  // This crap is insane. steps:
  // - lookup each contact that the user inputted in the account contacts
  // - if found, move to the next one
  // - if not found, create new contact, then rerun search
  // - if all found, push contact ID's to array
  // - create event and then use array to invite contacts to newly created event
  const publicVenueCreateEventButtonOnClickHandler = (props) => {
    let contactsToAdd = [];
    let contactsToInvite = [];
    
    let contactsListToSearch;
    let getContactsToFilterToken = signedInUserAccessToken;
    let createdEventId;

    const contactProcessing = (props) => {
      console.log('BEGIN - Contact Processing');
      let contactStatus = false;

      const finishContactProcessing = props => {
        console.log('BEGIN - finishContactProcessing');
        if (contactStatus === true) {
          // for (let foundContact of publicVenueInviteList) {
          //   console.log(foundContact);
          //   console.log(foundContact.id);
          //   contactsToInvite.push(foundContact.id);
          //   console.log(contactsToInvite);
          // }
          console.log('Create The Event!');
          createEvent();
        } else {
          console.log('Not Ready To Create Event');
        }
      }

      for (let contact of publicVenueInviteList) {
        let foundContacts = contactsListToSearch.find(
          (_contact) => _contact.email === contact.email
        );
        console.log(contact);
        if (foundContacts !== undefined) {
          console.log('CONTACT FOUND - adding contact to contactsToAdd');
          //  console.log(foundContacts);
          contactsToAdd.push(foundContacts.id);
          //  console.log(contactsToAdd);
          contactStatus = true;
        } else {
          console.log('CONTACT NOT FOUND - creating contact');
          contactStatus = false;
          contactsToAdd.length = 0;
          createContact(contact.firstName, contact.email);
          break;
        }
        // console.log(contact);
      }
      finishContactProcessing();

      
    };

    async function getContactsToFilter() {
      // getContactsToFilterToken = signedInUserAccessToken;
      console.log('BEGIN - getContactsToFilter');
      const getContactsResponse = await fetch(
        "https://api.splitsees.com/api/contacts",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getContactsToFilterToken,
          },
        }
      );

      if(getContactsResponse.status === 200) {
        const data = await getContactsResponse.json();
        const getContactsResponseAuth = getContactsResponse.headers.get(
          "Authorization"
        );
        getContactsToFilterToken = getContactsResponseAuth.split(" ")[1];
        // console.log(getContactsToFilterToken);
        setSignedInUserAccessToken(getContactsToFilterToken);
        // console.log(data.data);
        contactsListToSearch = data.data;
        console.log('END - contactsToFilter');
        contactProcessing();
      } else {
        setModalTitle('Error');
        setModalCopy('There was an error retriving your contacts');
      }

    }

    async function createContact(

      contact_first_name,
      contact_email
    ) {
      console.log('BEGIN - createContact');
      // console.log(contact_first_name);
      // console.log(contact_last_name);
      // console.log(contact_email);

      // console.log(JSON.stringify({
      //   first_name: contact_first_name,
      //   last_name: contact_last_name,
      //   email: contact_email
      // }));

      // let firstName = contact_first_name;
      // let lastName = contact_last_name;
      // let email = contact_email;
      const createContactResponse = await fetch(
        "https://api.splitsees.com/api/contacts",
        {
          method: "POST",
          body: JSON.stringify({
            first_name: contact_first_name,
            last_name: "lastName",
            email: contact_email,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getContactsToFilterToken,
          },
        }
      );
      if (createContactResponse.status === 201) {
        
        // console.log(data);
        // console.log(data.data);
        const createContactsResponseAuth = createContactResponse.headers.get(
          "Authorization"
        );
        getContactsToFilterToken = createContactsResponseAuth.split(" ")[1];
        setSignedInUserAccessToken(getContactsToFilterToken);
        // console.log(data.data);
        console.log('END - createContact');
        getContactsToFilter();
      } else {
        const data = await createContactResponse.json();
        console.log(data.data);
        // console.log(createContactResponse.status);
        // console.log(createContactResponse.body);
        setModalTitle('Create Contact Error');
        setModalCopy('Something went wrong while creating a contact. Try again');
        setModalDisplay(true);
        // @TODO - Call alert modal with error message about Contacts not being created
      }
    }
    getContactsToFilter();

    async function createEvent() {
      console.log('BEGIN - createEvent');
      // Change time to 24:00 based on the AM/PM selection
      let startTimeHour;
      let endTimeHour;
      if (publicVenueBookingStartTimeAmPm === "pm") {
        startTimeHour = parseInt(publicVenueBookingStartTimeHour) + 12;
      } else {
        startTimeHour = publicVenueBookingStartTimeHour;
      }
      if (publicVenueBookingEndTimeAmPm === "pm") {
        endTimeHour = parseInt(publicVenueBookingEndTimeHour) + 12;
      } else {
        endTimeHour = publicVenueBookingEndTimeHour;
      }

      let guestCount = contactsToAdd.length;
      console.log("contact list length " + contactsToAdd.length);
      console.log(contactsToAdd);
      // console.log('guest count' + guestCount);
      // console.log(publicVenueBookService);
      const createEventResponse = await fetch(
        "https://api.splitsees.com/api/events",
        {
          method: "POST",
          body: JSON.stringify({
            service_id: publicVenueBookService[0].id,
            date:
              publicVenueBookingYear +
              "-" +
              publicVenueBookingMonth +
              "-" +
              publicVenueBookingDay,
            start_time: startTimeHour + ":" + publicVenueBookingStartTimeMinute,
            end_time: "11:59",
            event_type_id: 3,
            guests_count: contactsToAdd.length,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getContactsToFilterToken,
          },
        }
      );
      if (createEventResponse.status === 201) {
        const data = await createEventResponse.json();
        // console.log(data);
        // console.log(data.data);
        createdEventId = data.data.id;
        console.log(createdEventId);
        // console.log(createEventResponse.status);
        // console.log(createEventResponse.body);
        const createEventResponseAuth = createEventResponse.headers.get(
          "Authorization"
        );
        getContactsToFilterToken = createEventResponseAuth.split(" ")[1];
        setSignedInUserAccessToken(getContactsToFilterToken);
        console.log('END - createEvent');
        inviteContacts();
      } else {
        const data = await createEventResponse.json();
        console.log(data);
        console.log(createEventResponse.status);
        console.log(createEventResponse.body);
        //@TODO Call alert modal to display error message about Event NOT being created
      }
    }

    async function inviteContacts() {
      console.log('BEGIN - inviteContacts');
      // console.log(JSON.stringify({
      //   contact_ids: contactsToAdd,
      // }));

      // console.log(contactsToAdd);
      const inviteContactsResponse = await fetch(
        `https://api.splitsees.com/api/events/${createdEventId}/invite`,
        {
          method: "POST",
          body: JSON.stringify({
            contact_ids: contactsToAdd,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getContactsToFilterToken,
          },
        }
      );
      if (inviteContactsResponse.status === 200) {
        const data = await inviteContactsResponse.json();
        setModalTitle("Event Created!");
        setModalCopy(
          "Your Event has been created, your contacts invited, and has been sent to the Merchant for approval. Once approved, invites will be sent out!"
        );
        setModalDisplay(true);
        setPublicVenueBookService([]);
        console.log('END - inviteContacts');
        const inviteContactsResponseAuth = inviteContactsResponse.headers.get(
          "Authorization"
        );
        getContactsToFilterToken = inviteContactsResponseAuth.split(" ")[1];
        setSignedInUserAccessToken(getContactsToFilterToken);
        publicVenueInviteList.length = 0;
        // console.log(data);
      } else {
        console.log(inviteContactsResponse.status);
        console.log(inviteContactsResponse.body);
        //@TODO Call alert modal with a message about Contacts NOT being invited
        setModalTitle("Event Failed");
        setModalCopy(
          "The event was not created, something went wrong. Try again."
        );
        setModalDisplay(true);
        setPublicVenueBookService([]);
      }
    }
  };



  //////////////////////////////////////////////////
  /////  Google API STUFF
  //////////////////////////////////////////////////

  const [googleSignedIn, setGoogleSignedIn] = useState();
  // const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  // const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const googleDiscoveryDocs = process.env.REACT_APP_GOOGLE_DISCOVERY_DOCS;
  const googleScope = process.env.REACT_APP_GOOGLE_SCOPES;
  let connections = [];
  let connectionsList = [];
  // let filteredList = [];
  let filteredConnectionsList = [];
  let searchConnectionsList = [];

  const googleUpdateSignedInStatus = (isSignedIn) => {
    console.log("step 3");

    if (isSignedIn) {
      setGoogleSignedIn(true);
      console.log("logged in");
    } else {
      setGoogleSignedIn(false);
      console.log("not logged in");
    }
  };
  const initClient = (props) => {
    console.log("step 2");
    window.gapi.client
      .init({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        discoveryDocs: [
          "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
        ],
        scope: process.env.REACT_APP_GOOGLE_SCOPES,
      })
      .then(
        function() {
          // Listen for sign-in state changes.
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(googleUpdateSignedInStatus);
          googleUpdateSignedInStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn.get()
          );
        },
        function(error) {
          // appendPre(JSON.stringify(error, null, 2));
          console.log("we def not cool");
          console.log(JSON.stringify(error, null, 2));
        }
      );
  };

  const googleAuth2ApiLoader = (props) => {
    window.gapi.load("client:auth2", initClient);
    // console.log("step 1");
  };

  const googleSignInButtonOnClickHandler = (props) => {
    window.gapi.auth2.getAuthInstance().signIn();
  };

  const googleSignPutButtonOnClickHandler = (props) => {
    window.gapi.auth2.getAuthInstance().signOut();
  };



  const googleListContactsButtonOnClickHandler = (props) => {
    // console.log("step 4");
    window.gapi.client.people.people.connections
      .list({
        resourceName: "people/me",
        pageSize: 2000,
        personFields: "names,emailAddresses",
      })
      .then(function(response) {
        connections = response.result.connections;

        connectionsList = Object.entries(connections);
        console.log(connectionsList);
        connectionsList.forEach(connection => {
          if(connection[1].names !== undefined && connection[1].emailAddresses !== undefined) {
            filteredConnectionsList.push(connection);
          }
        });
        searchConnectionsList = filteredConnectionsList;
        // filteredConnectionsList = connectionsList.f;
        // console.log(response);
        console.log(filteredConnectionsList);
        // console.log()

        // console.log(connections[0].emailAddresses[0].value);
        
        googleContactsModal();
      });
  };



  const googleContactsSearchFieldOnChange = event => {
    // console.log(event.target.value);
    const searchList = filteredConnectionsList.filter(
      connection => ( connection[1].names[0].givenName.toLowerCase().includes(event.target.value.toLowerCase()) || 
      // connection[1].names[0].familyName.toLowerCase().includes(event.target.value.toLowerCase()) || 
      connection[1].emailAddresses[0].value.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
    // console.log(searchList);
    searchConnectionsList = searchList;
    googleContactsModal();
  }

  const selectGoogleContact = (name,email) => {
    selectedGoogleContacts.push({name, email});
    console.log(selectedGoogleContacts);
  }

  const chooseSelectedContacts = props => {
    selectedGoogleContacts.forEach(
      (contact) => {
        const lastGuestId = publicVenueInviteList[publicVenueInviteList.length - 1].id;
        if((publicVenueInviteList.length) === 1 && (publicVenueInviteList[0].firstName === "")) {
          publicVenueInviteList.length = 0;
          publicVenueInviteList.push({ id: lastGuestId, firstName: contact.name, lastName: "", email: contact.email});
        } else {
          publicVenueInviteList.push({ id: lastGuestId + 1, firstName: contact.name, lastName: "", email: contact.email});
        }
        
      }
    )

    // const lastGuestId =
    //   publicVenueInviteList[publicVenueInviteList.length - 1].id;
    // // console.log(publicVenueInviteList);
    // // console.log(lastGuestId);
    // setPublicVenueInviteList([
    //   ...publicVenueInviteList,
    //   { id: lastGuestId + 1, firstName: "", lastName: "", email: "" },
    // ]);
    setModalDisplay(false);
  }

  const googleContactsModal = props => {
    setModalTitle("Select Your Google Contacts");
        setModalCopy(
          <>
            <div className="">
              <div className="">
                <input type="text" placeholder="Search By Name" onChange={googleContactsSearchFieldOnChange}></input>
              </div>
              <ul>
                {searchConnectionsList.map((connection) => (
                 
                    <li key={connection[0]}>
                  <input
                    type="checkbox"
                    name={connection[1].names[0].givenName}
                    value={connection[1].emailAddresses[0].value }
                    onChange={()=> selectGoogleContact(connection[1].names[0].givenName, connection[1].emailAddresses[0].value )}
                    //@TODO OnChange - add name and email address to array to 
                    // then be added to contacts to invite that will be processed
                    // and created if necessary
                  />
                  {connection[1].names[0].givenName}{" "}
                  {connection[1].names[0].familyName}{" -- "}
                  {connection[1].emailAddresses[0].value}
                </li> 
                 
             
                
                  

                 
                ))}
              </ul>
            </div>
            <Button className="button-active" onClick={chooseSelectedContacts}>Select Contacts</Button>
          </>
        );
        setModalDisplay(true);
  }

  //////////////////////////////////////////////////
  ///// Invitation Details
  //////////////////////////////////////////////////

  const [invitationFirstName, setInvitationFirstName] = useState();
  const [invitationServiceName, setInvitationServiceName] = useState();
  const [invitationEventDate, setInvitationEventDate] = useState();
  const [invitationEventStartTime, setInvitationStartTime] = useState();
  const [invitationEventEndTime, setInvitationEndTime] = useState();
  const [invitationVenueName, setInvitationVenueName] = useState();
  const [invitationVenueAddress1, setInvitationVenueAddress1] = useState();
  const [invitationVenueAddress2, setInvitationVenueAddress2] = useState();
  const [invitationVenueCity, setInvitationVenueCity] = useState();
  const [invitationVenueState, setInvitationVenueState] = useState();
  const [invitationVenueZipCode, setInvitationVenueZipCode] = useState();
  const [invitationServiceImage, setInvitationServiceImage] = useState();
  const [invtationServicePrice, setInvitationServicePrice] = useState();
  const [invtationPaymentIntentId, setInvtationPaymentIntentId] = useState();
  const [
    invtationPaymentIntentIdSecret,
    setInvtationPaymentIntentIdSecret,
  ] = useState();
  const [invitationHash, setInvitationHash] = useState();

  const getInvitationDeatilsHandler = (hashId) => {
    setInvitationHash(hashId);
    async function getInvitationDetails() {
      const getInvitationDetailsApi = await fetch(
        `https://api.splitsees.com/api/invitations/${hashId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const data = await getInvitationDetailsApi.json();
      const invitationContact = data.data.contact;
      const invitationService = data.data.event.service;
      const invitationEvent = data.data.event;
      const invitationVenue = data.data.event.service.venue;
      setInvitationFirstName(invitationContact.first_name);
      setInvitationServiceName(invitationService.name);
      setInvitationServiceImage(invitationService.image_url);
      setInvitationEventDate(invitationEvent.date);
      setInvitationStartTime(invitationEvent.start_time);
      setInvitationEndTime(invitationEvent.end_time);
      setInvitationVenueName(invitationVenue.name);
      setInvitationVenueAddress1(invitationVenue.address_1);
      setInvitationVenueAddress2(invitationVenue.address_2);
      setInvitationVenueCity(invitationVenue.city);
      setInvitationVenueState(invitationVenue.state_abbreviation);
      setInvitationVenueZipCode(invitationVenue.zipcode);
      setInvitationServicePrice(invitationService.price);
      let stripePromise = loadStripe(
        "pk_live_51JAqsWEpU1TDpqCWYYwZItlc6Y2ZAvggemTzM80BN5DQohyst9VZKwIEHtt9dZ6cP1wZVZ3XLe5FWrXWJJkmSWGn00pi1cg2HN"
      );
      setInvtationPaymentIntentId(stripePromise);
      // console.log(stripePromise);
      setInvtationPaymentIntentIdSecret(data.data.payment_intent_id_secret);
      // console.log(data.data);
    }

    getInvitationDetails();
  };

  const InvitationDetailsRsvpButtonClickHandler = () => {
    let currentLocation = location;
    history.push(currentLocation.pathname + "rsvp/");
  };

  //////////////////////////////////////////////////
  ///// Invitation RSVP
  //////////////////////////////////////////////////

  const [invitationRsvpChoice, setInvitationRsvpChoice] = useState();
  const [invitationPaid, setInvitationPaid] = useState(false);
  const [inviteStatus, setInviteStatus] = useState();

  const rsvpChoiceOnClickHandler = (props) => {
    console.log(props);
    setInvitationRsvpChoice(props);
    props === "going" ? setInviteStatus('accepted') : setInviteStatus('declined')
  };

  const rsvpChoiceNextOnClickHandler = (props) => {
    let currentLocation = location;
    console.log(invitationRsvpChoice);
    console.log(invtationPaymentIntentId);
    
      async function updateInviteStatus() {
        const getUpdateInviteStatusStatus = await fetch(
          `https://api.splitsees.com/api/invitations/${invitationHash}`,
          { 
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              status: inviteStatus,
              reason:''
            }),
          }
        );  
      }
      updateInviteStatus();
      invitationRsvpChoice === "going"
      ? history.push(currentLocation.pathname + "going")
      : history.push(currentLocation.pathname + "not-going");

  };

  const rsvpChoiceBackOnClickHandler = (props) => {
    let currentLocation = location;
    history.push(currentLocation.pathname.split("rsvp")[0]);
  };

  const rsvpPaymentSuccess = (props) => {
    setModalDisplay(true);
    setModalTitle("Payment Success");
    setModalCopy(
      "You have paid successfully and will be receiving an email shortly!"
    );
    setInvitationPaid(true);
  };

  const rsvpPaymentError = (props) => {
    setModalDisplay(true);
    setModalTitle("Payment Error");
    setModalCopy(props);
  };

  //////////////////////////////////////////////////
  ///// Merchant Get/Approve Events
  //////////////////////////////////////////////////

  const [merchantBookings, setMerchantBookings] = useState([]);
  const [
    merchantBookingStatusEditFlag,
    setMerchantBookingStatusEditFlag,
  ] = useState(false);
  const [
    merchantBookingStatusEditId,
    setMerchantBookingStatusEditId,
  ] = useState();

  const merchantEventLister = () => {
    let getMerchantEventsToken = signedInUserAccessToken;
    console.log("begin list: " + getMerchantEventsToken);

    async function getMerchantEvents() {
      const getMerchantEventsApi = await fetch(
        "https://api.splitsees.com/api/events?page=1&rpp=100",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + getMerchantEventsToken,
          },
        }
      );
      const data = await getMerchantEventsApi.json();
      if (getMerchantEventsApi.status === 200) {
        console.log(data);
        setMerchantBookings(data.data);
        const getMerchantEventsAuth = getMerchantEventsApi.headers.get(
          "Authorization"
        );
        getMerchantEventsToken = getMerchantEventsAuth.split(" ")[1];
        setSignedInUserAccessToken(getMerchantEventsToken);
        console.log("end list: " + getMerchantEventsToken);
      } else {
        console.log("bad");
        console.log(data);
        console.log(data.status);
        console.log(getMerchantEventsApi.status);
      }
    }
    getMerchantEvents();
  };

  const merchantBookingStatusEditOnClickHandler = (props) => {
    setMerchantBookingStatusEditId(props);
    let editFlag = merchantBookingStatusEditFlag;
    setMerchantBookingStatusEditFlag(!editFlag);
    console.log(props);
    console.log(editFlag);
  };

  const merchantBookingStatusEditChoiceOnClickHandler = (props) => {
    let changeEventStatusToken = signedInUserAccessToken;
    console.log("begin status: " + changeEventStatusToken);
    let editFlag = merchantBookingStatusEditFlag;
    setMerchantBookingStatusEditFlag(!editFlag);

    console.log(props);

    async function changeEventStatus() {
      const changeEventStatusApi = await fetch(
        `https://api.splitsees.com/api/events/${merchantBookingStatusEditId}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + changeEventStatusToken,
          },
          body: JSON.stringify({
            status: props,
          }),
        }
      );
      const data = await changeEventStatusApi.json();
      if (changeEventStatusApi.status === 200) {
        setMerchantBookingStatusEditId(undefined);
        const changeEventStatusAuth = changeEventStatusApi.headers.get(
          "Authorization"
        );
        changeEventStatusToken = changeEventStatusAuth.split(" ")[1];
        setSignedInUserAccessToken(changeEventStatusToken, () => {
          merchantEventLister();
        });
        console.log(data);
        console.log("end status: " + changeEventStatusToken);
      } else {
        console.log("boo");
        console.log(data);
        const changeEventStatusAuth = changeEventStatusApi.headers.get(
          "Authorization"
        );
        changeEventStatusToken = changeEventStatusAuth.split(" ")[1];
        setSignedInUserAccessToken(changeEventStatusToken, () => {
          merchantEventLister();
        });
        console.log(data);
        console.log("end status error: " + changeEventStatusToken);
      }
    }
    changeEventStatus();
  };

  //////////////////////////////////////////////////
  ///// Exit / Sign Out
  //////////////////////////////////////////////////

  const sidebarExitIconOnClickHandler = (props) => {
    setIsLoggedIn(false);
    setSignedInUserAccessToken("");
    // setSignedInBankInfoAccountHolderName("");
    setSignedInUserAccessTokenExpiration("");
    setSignInEmail("");
    setSignInMerchantOnboardingLink("");
    setSignInPassword("");
    setSignInRole("");
    setSignedInUserEmail("");
    setSignedInUserFirstName("");
    setSignedInUserLastName("");
    setSignedInUserRole("");
    setSignedInUserMerchantId("");
    setSignedInUserZipCode("");
    setSignedInUserProfileImage("");
    setSignedInUserCity("");
    // setSignedInUserNotifications("");
    history.push("/");
  };



  return (
    <AuthContext.Provider
      value={{
        modalTitle: modalTitle,
        modalCopy: modalCopy,
        modalDisplay: modalDisplay,
        isLoggedIn: isLoggedIn,
        registerRole: registerRole,
        registerEmail: userEmail,
        signInRole: signInRole,
        signInEmail: signInEmail,
        signInPassword: signInPassword,
        signedInUserCity: signedInUserCity,
        signedInUserZipCode: signedInUserZipCode,
        forgotPasswordEmail: forgotPasswordEmail,
        forgotPasswordResetCode: forgotPasswordResetCode,
        forgotPasswordResetPassword: forgotPasswordResetPassword,
        forgotPasswordResetPasswordConfirm: forgotPasswordResetPasswordConfirm,
        profileCardActiveLink: profileCardActiveLink,
        signedInUserFirstName: signedInUserFirstName,
        signedInUserLastName: signedInUserLastName,
        signedInUserEmail: signedInUserEmail,
        signedInUserProfileImage: signedInUserProfileImage,
        signedInUserAccessToken: signedInUserAccessToken,
        signedInUserAccessTokenExpiration: signedInUserAccessTokenExpiration,
        signedInUserRole: signedInUserRole,
        signedInUserNotifications: signedInUserNotifications,
        signedInUserNotificationsEmail: signedInUserNotificationsEmail,
        signedInUserNotificationsPhone: signedInUserNotificationsPhone,
        // signedInUserBankInfoBankName: signedInUserBankInfoBankName,
        // signedInUserBankInfoRoutingNumber: signedInUserBankInfoRoutingNumber,
        // signedInUserBankInfoAccountNumber: signedInUserBankInfoAccountNumber,
        // signedInUserBankInfoAccountHolderName: signedInUserBankInfoAccountHolderName,
        // signedInUserAccountBankInformationEditStatus: signedInUserAccountBankInformationEditStatus,
        signedInUserAccountPersonalDetailsFirstName: signedInUserAccountPersonalDetailsFirstName,
        signedInUserAccountPersonalDetailsLastName: signedInUserAccountPersonalDetailsLastName,
        signedInUserAccountPersonalDetailsEmail: signedInUserAccountPersonalDetailsEmail,
        signedInUserAccountPersonalDetailsAddress: signedInUserAccountPersonalDetailsAddress,
        signedInUserAccountPersonalDetailsCity: signedInUserAccountPersonalDetailsCity,
        signedInUserAccountPersonalDetailsState: signedInUserAccountPersonalDetailsState,
        signedInUserAccountPersonalDetilsZipCode: signedInUserAccountPersonalDetilsZipCode,
        // signedInUserAccountPersonalDetailsPhoneNumber:signedInUserAccountPersonalDetailsPhoneNumber,
        signedInUserProfileCurrentPassword: signedInUserProfileCurrentPassword,
        signedInUserProfileNewPassword: signedInUserProfileNewPassword,
        signedInUserProfileNewPasswordConfirm: signedInUserProfileNewPasswordConfirm,
        signInMerchantOnboardingLink: signInMerchantOnboardingLink,
        merchantVenueCreateVenueName: merchantVenueCreateVenueName,
        merchantVenueCreateVenueWebsite: merchantVenueCreateVenueWebsite,
        merchantVenueCreateVenueAddressLine1: merchantVenueCreateVenueAddressLine1,
        merchantVenueCreateVenueAddressLine2: merchantVenueCreateVenueAddressLine2,
        merchantVenueCreateVenueAddressCity: merchantVenueCreateVenueAddressCity,
        merchantVenueCreateVenueAddressState: merchantVenueCreateVenueAddressState,
        merchantVenueCreateVenuePhoneNumber: merchantVenueCreateVenuePhoneNumber,
        merchantVenueCreateVenueAddressZipCode: merchantVenueCreateVenueAddressZipCode,
        merchantVenueCreateVenueFacebookPage: merchantVenueCreateVenueFacebookPage,
        merchantVenueCreateVenueLogoFile: merchantVenueCreateVenueLogoFile,
        merchantVenueCreateVenueBackgroundImageFile: merchantVenueCreateVenueBackgroundImageFile,
        activeSidebarTab: activeSidebarTab,
        mechantVenueCreateVenueHoursOfOperationMondayCheckbox: mechantVenueCreateVenueHoursOfOperationMondayCheckbox,
        mechantVenueCreateVenueHoursOfOperationTuesdayCheckbox: mechantVenueCreateVenueHoursOfOperationTuesdayCheckbox,
        mechantVenueCreateVenueHoursOfOperationWednesdayCheckbox: mechantVenueCreateVenueHoursOfOperationWednesdayCheckbox,
        mechantVenueCreateVenueHoursOfOperationThursdayCheckbox: mechantVenueCreateVenueHoursOfOperationThursdayCheckbox,
        mechantVenueCreateVenueHoursOfOperationFridayCheckbox: mechantVenueCreateVenueHoursOfOperationFridayCheckbox,
        mechantVenueCreateVenueHoursOfOperationSaturdayCheckbox: mechantVenueCreateVenueHoursOfOperationSaturdayCheckbox,
        mechantVenueCreateVenueHoursOfOperationSundayCheckbox: mechantVenueCreateVenueHoursOfOperationSundayCheckbox,
        merchantVenueCreateVenueHoursOfOperaitonMondayOpenHour: merchantVenueCreateVenueHoursOfOperaitonMondayOpenHour,
        merchantVenueCreateVenueHoursOfOperaitonMondayOpenMinute: merchantVenueCreateVenueHoursOfOperaitonMondayOpenMinute,
        merchantVenueCreateVenueHoursOfOperaitonMondayOpenAmpm: merchantVenueCreateVenueHoursOfOperaitonMondayOpenAmpm,
        merchantVenueCreateVenueHoursOfOperaitonMondayCloseHour: merchantVenueCreateVenueHoursOfOperaitonMondayCloseHour,
        merchantVenueCreateVenueHoursOfOperaitonMondayCloseMinute: merchantVenueCreateVenueHoursOfOperaitonMondayCloseMinute,
        merchantVenueCreateVenueHoursOfOperaitonMondayCloseAmpm: merchantVenueCreateVenueHoursOfOperaitonMondayCloseAmpm,
        merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenHour: merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenHour,
        merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenMinute: merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenMinute,
        merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenAmpm: merchantVenueCreateVenueHoursOfOperaitonTuesdayOpenAmpm,
        merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseHour: merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseHour,
        merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseMinute: merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseMinute,
        merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseAmpm: merchantVenueCreateVenueHoursOfOperaitonTuesdayCloseAmpm,
        merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenHour: merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenHour,
        merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenMinute: merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenMinute,
        merchantVenueCreateVenueHoursOfOperaitonTWednesayOpenAmpm: merchantVenueCreateVenueHoursOfOperaitonWednesdayOpenAmpm,
        merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseHour: merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseHour,
        merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseMinute: merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseMinute,
        merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseAmpm: merchantVenueCreateVenueHoursOfOperaitonWednesdayCloseAmpm,

        merchantVenueCreateVenueHoursOfOperaitonThursdayOpenHour: merchantVenueCreateVenueHoursOfOperaitonThursdayOpenHour,
        merchantVenueCreateVenueHoursOfOperaitonThursdayOpenMinute: merchantVenueCreateVenueHoursOfOperaitonThursdayOpenMinute,
        merchantVenueCreateVenueHoursOfOperaitonThursayOpenAmpm: merchantVenueCreateVenueHoursOfOperaitonThursdayOpenAmpm,
        merchantVenueCreateVenueHoursOfOperaitonThursdayCloseHour: merchantVenueCreateVenueHoursOfOperaitonThursdayCloseHour,
        merchantVenueCreateVenueHoursOfOperaitonThursdayCloseMinute: merchantVenueCreateVenueHoursOfOperaitonThursdayCloseMinute,
        merchantVenueCreateVenueHoursOfOperaitonThursdayCloseAmpm: merchantVenueCreateVenueHoursOfOperaitonThursdayCloseAmpm,
        merchantVenueCreateVenueHoursOfOperaitonFridayOpenHour: merchantVenueCreateVenueHoursOfOperaitonFridayOpenHour,
        merchantVenueCreateVenueHoursOfOperaitonFridayOpenMinute: merchantVenueCreateVenueHoursOfOperaitonFridayOpenMinute,
        merchantVenueCreateVenueHoursOfOperaitonFriayOpenAmpm: merchantVenueCreateVenueHoursOfOperaitonFridayOpenAmpm,
        merchantVenueCreateVenueHoursOfOperaitonFridayCloseHour: merchantVenueCreateVenueHoursOfOperaitonFridayCloseHour,
        merchantVenueCreateVenueHoursOfOperaitonFridayCloseMinute: merchantVenueCreateVenueHoursOfOperaitonFridayCloseMinute,
        merchantVenueCreateVenueHoursOfOperaitonFridayCloseAmpm: merchantVenueCreateVenueHoursOfOperaitonFridayCloseAmpm,
        merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenHour: merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenHour,
        merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenMinute: merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenMinute,
        merchantVenueCreateVenueHoursOfOperaitonSaturayOpenAmpm: merchantVenueCreateVenueHoursOfOperaitonSaturdayOpenAmpm,
        merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseHour: merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseHour,
        merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseMinute: merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseMinute,
        merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseAmpm: merchantVenueCreateVenueHoursOfOperaitonSaturdayCloseAmpm,
        merchantVenueCreateVenueHoursOfOperaitonSundayOpenHour: merchantVenueCreateVenueHoursOfOperaitonSundayOpenHour,
        merchantVenueCreateVenueHoursOfOperaitonSundayOpenMinute: merchantVenueCreateVenueHoursOfOperaitonSundayOpenMinute,
        merchantVenueCreateVenueHoursOfOperaitonSunayOpenAmpm: merchantVenueCreateVenueHoursOfOperaitonSundayOpenAmpm,
        merchantVenueCreateVenueHoursOfOperaitonSundayCloseHour: merchantVenueCreateVenueHoursOfOperaitonSundayCloseHour,
        merchantVenueCreateVenueHoursOfOperaitonSundayCloseMinute: merchantVenueCreateVenueHoursOfOperaitonSundayCloseMinute,
        merchantVenueCreateVenueHoursOfOperaitonSundayCloseAmpm: merchantVenueCreateVenueHoursOfOperaitonSundayCloseAmpm,
        merchantVenueListVenuesZeroLengthFlag: merchantVenueListVenuesZeroLengthFlag,
        merchantVenueListVenuesFilteredList: merchantVenueListVenuesFilteredList,
        merchantServiceCreateServiceImageValidationStatus: merchantServiceCreateServiceImageValidationStatus,
        merchantServiceCreateServiceNameValidationStatus: merchantServiceCreateServiceNameValidationStatus,
        merchantServiceCreateServiceQuotaValidationStatus: merchantServiceCreateServiceQuotaValidationStatus,
        merchantServiceCreateServiceMinParticipantsValidationStatus: merchantServiceCreateServiceMinParticipantsValidationStatus,
        merchantServiceCreateServiceMaxParticipantsValidationStatus: merchantServiceCreateServiceMaxParticipantsValidationStatus,
        merchantServiceCreateServiceShortDescriptionValidationStatus: merchantServiceCreateServiceShortDescriptionValidationStatus,
        merchantServiceCreateServiceLongDescriptionValidationStatus: merchantServiceCreateServiceLongDescriptionValidationStatus,
        merchantServiceCreateServicePriceValidationStatus: merchantServiceCreateServicePriceValidationStatus,
        merchantServiceCreateServicePerUnitValidationStatus: merchantServiceCreateServicePerUnitValidationStatus,
        merchantServiceCreateServiceSelectedVenueValidationStatus: merchantServiceCreateServiceSelectedVenueValidationStatus,
        merchantServiceCreateServicePerUnit: merchantServiceCreateServicePerUnit, // this is to get rid of an erorr
        merchantServiceListServicesZeroLengthFlag: merchantServiceListServicesZeroLengthFlag,
        merchantServiceListServicesFilteredList: merchantServiceListServicesFilteredList,
        publicVenueInfoBackgroundImage: publicVenueInfoBackgroundImage,
        publicVenueInfoLogo: publicVenueInfoLogo,
        publicVenueInfoName: publicVenueInfoName,
        publicVenueInfoAddressStreet0: publicVenueInfoAddressStreet0,
        publicVenueInfoAddressStreet1: publicVenueInfoAddressStreet1,
        publicVenueInfoAddressCity: publicVenueInfoAddressCity,
        publicVenueInfoAddressState: publicVenueInfoAddressState,
        publicVenueInfoAddressZipCode: publicVenueInfoAddressZipCode,
        publicVenueInfoAddressPhoneNumber: publicVenueInfoAddressPhoneNumber,
        publicVenueInfoWebsite: publicVenueInfoWebsite,
        publicVenueServiceList: publicVenueServiceList,
        publicVenueBookService: publicVenueBookService,
        publicVenueInviteList: publicVenueInviteList,
        publicVenueBookingMonth: publicVenueBookingMonth,
        publicVenueBookingDay: publicVenueBookingDay,
        publicVenueBookingStartTimeHour: publicVenueBookingStartTimeHour,
        publicVenueBookingStartTimeMinute: publicVenueBookingStartTimeMinute,
        publicVenueBookingStartTimeAmPm: publicVenueBookingStartTimeAmPm,
        publicVenueBookingEndTimeHour: publicVenueBookingEndTimeHour,
        publicVenueBookingEndTimeMinute: publicVenueBookingEndTimeMinute,
        publicVenueBookingEndTimeAmPm: publicVenueBookingEndTimeAmPm,
        invitationFirstName: invitationFirstName,
        invitationServiceName: invitationServiceName,
        invitationEventDate: invitationEventDate,
        invitationEventStartTime: invitationEventStartTime,
        invitationEventEndTime: invitationEventEndTime,
        invitationVenueName: invitationVenueName,
        invitationVenueAddress1: invitationVenueAddress1,
        invitationVenueAddress2: invitationVenueAddress2,
        invitationVenueCity: invitationVenueCity,
        invitationVenueState: invitationVenueState,
        invitationVenueZipCode: invitationVenueZipCode,
        invitationServiceImage: invitationServiceImage,
        invtationServicePrice: invtationServicePrice,
        invitationRsvpChoice: invitationRsvpChoice,
        invtationPaymentIntentId: invtationPaymentIntentId,
        invtationPaymentIntentIdSecret: invtationPaymentIntentIdSecret,
        publicVenueBookingYear: publicVenueBookingYear,
        merchantBookings: merchantBookings,
        merchantBookingStatusEditFlag: merchantBookingStatusEditFlag,
        merchantBookingStatusEditId: merchantBookingStatusEditId,
        invitationPaid: invitationPaid,
        // publicVenueSignInMessage: publicVenueSignInMessage,
        // publicVenueSignInError: publicVenueSignInError,
        modalMessage: modalMessage,
        modalError: modalError,
        // gapi:gapi,
        // googleAuth:googleAuth,
        googleSignedIn: googleSignedIn,
        setModalCopyContent: setModalCopyContentHandler,
        setModalTitleContent: setModalTitleContentHandler,
        modalCloseButtonOnClick: modalCloseButtonOnClickHandler,
        setModalDisplayState: setModalDisplayStateHandler,
        signInTopLinkOnClick: signInTopLinkClickHandler,
        merchantPlannerOnClick: merchantPlannerClickHandler,
        onRegister: registerButtonClickHandler,
        sidebarOnClick: sidebarOnClickHandler,
        emailInputOnChange: emailInputChangeHandler,
        firstNameInputOnChange: firstNameInputChangeHandler,
        lastNameInputOnChange: lastNameInputChangeHandler,
        passwordInputOnChnage: passwordInputChangeHandler,
        passwordVerifyOnChange: passwordVerifyInputChangeHandler,
        resendOnClick: resendOnClickHandler,
        launchOnClick: launchClickHandler,
        verifyCodeOnChange: verifyCodeOnChangeHandler,
        signUpOnClick: signUpOnClickHandler,
        signInMerchantPlannerOnClick: signInMerchantPlannerClickHandler,
        signInEmailOnChange: signInEmailOnChangeHandler,
        signInPasswordOnChange: signInPasswordOnChangeHandler,
        signInButtonOnClick: signInButtonOnClickHandler,
        forgotPasswordLinkOnClick: forgotPasswordLinkOnClickHandler,
        forgotPasswordEmailOnChange: forgotPasswordEmailOnChangeHandler,
        forgotPasswordButtonOnClick: forgotPasswordButtonClickHandler,
        forgotPasswordResetCodeOnChange: forgotPasswordResetCodeOnChangeHandler,
        forgotPasswordResetPasswordOnChange: forgotPasswordResetPasswordOnChangeHandler,
        forgotPasswordResetPasswordConfirmOnChange: forgotPasswordResetPasswordConfirmOnChangeHandler,
        forgotPasswordResetPasswordButonOnClick: forgotPasswordResetPasswordButonOnClickHandler,
        profileIconOnClick: profileIconOnClickHandler,
        profileCardAccountLinkOnClick: profileCardAccountLinkOnClickHandler,
        profileCardNotificationsLinkOnClick: profileCardNotificationsLinkOnClickHandler,
        profileCardLegalLinkOnClick: profileCardLegalLinkOnClickHandler,
        getSignedInUserNotifications: getSignedInUserNotificationsHandler,
        signedInUserEmailNotificiationCheckBoxOnChange: signedInUserEmailNotificiationCheckBoxOnChangeHandler,
        signedInUserNotificationSaveButtonOnClick: signedInUserNotificationSaveButtonOnClickHandler,
        signedInUserLegalCloseButtonOnClick: signedInUserLegalCloseButtonOnClickHandler,
        signedInUserAccountCloseButtonOnClick: signedInUserAccountCloseButtonOnClickHandler,
        signedInUserAccountBankInformationEditOnClick: signedInUserAccountBankInformationEditOnClickHandler,
        // signedInuserBankInfoBankNameOnChange: signedInuserBankInfoBankNameOnChangeHandler,
        // signedInBankInfoRoutingNumberOnChange: signedInBankInfoRoutingNumberOnChangeHandler,
        // signedInUserBankInfoAccountNumberOnChange: signedInUserBankInfoAccountNumberOnChangeHandler,
        // signedInUserBankInfoAccountHolderNameOnChange: signedInUserBankInfoAccountHolderNameOnChangeHandler,
        // signedInUserBankInfoButtonOnClick: signedInUserBankInfoButtonOnClickHandler,
        signedInUserProfilePhotoUploadLinkOnClick: signedInUserProfilePhotoUploadLinkOnClickHandler,
        signedInUserProfilePhotoUploadFileOnChange: signedInUserProfilePhotoUploadFileOnChangeHandler,
        signedInUserProfilePhotoUploadFileButtonOnClick: signedInUserProfilePhotoUploadFileButtonOnClickHandler,
        signedInUserAccountPersonalDetailsEditOnClick: signedInUserAccountPersonalDetailsEditOnClickHandler,
        signedInUserAccountPersonalDetailsFirstNameFieldOnChange: signedInUserAccountPersonalDetailsFirstNameFieldOnChangeHandler,
        signedInUserAccountPersonalDetailsLastNameFieldOnChange: signedInUserAccountPersonalDetailsLastNameFieldOnChangeHandler,
        signedInUserAccountPersonalDetailsEmailFieldOnChange: signedInUserAccountPersonalDetailsEmailFieldOnChangeHandler,
        signedInUserAccountPersonalDetailsAddressFieldOnChange: signedInUserAccountPersonalDetailsAddressFieldOnChangeHandler,
        signedInUserAccountPersonalDetailsCityFieldOnChange: signedInUserAccountPersonalDetailsCityFieldOnChangeHandler,
        signedInUserAccountPersonalDetailsStateFieldOnChange: signedInUserAccountPersonalDetailsStateFieldOnChangeHandler,
        signedInUserAccountPersonalDetilsZipCodeFieldChange: signedInUserAccountPersonalDetilsZipCodeFieldChangeHandler,
        signedInUserAccountPersonalDetailsSaveButtonOnClick: signedInUserAccountPersonalDetailsSaveButtonOnClickHandler,
        // setSignedInUserAccountPersonalDetails: setSignedInUserAccountPersonalDetailsHandler,
        getSignedInUserAccountPersonalDetails: getSignedInUserAccountPersonalDetailsHandler,
        signedInUserAccountPasswordResetOnClick: signedInUserAccountPasswordResetOnClickHandler,
        signedInUserProfileCurrentPasswordOnChange: signedInUserProfileCurrentPasswordOnChangeHandler,
        signedInUserProfileNewPasswordOnChange: signedInUserProfileNewPasswordOnChangeHandler,
        signedInUserProfileNewPasswordConfirmOnChange: signedInUserProfileNewPasswordConfirmOnChangeHandler,
        signedInUserProfilePasswordResetButtonOnClick: signedInUserProfilePasswordResetButtonOnClickHandler,
        merchantVenueCreateVenueNameFieldOnChange: merchantVenueCreateVenueNameFieldOnChangeHandler,
        merchantVenueCreateVenueWebsiteFieldOnChange: merchantVenueCreateVenueWebsiteFieldOnChangeHandler,
        merchantVenueCreateVenueAddressLine1FieldOnChange: merchantVenueCreateVenueAddressLine1FieldOnChangeHandler,
        merchantVenueCreateVenueAddressLine2FieldOnChange: merchantVenueCreateVenueAddressLine2FieldOnChangeHandler,
        merchantVenueCreateVenueAddressCityFieldOnChange: merchantVenueCreateVenueAddressCityFieldOnChangeHandler,
        merchantVenueCreateVenueAddressStateFieldOnChange: merchantVenueCreateVenueAddressStateFieldOnChangeHandler,
        merchantVenueCreateVenuePhoneNumberFieldOnChange: merchantVenueCreateVenuePhoneNumberFieldOnChangeHandler,
        merchantVenueCreateVenueAddressZipCodeFieldOnChange: merchantVenueCreateVenueAddressZipCodeFieldOnChangeHandler,
        merchantVenueCreateVenueFacebookPageFieldOnChange: merchantVenueCreateVenueFacebookPageFieldOnChangeHandler,
        merchantVenueCreateVenueNextButtonOnClick: merchantVenueCreateVenueNextButtonOnClickHandler,
        merchantVenueCreateVenueLogoFieldOnChange: merchantVenueCreateVenueLogoFieldOnChangeHandler,
        merchantVenueCreateVenueBackgroundImageFieldOnChange: merchantVenueCreateVenueBackgroundImageFieldOnChangeHandler,
        merchantVenueCreateVenueHoursOfOperationHourOnChange: merchantVenueCreateVenueHoursOfOperationHourOnChangeHandler,
        merchantVenueCreateVenueHoursOfOperationMinuteOnChange: merchantVenueCreateVenueHoursOfOperationMinuteOnChangeHandler,
        mechantVenueCreateVenueHoursOfOperationCheckboxOnChange: mechantVenueCreateVenueHoursOfOperationCheckboxOnChangeHandler,
        merchantVenueCreateVenueHoursOfOperationAmpmOnChange: merchantVenueCreateVenueHoursOfOperationAmpmOnChangeHanlder,
        merchantVenueCreateVenueSaveVenueButtonClick: merchantVenueCreateVenueSaveVenueButtonClickHandler,
        merchantVenueCreateVenueBackButtonClick: merchantVenueCreateVenueBackButtonClickHandler,
        merchantVenueListVenues: merchantVenueListVenues,
        merchantVenueCreateVenueButtonOnClick: merchantVenueCreateVenueButtonOnClickHandler,
        merchantServiceCreateServiceButtonOnClick: merchantServiceCreateServiceButtonOnClickHandler,
        merchantServiceCreateServiceImageInputOnChange: merchantServiceCreateServiceImageInputOnChangeHandler,
        merchantServiceCreateServiceNameInputOnChange: merchantServiceCreateServiceNameInputOnChangeHandler,
        merchantServiceCreateServiceQuotaInputOnChange: merchantServiceCreateServiceQuotaInputOnChangeHandler,
        merchantServiceCreateServiceMinParticipantsInputOnChange: merchantServiceCreateServiceMinParticipantsInputOnChangeHandler,
        merchantServiceCreateServiceMaxParticipantsInputOnChange: merchantServiceCreateServiceMaxParticipantsInputOnChangeHandler,
        merchantServiceCreateServiceShortDescriptionInputOnChange: merchantServiceCreateServiceShortDescriptionInputOnChangeHandler,
        merchantServiceCreateServiceLongDescriptionInputOnChange: merchantServiceCreateServiceLongDescriptionInputOnChangeHandler,
        merchantServiceCreateServicePriceInputOnChange: merchantServiceCreateServicePriceInputOnChangeHandler,
        merchantServiceCreateServicePerUnitInputOnChange: merchantServiceCreateServicePerUnitInputOnChangeHandler,
        merchantServiceCreateServiceSaveServiceButtonOnClick: merchantServiceCreateServiceSaveServiceButtonOnClickHandler,
        merchantServiceCreateServiceSelectedVenueOnChange: merchantServiceCreateServiceSelectedVenueOnChangeHandler,
        merchantServiceListServices: merchantServiceListServices,
        publicGetVenueList: publicGetVenueList,
        publicVenueSignInButtonOnClick: publicVenueSignInButtonOnClickHandler,
        publivVenueModalSignInButtonOnClick: publivVenueModalSignInButtonOnClickHandler,
        // publicVenueServicesListLoggedIn: publicVenueServicesListLoggedIn,
        publicVenueBookServiceButtonOnClick: publicVenueBookServiceButtonOnClickHandler,
        publicVenueAddGuestButtonOnClick: publicVenueAddGuestButtonOnClickHandler,
        publicVenueRemoveGuestButtonOnClick: publicVenueRemoveGuestButtonOnClickHandler,
        publicVenueGuestFirstNameFieldOnChange: publicVenueGuestFirstNameFieldOnChangeHandler,
        publicVenueGuestLastNameFieldOnChange: publicVenueGuestLastNameFieldOnChangeHandler,
        publicVenueGuestEmailFieldOnChange: publicVenueGuestEmailFieldOnChangeHandler,
        publicVenueMonthFieldOnChange: publicVenueMonthFieldOnChangeHandler,
        publicVenueDayFieldOnChange: publicVenueDayFieldOnChangeHandler,
        publicVenueStartTimeHourFieldOnChange: publicVenueStartTimeHourFieldOnChangeHandler,
        publicVenueStartTimeMinuteFieldOnChange: publicVenueStartTimeMinuteFieldOnChangeHandler,
        publicVenueStartTimeAmPmFieldOnChange: publicVenueStartTimeAmPmFieldOnChangeHandler,
        publicVenueEndTimeHourFieldOnChange: publicVenueEndTimeHourFieldOnChangeHandler,
        publicVenueEndTimeMinuteFieldOnChange: publicVenueEndTimeMinuteFieldOnChangeHandler,
        publicVenueEndTimeAmPmFieldOnChange: publicVenueEndTimeAmPmFieldOnChangeHandler,
        publicVenueCreateEventButtonOnClick: publicVenueCreateEventButtonOnClickHandler,
        getInvitationDeatilsHandler: getInvitationDeatilsHandler,
        InvitationDetailsRsvpButtonOnClick: InvitationDetailsRsvpButtonClickHandler,
        rsvpChoiceOnClick: rsvpChoiceOnClickHandler,
        rsvpChoiceNextOnClick: rsvpChoiceNextOnClickHandler,
        rsvpChoiceBackOnClick: rsvpChoiceBackOnClickHandler,
        publicVenueYearFieldOnChange: publicVenueYearFieldOnChangeHandler,
        merchantEventLister: merchantEventLister,
        merchantBookingStatusEditOnClick: merchantBookingStatusEditOnClickHandler,
        merchantBookingStatusEditChoiceOnClick: merchantBookingStatusEditChoiceOnClickHandler,
        sidebarExitIconOnClick: sidebarExitIconOnClickHandler,
        rsvpPaymentSuccess: rsvpPaymentSuccess,
        rsvpPaymentError: rsvpPaymentError,
        publicVenueRegisterButtonOnClick: publicVenueRegisterButtonOnClickHandler,
        // googleSetGapi:googleSetGapi,
        // googleSetGoogleAuth:googleSetGoogleAuth,
        // renderSigninButton:renderSigninButton,
        googleAuth2ApiLoader: googleAuth2ApiLoader,
        googleSignInButtonOnClick: googleSignInButtonOnClickHandler,
        googleSignPutButtonOnClick: googleSignPutButtonOnClickHandler,
        googleListContactsButtonOnClick: googleListContactsButtonOnClickHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
