import { Switch, Route } from "react-router-dom";
import ProfileCard from "../../../ui/profile/profileCard"
import PlannerProfilePhotoUpload from "./plannerProfilePhotoUpload";
import PlannerProfileAccount from "./plannerProfileAccount";
import PlannerProfileAccountPersonalDetails from "./plannerProfileAccountPersonalDetails";
import PlannerProfileLegal from "./plannerProfileLegal";

const PlannerProfile = props => {
  return (
    <>
      <div id="profile__wrapper">
        <ProfileCard />
        <Switch>
          <Route path="/planner/profile" exact>

          </Route>
          <Route path="/planner/profile/profile-photo" exact>
            <PlannerProfilePhotoUpload />
          </Route>
          <Route path="/planner/profile/account" exact>
            <PlannerProfileAccount />
         </Route>
         <Route path="/planner/profile/account/personal-details">
          <PlannerProfileAccountPersonalDetails />
        </Route>
        <Route path="/planner/profile/legal">
          <PlannerProfileLegal />
        </Route>
        </Switch>
      </div>
    </>
  )
}

export default PlannerProfile;