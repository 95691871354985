import { React, useContext } from "react";
import AuthContext from "../../../store/auth-context";
import Button from "../../ui/button/button";
import RsvpYesOptionIcon from "../../../globals/icons/rsvpYesOptionIcon";
import RsvpNoOption from "../../../globals/icons/rsvpNoOption";

const InvitationRsvp = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <>
      <div id="invitations-rsvp__wrapper">
        <span id="invitations-rsvp__wrapper__rsvp">RSVP</span>
        <span id="invitations-rsvp__wrapper__subHeader">Let us know if you would like to join the {ctx.invitationServiceName} event!</span>
        <div id="invitations-rsvp__wrapper__rsvp-wrapper">
          <div className={`invitations-rsvp__wrapper__rsvp-wrapper__rsvp-option ${ctx.invitationRsvpChoice === "going" ? "active" : ""}`} onClick={() => ctx.rsvpChoiceOnClick('going')}>
            <RsvpYesOptionIcon />
            <span>I'm Going!</span>
          </div>
          <div className={`invitations-rsvp__wrapper__rsvp-wrapper__rsvp-option ${ctx.invitationRsvpChoice === "not-going" ? "active" : ""}`} onClick={() => ctx.rsvpChoiceOnClick('not-going')}>
            <RsvpNoOption />
            <span>Not Going!</span>
          </div>
        </div>
        <div id="invitations-rsvp__wrapper__buttons-wrapper">
          <Button className="button-passive" onClick={ctx.rsvpChoiceBackOnClick}>Back</Button>
          <Button className="button-active" disabled={ctx.invitationRsvpChoice === undefined} onClick={ctx.rsvpChoiceNextOnClick}>Next</Button>

        </div>
      </div>
    </>
  );
};

export default InvitationRsvp;
