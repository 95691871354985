import Button from "../../../ui/button/button";

const MerchantVenueAddDetailsBankInfo = (props) => {
  return (
    <>
      <h2>Enter your Bank Information</h2>
      <p>
        Payments owed to you per your merchant agreement will be deposited into
        this account.
      </p>
      <label htmlFor="bankName">Bank Name</label>
      <input type="text" id="bankName" />
      <label htmlFor="accountHolderName">Name of the Account Holder</label>
      <input type="text" id="accountHolderName" />
      <label htmlFor="routingNumber">Routing Number</label>
      <input type="number" id="routingNumber" />
      <label htmlFor="accountNumber">Bank Account Number</label>
      <input type="number" id="accountNumber" />
      <Button className="button-active">Save</Button>
    </>
  );
};

export default MerchantVenueAddDetailsBankInfo;
