
import {React,useContext } from 'react';
import Button from '../../../ui/button/button';
import AuthContext from "../../../../store/auth-context";




const MerchantProfileNotifications = props => {
  const ctx = useContext(AuthContext);


  // useEffect(() => {
  //   void(ctx.signedInUserNotifications === '' && ctx.getSignedInUserNotifications());
  // }, []);

  


  return (
    <> 
      <div id="merchantProfile__notifications__wrapper">
        <h1>Manage my notifications</h1>
        <input type="checkbox" id="email" name="email" checked={ctx.signedInUserNotificationsEmail}  onChange={ctx.signedInUserEmailNotificiationCheckBoxOnChange}/> 
        <label htmlFor="email" className="merchantProfile__notifications__wrapper__checkbox-label">Notify by email</label>
        <Button className="button-active" onClick={ctx.signedInUserNotificationSaveButtonOnClick}>Save</Button>
      </div>
    </>
  )
}

export default MerchantProfileNotifications;