const LegalIcon = (props) => {
  return (
    <svg
      id="legalIcon"
      className={props.className}
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <g>
        <g>
          <path d="M97.1,49.3L82.9,21.3c-0.5-1-1.4-1.6-2.5-1.7l-19.2,0.1c-0.9-2.7-2.8-5-5.4-6.4V9.6c0-3.2-2.6-5.9-5.9-5.9    s-5.9,2.6-5.9,5.9v3.7c-2.5,1.4-4.4,3.7-5.3,6.4l-19.2-0.1c-1,0.1-2,0.7-2.5,1.7L2.9,49.3c-0.4,0.8-0.5,1.8-0.3,2.7    c2.4,8.7,9.1,14.3,17.1,14.3c8,0,14.7-5.6,17.1-14.3c0.2-0.9,0.1-1.8-0.3-2.7l-11-21.8l13.2,0.1c1,2.7,2.8,4.9,5.3,6.3v37.5    c0,7-5.3,12.9-12.3,13.6L21,86.2c-1.2,0.1-2.1,1.2-2.1,2.4v5.4c0,1.3,1.1,2.4,2.4,2.4h57.6c1.3,0,2.4-1.1,2.4-2.4v-5.4    c0-1.2-0.9-2.2-2.1-2.4L68.1,85c-7-0.8-12.3-6.6-12.3-13.6V33.9c2.5-1.4,4.3-3.6,5.3-6.3l13.2-0.1l-11,21.8    c-0.4,0.8-0.5,1.8-0.3,2.7c2.4,8.7,9.1,14.3,17.1,14.3c8,0,14.7-5.6,17.1-14.3C97.6,51.1,97.5,50.2,97.1,49.3z M28.9,45.5H10.6    l9.2-18.1L28.9,45.5z M71.1,45.5l9.2-18.1l9.2,18.1H71.1z"></path>
        </g>
      </g>
    </svg>
  );
};

export default LegalIcon;
