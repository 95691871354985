import './icons.scss';

const SidebarDashboardIcon = (props) => {
  return (
    <svg


      viewBox="0 0 32 32"
      id="sidebardDashboardIcon"
      className={props.className}
    >
     
        <path d="M14,7V25a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V7A3,3,0,0,1,7,4h4A3,3,0,0,1,14,7ZM25,19.7H19a3,3,0,0,0-3,3V25a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3V22.7A3,3,0,0,0,25,19.7ZM25,4H19a3,3,0,0,0-3,3v7.7a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3V7A3,3,0,0,0,25,4Z"></path>
    
    </svg>
  );
};

export default SidebarDashboardIcon;
