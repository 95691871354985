const EyeIcon = (props) => {
  return (
    <svg 
      id="eyeIcon"
      viewBox="0 0 25 16">
        <path
        transform="translate(-1094.000000, -196.000000)"
          d="M1106.5,196 C1101.26315,196 1096.51281,198.87843 1094.05181,203.524466 C1093.98273,203.628766 1093.98273,203.733066 1094.05181,203.836014 C1096.47917,208.481978 1101.26488,211.360479 1106.5,211.360479 C1111.73685,211.360479 1116.48719,208.482048 1118.94819,203.836014 C1119.01727,203.731714 1119.01727,203.627414 1118.94819,203.524466 C1116.48695,198.8785 1111.7365,196 1106.5,196 Z"
        ></path>
        <path
        transform="translate(-1094.000000, -196.000000)"
          d="M1106.5,209.108084 C1103.48342,209.108084 1101.05613,206.680726 1101.05613,203.664213 C1101.05613,200.6477 1103.48348,198.22034 1106.5,198.22034 C1109.51651,198.22034 1111.94387,200.6477 1111.94387,203.664213 C1111.94387,206.680726 1109.51651,209.108084 1106.5,209.108084 Z"
        ></path>
    </svg>
  );
};

export default EyeIcon;


