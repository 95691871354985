import {React,useContext} from 'react';
import Button from '../../../ui/button/button';
import AuthContext from "../../../../store/auth-context";



const MerchantProfileAccountPasswordReset = (props) => {
  const ctx = useContext(AuthContext);

  return (
    <>
      <div id="merchantProfile__account__wrapper">
      
        <h1>Password Reset</h1>
        <div className="merchantProfileAccount__passwordReset__inputWrapper">
          <label htmlFor="currentPassword">Current Password</label>
          <input id="currentPassword" type="password" onChange={ctx.signedInUserProfileCurrentPasswordOnChange} />
        </div>
        <div className="merchantProfileAccount__passwordReset__inputWrapper">
          <label htmlFor="newPassword">New Password</label>
          <input id="newPassword" type="password" onChange={ctx.signedInUserProfileNewPasswordOnChange} />
        </div>
        <div className="merchantProfileAccount__passwordReset__inputWrapper">
          <label htmlFor="newPasswordConfirm">Retype your new password</label>
          <input id="newPasswordConfirm" type="password" onChange={ctx.signedInUserProfileNewPasswordConfirmOnChange} />
        </div>
        
        <Button className="button-active" onClick={ctx.signedInUserProfilePasswordResetButtonOnClick}>Save</Button>
      </div>
    </>
  );
};

export default MerchantProfileAccountPasswordReset;
