import { React, useContext } from "react";
import AuthContext from "../../../../store/auth-context";
import Button from "../../../ui/button/button";


const PlannerProfilePhotoUpload = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <>
      <div id="merchantProfile__profilePhoto__wrapper">
        <h1>Upload your profile picture</h1>
        <input
          type="file"
          accept=".jpg, .jpeg, .png"
          onChange={ctx.signedInUserProfilePhotoUploadFileOnChange}
        />
        <Button
          className="button-active"
          onClick={ctx.signedInUserProfilePhotoUploadFileButtonOnClick}
        >
          Upload
        </Button>
      </div>
    </>
  );
};

export default PlannerProfilePhotoUpload;
