import { React, useContext } from "react";
// import Button from "../../../ui/button/button";
import AuthContext from "../../../../store/auth-context";

const MerchantProfileAccountBankInfo = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <>
      <div id="merchantProfileAccount__bankInfo__wrapper">
        <h1>Edit your payment information</h1>
        <p className="merchantProfileAccount__bankInfo__wrapper__copy">
          Payments owed to you per your merchant agreement will be deposited
          into this account.
        </p>
        <div className="merchantProfileAccount__bankInfo__inputWrapper">
          <a href={ctx.signInMerchantOnboardingLink} id="merchantProfileAccount__bankInfo__inputWrapper__onboarding-button" role="button" target="_blank" rel="noreferrer"><span>Connect With </span></a>
        </div>

      </div>
    </>
  );
};

export default MerchantProfileAccountBankInfo;
