// import { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ViewMerchantDashboard from "../../views/merchant/merchantDashboard";
import ViewMerchantBookings from "../../views/merchant/bookings/merchantBookings";
import MerchantProfile from "../../views/merchant/merchantProfile/merchantProfile";
import ViewMerchantServices from "../../views/merchant/merchantServices/merchantServices";
import ViewMerchantVenue from "../../views/merchant/merchantVenue/merchantVenue";
import PlannerDashboard from "../../views/planner/plannerDashboard";
import PlannerProfile from "../../views/planner/plannerProfile/plannerProfile";


const MainContent = (props) => {

  
  
  return (
    <div id="mainContent">
      <Switch>
        {/* 
        ************************************************** 
        ***** Merchant Views
        **************************************************  
        */}
        <Route path="/merchant/dashboard">
          <ViewMerchantDashboard />
        </Route>
        <Route path="/merchant/venue">
          <ViewMerchantVenue />
        </Route>
        <Route path="/merchant/services">
          <ViewMerchantServices />
        </Route>
        <Route path="/merchant/bookings">
          <ViewMerchantBookings />
        </Route>
        <Route path="/merchant/profile">
          <MerchantProfile />
        </Route>
        {/* 
        ************************************************** 
        ***** Planner Views
        **************************************************  
        */}
        <Route path="/planner/dashboard">
          <PlannerDashboard />
        </Route>
        <Route path="/planner/profile">
          <PlannerProfile />
        </Route>
        <Route path="/" exact>
          <Redirect to="/merchant/dashboard" />
        </Route>

      </Switch>
    </div>
  );
};

export default MainContent;
