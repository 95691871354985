import React from "react";
import RightArrowIcon from "../../../globals/icons/rightArrowIcon";
import "./button.scss";

const Button = (props) => {
  return (
    // @TODO diasbaled attribute needs to be added but is decided using logic
    // @TODO symbol span login for when buttons need arrows
    // @TODO form attirbute
    // @TODO tyep attribute
    // @TODO maybe value?
    <button className={props.className} name={props.name} onClick={props.onClick} disabled={props.disabled} value={props.value}>
      {props.children} {props.symbol === 'rightArrow' && <RightArrowIcon />} 
    </button>
  );
};

export default Button;
