import SplitseesLogo from "../../../globals/icons/splitseesLogo";
import { useContext } from "react";
import SplitseesButton from "../../ui/splitsees-button/splitsees-button";
import AuthContext from "../../../store/auth-context";

const FrontDoorForgotPasswordReset = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <div className="front-door__wrapper__content__wrapper front-door__forgot-password__reset">
      <SplitseesLogo color="splitsees-purple" className="front-door__logo" />
      <p className="front-door__copy--primary">
        Thanks for confirming your email address. A password reset code has been
        emailed to you. Please enter it below.
      </p>
      <fieldset>
        <div className="splitsees__inputWrapper">
          <input
            type="text"
            id="forgotPasswordResetCode"
            onChange={ctx.forgotPasswordResetCodeOnChange}
            placeholder="reset code"
          ></input>
        </div>
        <div className="splitsees__inputWrapper">
          <input
            type="password"
            id="forgotPasswordResetNewPassword"
            onChange={ctx.forgotPasswordResetPasswordOnChange}
            placeholder="new password"
          ></input>
        </div>
        <div className="splitsees__inputWrapper">
          <input
            type="password"
            id="forgotPasswordResetConfirmNewPassword"
            onChange={ctx.forgotPasswordResetPasswordConfirmOnChange}
            placeholder="re-type new password"
          ></input>
        </div>
      </fieldset>

      <SplitseesButton
        className="tall"
        onClick={ctx.forgotPasswordResetPasswordButonOnClick}
      >
        CONFIRM NEW PASSWORD
      </SplitseesButton>
    </div>
  );
};

export default FrontDoorForgotPasswordReset;
