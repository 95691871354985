import { React, useContext } from "react";
import { useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import AuthContext from "../../../store/auth-context";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};


const InvitationRsvpGoing = () => {
  const ctx = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();

  //@TODO Move all of this Stripe related stuff over to the state management at some point.
  //reduce logig inside components

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(ctx.invtationPaymentIntentIdSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
      ctx.rsvpPaymentError(result.error.message);

    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        ctx.rsvpPaymentSuccess();
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };
  return (
    <>
    
    
      
        <div id="invitations-rsvp-going__wrapper">
          
         { ctx.invitationPaid === false && <form id="invitations-rsvp-going__wrapper__form" onSubmit={handleSubmit}>
            <h1>Awesome!</h1>
            <span id="invitations-rsvp-going__wrapper__form__sub-header">We look forward to seeing you soon.<br/>Please pay below!</span>
              <span id="cardElement"><CardElement options={CARD_ELEMENT_OPTIONS} /></span>
         
            <button disabled={!stripe}>Confirm order</button>
          </form> }
          {ctx.invitationPaid === true && <h1>See You There!</h1>}
        </div>
        

    </>
  );
};

export default InvitationRsvpGoing;
