import MerchantVenueNavPills from "../../../ui/merchantVenueNavPills/merchantVenueNavPills";

const MerchantVenueAddDetailsPhotoUploadError = (props) => {
  return (
    <>
      <MerchantVenueNavPills />
      <h1>Photo Upload Erorr</h1>
    </>
  );
};

export default MerchantVenueAddDetailsPhotoUploadError;
