const SidebarEventsIcon = (props) => {
  return (
    <svg
      id="sidebarEventsIcon"
      className={props.className}
      viewBox="-15 -15 130 130"
    >
      <path d="M30.1,28.1c3.6,0,6.4-2.9,6.4-6.4V8.9c0-3.6-2.9-6.4-6.4-6.4s-6.4,2.9-6.4,6.4v12.7C23.7,25.2,26.5,28.1,30.1,28.1z"></path>
      <path d="M69.9,28.1c3.6,0,6.4-2.9,6.4-6.4V8.9c0-3.6-2.9-6.4-6.4-6.4c-3.6,0-6.4,2.9-6.4,6.4v12.7C63.5,25.2,66.3,28.1,69.9,28.1     z"></path>
      <path d="M89.9,15.3h-8.3v6.4c0,6.4-5.2,11.7-11.7,11.7c-6.4,0-11.7-5.2-11.7-11.7v-6.4H41.8v6.4c0,6.4-5.2,11.7-11.7,11.7     c-6.4,0-11.7-5.2-11.7-11.7v-6.4h-8.3c-3.9,0-7,3.2-7,7v68.1c0,3.9,3.1,7,7,7h79.9c3.9,0,7-3.2,7-7V22.3     C97,18.4,93.8,15.3,89.9,15.3z M68.4,57.1L50.1,75.4c-1,1-2.4,1.6-3.7,1.6c-1.3,0-2.7-0.5-3.7-1.6l-9.7-9.7     c-2.1-2.1-2.1-5.4,0-7.5c2.1-2,5.4-2,7.5,0l5.9,6l14.6-14.6c2.1-2.1,5.4-2.1,7.5,0C70.5,51.7,70.5,55,68.4,57.1z"></path>
    </svg>
  );
};

export default SidebarEventsIcon;
