/* eslint-disable no-eval */
import { React, useContext } from "react";
// import Button from "../../../ui/button/button";
import AuthContext from "../../../store/auth-context";

const TimeSelect = (props) => {
  const ctx = useContext(AuthContext);

  return (
    <>
      <div className="timeSelect__wrapper">
        <select
          id={`${props.day}-${props.period}-hour`}
          defaultValue=""
          disabled={eval(
            `!ctx.mechantVenueCreateVenueHoursOfOperation${
              props.day.charAt(0).toUpperCase() + props.day.slice(1)
            }Checkbox`
          )}
          onChange={ctx.merchantVenueCreateVenueHoursOfOperationHourOnChange}
        >
          <option value="" disabled>
            Hour
          </option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
        <select
          id={`${props.day}-${props.period}-minute`}
          defaultValue=""
          disabled={eval(
            `!ctx.mechantVenueCreateVenueHoursOfOperation${
              props.day.charAt(0).toUpperCase() + props.day.slice(1)
            }Checkbox`
          )}
          onChange={ctx.merchantVenueCreateVenueHoursOfOperationMinuteOnChange}
        >
          <option value="" disabled>
            Minutes
          </option>
          <option value="00">00</option>
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="45">45</option>
        </select>
        <select
          id={`${props.day}-${props.period}-ampm`}
          defaultValue=""
          disabled={eval(
            `!ctx.mechantVenueCreateVenueHoursOfOperation${
              props.day.charAt(0).toUpperCase() + props.day.slice(1)
            }Checkbox`
          )}
          onChange={ctx.merchantVenueCreateVenueHoursOfOperationAmpmOnChange}
        >
          <option value="">AM/PM</option>
          <option value="am">AM</option>
          <option value="pm">PM</option>
        </select>
      </div>
    </>
  );
};

export default TimeSelect;
