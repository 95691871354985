import { Switch, Route } from "react-router-dom";
import ProfileCard from "../../../ui/profile/profileCard"
// import ProfilePhotoUpload from "../merchantProfile/profilePhotoUpload";
import MerchantProfileAccount from "./merchantProfileAccount";
import MerchantProfileAccountBankInfo from "./merchantProfileAccountBankInfo";
import MerchantProfileAccountChangePassword from "./merchantProfileAccountChangePassword";
import MerchantProfileAccountManageVenue from "./merchantProfileAccountManageVenue";
import MerchantProfileAccountPersonalDetails from "./merchantProfileAccountPersonalDetails";
import MerchantProfileAccountPasswordReset from "./merchantProfileAccountResetPassword";
import MerchantProfileLegal from "./merchantProfileLegal";
import MerchantProfileNotifications from "./merchantProfileNotifications";
import MerchantProfilePhoto from "./merchantProfileProfilePhoto";

const MerchantProfile = props => {
  return (
    <div id="profile__wrapper">
      <ProfileCard userInfo={props.userInfo}/>
      <Switch>
        <Route path="/merchant/profile" exact>
        </Route>
        {/* <Route path="/merchant/profile/photo-upload">
          <ProfilePhotoUpload userInfo={props.userInfo} />
        </Route> @TODO - Part of the universal components for Merchant/Planner */}
        <Route path="/merchant/profile/account" exact>
          <MerchantProfileAccount />
        </Route>
        <Route path="/merchant/profile/account/manage-venue">
          <MerchantProfileAccountManageVenue />
        </Route>
        <Route path="/merchant/profile/account/banking-information">
          <MerchantProfileAccountBankInfo />
        </Route>
        <Route path="/merchant/profile/account/personal-details">
          <MerchantProfileAccountPersonalDetails />
        </Route>
        <Route path="/merchant/profile/account/change-password">
          <MerchantProfileAccountChangePassword />
        </Route>
        <Route path="/merchant/profile/notifications">
          <MerchantProfileNotifications />
        </Route>
        <Route path="/merchant/profile/legal">
          <MerchantProfileLegal />
        </Route>
        <Route path="/merchant/profile/profile-photo">
          <MerchantProfilePhoto />
        </Route>
        <Route path="/merchant/profile/password-reset">
          <MerchantProfileAccountPasswordReset />
        </Route>
      </Switch>
      
      
    </div>
  )
}

export default MerchantProfile;