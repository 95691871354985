// import BellIcon from "../../../globals/icons/bellIcon";
import LegalIcon from "../../../globals/icons/legalIcon";
import UserIcon from "../../../globals/icons/userIcon";
import { useContext } from "react";
import AuthContext from "../../../store/auth-context";

const ProfileCard = (props) => {
  const ctx = useContext(AuthContext);

  return (
    <div id="profileCard">
      <div id="profileCard__contentWrapper">
        <div
          id="profileCard__contentWrapper__profilePhoto"
          onClick={ctx.signedInUserProfilePhotoUploadLinkOnClick}
          style={{
            backgroundImage:
              "url(" +
              (ctx.signedInUserProfileImage !== null
                ? ctx.signedInUserProfileImage
                : "https://res.cloudinary.com/dpk0itkv7/image/upload/v1623212935/profile-placeholder-image_aeuavw.jpg") +
              ")",
          }}
        ></div>
        <span className="profileCard__contentWrapper__name">
          {ctx.signedInUserFirstName} {ctx.signedInUserLastName}
        </span>
        <ul id="profileCard__contentWrapper__linkList">
          <li onClick={ctx.profileCardAccountLinkOnClick}>
            <UserIcon
              className={
                ctx.profileCardActiveLink === "account" ? "active" : ""
              }
            ></UserIcon>{" "}
            Account
          </li>
          {/* <li onClick={ctx.profileCardNotificationsLinkOnClick}><BellIcon className={ctx.profileCardActiveLink === 'notifications' ? 'active': '' }></BellIcon> Notifications</li> */}
          <li onClick={ctx.profileCardLegalLinkOnClick}>
            <LegalIcon
              className={ctx.profileCardActiveLink === "legal" ? "active" : ""}
            ></LegalIcon>{" "}
            Legal
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileCard;
