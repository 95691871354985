import { Switch, Route } from "react-router-dom";
import { React } from "react";
import MerchantVenueAddDetailsPhotoUploadError from "./merchantVenueAddDetailsPhotoUploadError";
import MerchantVenueAddDetailsBankInfo from "./merchantVenueAddDetailsBankInformation";
import MerchantVenueAddDetailsHoursOfOperation from "./merchantVenueDetailsHoursOfOperation";
import MerchantVenueAddDetailsYourPage from "./merchantVenueAddDetailsYourPage";
import MerchantVenueListVenues from "./merchantVenueListVenues";
import MerchantVenueAddDetails from "./merchantVenueAddDetails";
// import AuthContext from "../../../../store/auth-context";

const ViewMerchantVenue = (props) => {
  // const ctx = useContext(AuthContext);



  return (
    <>
      {/* <MerchantVenueNavPills /> */}
      <div id="merchantVenue__wrapper">
        <Switch>
          <Route path="/merchant/venue" exact>
            <MerchantVenueListVenues />
            {/* <MerchantVenueAddDetails /> */}
            {/* <MerchantVenueAddDetailsHoursOfOperation /> */}
          </Route>
          <Route path="/merchant/venue/add-details" exact>
            <MerchantVenueAddDetails />
          </Route>
          <Route path="/merchant/venue/add-details/photo-upload-error">
            <MerchantVenueAddDetailsPhotoUploadError />
          </Route>
          <Route path="/merchant/venue/add-details/hours-of-operation">
            <MerchantVenueAddDetailsHoursOfOperation />
          </Route>
          <Route path="/merchant/venue/add-details/bank-information">
            <MerchantVenueAddDetailsBankInfo />
          </Route>
          <Route path="/merchant/venue/add-details/your-page">
            <MerchantVenueAddDetailsYourPage />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default ViewMerchantVenue;
