/* eslint-disable react-hooks/exhaustive-deps */
import { React, useContext, useEffect } from "react";
import AuthContext from "../../../../store/auth-context";
import Button from "../../../ui/button/button";

const MerchantServicesAddService = (props) => {
  const ctx = useContext(AuthContext);
  useEffect(() => {
    ctx.merchantVenueListVenues();
  }, []);
  return (
    <>
      <div id="merchantServiceAddDetails__wrapper">
        <h1>Add Service</h1>
        <div className="merchantServiceAddDetails__wrapper_inputWrapper">
          <label htmlFor="selectedVenue">Select Your Venue</label>
          <select
            id="selectedVenue"
            onChange={ctx.merchantServiceCreateServiceSelectedVenueOnChange}
            defaultValue=""
          >
            <option value="" disabled>Choose Your Venue</option>
            {
              ctx.merchantVenueListVenuesFilteredList.map((venue) => (
                <option key={venue.id} value={venue.id}>{venue.name}</option>
              ))
            }
          </select>
        </div>
        <div className="merchantServiceAddDetails__wrapper_inputWrapper">
          <label htmlFor="serviceImage">Add your image</label>
          <input
            type="file"
            id="serviceImage"
            onChange={ctx.merchantServiceCreateServiceImageInputOnChange}
          />
        </div>
        <div className="merchantServiceAddDetails__wrapper_inputWrapper">
          <label htmlFor="serviceName">Service Name</label>
          <input
            type="text"
            id="serviceName"
            onChange={ctx.merchantServiceCreateServiceNameInputOnChange}
          />
        </div>
        <div className="merchantServiceAddDetails__wrapper_inputWrapper">
          <label htmlFor="serviceQuota">Service Quota</label>
          <input
            type="number"
            id="serviceQuota"
            onChange={ctx.merchantServiceCreateServiceQuotaInputOnChange}
          />
        </div>
        <div className="merchantServiceAddDetails__wrapper_inputWrapper">
          <label htmlFor="minParticipants">Minumum Participants</label>
          <input
            type="number"
            id="minParticipants"
            onChange={
              ctx.merchantServiceCreateServiceMinParticipantsInputOnChange
            }
          />
        </div>
        <div className="merchantServiceAddDetails__wrapper_inputWrapper">
          <label htmlFor="maxParticipants">Maximum Participants</label>
          <input
            type="number"
            id="maxParticipants"
            onChange={
              ctx.merchantServiceCreateServiceMaxParticipantsInputOnChange
            }
          />
        </div>
        <div className="merchantServiceAddDetails__wrapper_inputWrapper">
          <label htmlFor="shortDescription">Short Description</label>
          <textarea
            rows="2"
            id="shortDescription"
            onChange={
              ctx.merchantServiceCreateServiceShortDescriptionInputOnChange
            }
          />
        </div>
        <div className="merchantServiceAddDetails__wrapper_inputWrapper">
          <label htmlFor="longDescription">Long Description</label>
          <textarea
            rows="5"
            id="longDescription"
            onChange={
              ctx.merchantServiceCreateServiceLongDescriptionInputOnChange
            }
          />
        </div>
        <div className="merchantServiceAddDetails__wrapper_inputWrapper">
          <div className="merchantServiceAddDetails__wrapper_inputWrapperDouble">
            <label htmlFor="servicePrice">Price ($)</label>
            <input
              type="number"
              id="servicePrice"
              onChange={ctx.merchantServiceCreateServicePriceInputOnChange}
            />
          </div>
          <div className="merchantServiceAddDetails__wrapper_inputWrapperDouble">
            <label htmlFor="perUnit">Per Unit</label>
            <select
              id="perUnit"
              defaultValue=""
              onChange={ctx.merchantServiceCreateServicePerUnitInputOnChange}
            >
              <option value="" disabled>
                Pick a Unit
              </option>
              <option value="perUnitPerson">Person</option>
              <option value="perUnitPackage">Package</option>
              <option value="perUnitHour">Hour</option>
              <option value="perUnitDay">Day</option>
            </select>
          </div>
        </div>
        <div className="merchantServiceAddDetails__wrapper_inputWrapper">
          <Button
            className="button-active"
            disabled={
              ctx.merchantServiceCreateServiceImageValidationStatus === false ||
              ctx.merchantServiceCreateServiceNameValidationStatus === false ||
              ctx.merchantServiceCreateServiceQuotaValidationStatus === false || 
              ctx.merchantServiceCreateServiceMinParticipantsValidationStatus === false ||
              ctx.merchantServiceCreateServiceMaxParticipantsValidationStatus === false ||
              ctx.merchantServiceCreateServiceShortDescriptionValidationStatus === false ||
              ctx.merchantServiceCreateServiceLongDescriptionValidationStatus === false ||
              ctx.merchantServiceCreateServicePriceValidationStatus === false || 
              ctx.merchantServiceCreateServicePerUnitValidationStatus === false ||
              ctx.merchantServiceCreateServiceSelectedVenueValidationStatus === false
            }
            onClick={ctx.merchantServiceCreateServiceSaveServiceButtonOnClick}
          >
            Save Service
          </Button>
        </div>
      </div>
    </>
  );
};

export default MerchantServicesAddService;
