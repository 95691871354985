import { useContext } from "react";
// import Button from "../../ui/button/button";
import AuthContext from "../../../store/auth-context";
import SplitseesLogo from "../../../globals/icons/splitseesLogo";
import SplitseesButton from "../../ui/splitsees-button/splitsees-button";

const FrontDoorVerify = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <div className="front-door__wrapper__content__wrapper front-door__register__verify">
      <SplitseesLogo
        color="splitsees-purple"
        className="front-door__logo"
      />
      <p className="front-door__copy--primary">Check your email to authorize your account!</p>
      <fieldset>
        <div className="splitsees__inputWrapper">
          <input
            type="text"
            id="registerVerifyCode"
            onChange={ctx.verifyCodeOnChange}
            placeholder="authorization code"
          ></input>
        </div>
        <span
          id="front-door__register__resend--link"
          className="front-door__links--small"
          onClick={ctx.resendOnClick}
        >
          Click here to resend your code
        </span>
        <div className="splitsees__inputWrapper">
          <input
            type="email"
            id="registerEmail"
            onChange={ctx.emailInputOnChange}
            value={ctx.registerEmail}
            placeholder="email address"
          ></input>
        </div>
      </fieldset>
      <SplitseesButton className="tall" onClick={ctx.launchOnClick}>
        AUTHORIZE ACCOUNT
      </SplitseesButton>
    </div>
  );
};

export default FrontDoorVerify;
