/* eslint-disable react-hooks/exhaustive-deps */
import { React, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import Button from "../../ui/button/button";
// import { loadGoogleScript } from "../../../globals/googleLogin";

const PublicVenue = (props) => {
  let { id } = useParams();
  const ctx = useContext(AuthContext);
  useEffect(() => {
    ctx.publicGetVenueList(id);
    ctx.googleAuth2ApiLoader();
  }, []);
  return (
    <>
      <div id="publicVenue__wrapper">
        <div
          id="publicVenue__wrapper__header"
          style={{
            backgroundImage: "url(" + ctx.publicVenueInfoBackgroundImage + ")",
          }}
        ></div>
        <div id="publicVenue__wrapper__body">
          <div id="publicVenue__wrapper__logo">
            <img alt="Venue Logo" src={ctx.publicVenueInfoLogo} />
          </div>

          <div id="publicVenue__wrapper__info">
            <h1>{ctx.publicVenueInfoName}</h1>
            <span id="publicVenue__wrapper__info__street0">
              {ctx.publicVenueInfoAddressStreet0}
            </span>
            <span id="publicVenue__wrapper__info__street1">
              {ctx.publicVenueInfoAddressStreet1}
            </span>
            <span id="publicVenue__wrapper__info__city">
              {ctx.publicVenueInfoAddressCity},&nbsp;
            </span>
            <span id="publicVenue__wrapper__info__state">
              {ctx.publicVenueInfoAddressState}&nbsp;
            </span>
            <span id="publicVenue__wrapper__info__ZipCode">
              {ctx.publicVenueInfoAddressZipCode}
            </span>
            <span id="publicVenue__wrapper__info__PhoneNumber">
              Phone: {ctx.publicVenueInfoAddressPhoneNumber}
            </span>
            <span id="publicVenue__wrapper__info__Website">
              <a
                href={ctx.publicVenueInfoWebsite}
                target="_blank"
                rel="noreferrer"
              >
                {ctx.publicVenueInfoWebsite}
              </a>
            </span>
          </div>
          <div id="publicVenue__wrapper__services">
            <h1>Services</h1>
            {ctx.publicVenueServiceList.map((service) => (
              <div
                className="publicVenue__wrapper__services__service"
                key={service.id}
              >
                <div
                  className="publicVenue__wrapper__services__service__header"
                  style={{
                    backgroundImage: "url(" + service.image_url + ")",
                  }}
                ></div>
                <div className="publicVenue__wrapper__services__service__body">
                  <h1 className="publicVenue__wrapper__services__service__body__serviceName">
                    {service.name}
                  </h1>
                  <p className="publicVenue__wrapper__services__service__body__shortDescription">
                    {service.description}
                  </p>
                  <p className="publicVenue__wrapper__services__service__body__price">
                    ${service.price} per unit
                  </p>
                  {ctx.isLoggedIn && (
                    <Button
                      value={service.id}
                      className="button-active"
                      onClick={ctx.publicVenueBookServiceButtonOnClick}
                    >
                      Book Event
                    </Button>
                  )}
                  {ctx.isLoggedIn === false && (
                    <>
                      <div id="publicVenue__button-wrapper">
                        <Button
                          className="button-active"
                          onClick={ctx.publicVenueSignInButtonOnClick}
                        >
                          Sign In To View
                        </Button>
                        <Button
                          className="button-active"
                          onClick={ctx.publicVenueRegisterButtonOnClick}
                        >
                          Register New User
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
          

          {ctx.publicVenueBookService.map((service) => (
            <div id="publicVenue__wrapper__services__book" key={service.id}>
              <h1>Booking</h1>
              <div className="publicVenue__wrapper__services__book__header">
                <div className="publicVenue__wrapper__services__book__header_logo">
                  <img src={service.image_url} alt="" />
                </div>
                <div className="publicVenue__wrapper__services__book__header_body">
                  <h2>{service.name}</h2>
                  <p>${service.price}/person</p>
                  <p>{service.information_content}</p>
                </div>
              </div>

              <h2>Choose Date</h2>
              <div className="publicVenue__wrapper__services__book__inputWrapper_month">
                <label htmlFor="month">Month</label>
                <select
                  id="month"
                  defaultValue=""
                  onChange={ctx.publicVenueMonthFieldOnChange}
                >
                  <option value="" disabled>
                    Select Month
                  </option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div className="publicVenue__wrapper__services__book__inputWrapper_day">
                <label htmlFor="day">Day</label>
                <select
                  id="day"
                  defaultValue=""
                  onChange={ctx.publicVenueDayFieldOnChange}
                >
                  <option value="" disabled>
                    Select Day
                  </option>
                  <option value="01">1</option>
                  <option value="02">2</option>
                  <option value="03">3</option>
                  <option value="04">4</option>
                  <option value="05">5</option>
                  <option value="06">6</option>
                  <option value="07">7</option>
                  <option value="08">8</option>
                  <option value="09">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                </select>
              </div>
              <div className="publicVenue__wrapper__services__book__inputWrapper_year">
                <label htmlFor="year">Year</label>
                <select
                  id="year"
                  defaultValue=""
                  onChange={ctx.publicVenueYearFieldOnChange}
                >
                  <option value="" disabled>
                    Select Year
                  </option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                </select>
              </div>
              <div className="publicVenue__wrapper__services__book__inputWrapper_startTime">
                <label htmlFor="time">Start Time</label>
                <select
                  id="time"
                  defaultValue=""
                  onChange={ctx.publicVenueStartTimeHourFieldOnChange}
                >
                  <option value="" disabled>
                    Hour
                  </option>
                  <option value="01">1</option>
                  <option value="02">2</option>
                  <option value="03">3</option>
                  <option value="04">4</option>
                  <option value="05">5</option>
                  <option value="06">6</option>
                  <option value="07">7</option>
                  <option value="08">8</option>
                  <option value="09">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
                <select
                  defaultValue=""
                  onChange={ctx.publicVenueStartTimeMinuteFieldOnChange}
                >
                  <option value="" disabled>
                    Minute
                  </option>
                  <option value="00">00</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                </select>
                <select
                  defaultValue=""
                  onChange={ctx.publicVenueStartTimeAmPmFieldOnChange}
                >
                  <option value="">AM/PM</option>
                  <option value="am">AM</option>
                  <option value="pm">PM</option>
                </select>
              </div>
              {/* <div className="publicVenue__wrapper__services__book__inputWrapper_endTime">
                <label htmlFor="time">End Time</label>
                <select
                  id="time"
                  defaultValue=""
                  onChange={ctx.publicVenueEndTimeHourFieldOnChange}
                >
                  <option value="" disabled>
                    Hour
                  </option>
                  <option value="01">1</option>
                  <option value="02">2</option>
                  <option value="03">3</option>
                  <option value="04">4</option>
                  <option value="05">5</option>
                  <option value="06">6</option>
                  <option value="07">7</option>
                  <option value="08">8</option>
                  <option value="09">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
                <select
                  defaultValue=""
                  onChange={ctx.publicVenueEndTimeMinuteFieldOnChange}
                >
                  <option value="" disabled>
                    Minute
                  </option>
                  <option value="00">00</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                </select>
                <select
                  defaultValue=""
                  onChange={ctx.publicVenueEndTimeAmPmFieldOnChange}
                >
                  <option value="">AM/PM</option>
                  <option value="am">AM</option>
                  <option value="pm">PM</option>
                </select>
              </div> */}

              <div className="publicVenue__wrapper_services__book__inviteGuestTitleWrapper">
                <h2>Invite Guests</h2>
                <Button
                  className="button-active"
                  onClick={ctx.publicVenueAddGuestButtonOnClick}
                >
                  Add Another Guest
                </Button>
                  {/* <Button
                    className="button-active"
                    onClick={ctx.publicVenueAddGuestButtonOnClick}
                  >
                    Google Contacts
                  </Button> */}
                  
                  {ctx.googleSignedIn === false && (
            
            <Button className="button-active" onClick={ctx.googleSignInButtonOnClick}>Google Sign In</Button>
          )}
          {/* {ctx.googleSignedIn !== false && (
            <div
              className="googleSignInOutButton"
              onClick={ctx.googleSignPutButtonOnClick}
            >
              Sign Out
            </div>
          )} */}
          {ctx.googleSignedIn !== false && (
            <Button 
              className="button-active"
              onClick={ctx.googleListContactsButtonOnClick}
            >
              Google Contacts
            </Button>
          )}
              </div>

              {ctx.publicVenueInviteList.map((guest) => (
                <div
                  key={guest.id}
                  className="publicVenue__wrapper__services__book__inviteGuestWrapper"
                >
                  <input
                    className={"firstName-" + guest.id}
                    type="text"
                    placeholder="First Name"
                    onChange={ctx.publicVenueGuestFirstNameFieldOnChange}
                    value={guest.firstName}
                  />
                  {/* <input
                    className={"lastName-" + guest.id}
                    type="text"
                    placeholder="Last Name"
                    onChange={ctx.publicVenueGuestLastNameFieldOnChange}
                  /> */}
                  <input
                    className={"email-" + guest.id}
                    type="email"
                    placeholder="Email Address"
                    onChange={ctx.publicVenueGuestEmailFieldOnChange}
                    value={guest.email}
                  ></input>
                  <Button
                    className="button-active"
                    value={guest.id}
                    onClick={ctx.publicVenueRemoveGuestButtonOnClick}
                    disabled={ctx.publicVenueInviteList.length <= 1}
                  >
                    Remove Guest
                  </Button>
                </div>
              ))}

              <Button
                className="button-active"
                disabled={
                  ctx.publicVenueBookingMonth === undefined ||
                  ctx.publicVenueBookingDay === undefined ||
                  ctx.publicVenueBookingStartTimeHour === undefined ||
                  ctx.publicVenueBookingStartTimeMinute === undefined ||
                  ctx.publicVenueBookingStartTimeAmPm === undefined ||
                  // ctx.publicVenueBookingEndTimeHour === undefined ||
                  // ctx.publicVenueBookingEndTimeMinute === undefined ||
                  // ctx.publicVenueBookingEndTimeAmPm === undefined ||
                  ctx.publicVenueInviteList[0].firstName === "" ||
                  // ctx.publicVenueInviteList[0].lastName === "" ||
                  ctx.publicVenueInviteList[0].email === ""
                }
                onClick={ctx.publicVenueCreateEventButtonOnClick}
              >
                Create Event
              </Button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PublicVenue;








