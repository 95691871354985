import { React, useContext } from "react";
import AuthContext from "../../../store/auth-context";
import Button from "../button/button";

const Modal = props => {
  const ctx = useContext(AuthContext);
  return (
    <>
      <div id="modal__wrapper">
        <div id="modal__wrapper_content">
          <span id="modal__wrapper_content_title">{ctx.modalTitle}</span>
          <span id="modal__wrapper_content_copy">{ctx.modalCopy}</span>
          <div id="modal__wrapper_conent_message-error">
            {ctx.modalMessage !== "" && 
              <p>{ctx.modalMessage}</p>
            }
            {ctx.modalError !== "" && 
              <p>{ctx.modalError}</p>
            }
          </div>
          <Button className="button-active" onClick={ctx.modalCloseButtonOnClick}>Close</Button>
        </div>
      </div>
    </>
  )
}

export default Modal;