import { useContext } from "react";
import SplitseesLogo from "../../../globals/icons/splitseesLogo";
// import {  useHistory } from 'react-router-dom';
import AuthContext from "../../../store/auth-context";

const Header = (props) => {
  const ctx = useContext(AuthContext);

  // const [profile, setProfile] = useState(false);
  // const history = useHistory();

  // const profileSettingsHandler = props => {
  //   setProfile(!profile);
  //   console.log(profile);
  //   history.push('/merchant/profile');
  // }

  return (
    <div id="header">
      <div className="header__logoWrapper">
        <SplitseesLogo />
      </div>
      <div className="header__profileIconWrapper">
        <div
          id="header__profileIconWrapper__icon"
          onClick={ctx.profileIconOnClick}
          style={{
            backgroundImage:
              "url(" +
              (ctx.signedInUserProfileImage !== null
                ? ctx.signedInUserProfileImage
                : "https://res.cloudinary.com/dpk0itkv7/image/upload/v1623212935/profile-placeholder-image_aeuavw.jpg") +
              ")",
          }}
        ></div>
        {/* <svg
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        onClick={ctx.profileIconOnClick}
      >
        <g
          id="Merchant-Dashboard-UI"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Merchant/-Profile-/-005-a--upload-profile-picture"
            transform="translate(-1120.000000, -7.000000)"
          >
            <g id="Profile-img" transform="translate(1120.000000, 7.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <circle
                stroke="#FFFFFF"
                strokeWidth="3"
                cx="20"
                cy="20"
                r="18.5"
              ></circle>
              <mask id="mask-4" fill="white">
                <use xlinkHref="#path-3"></use>
              </mask>
              <circle
                stroke="#FFFFFF"
                strokeWidth="3"
                cx="20"
                cy="20"
                r="18.5"
              ></circle>
            </g>
            <text
              id="M"
              fontFamily="Quicksand-Bold, Quicksand"
              fontSize="16"
              fontWeight="bold"
              fill="#FFFFFF"
            >
              <tspan x="1133" y="33">
                M
              </tspan>
            </text>
          </g>
        </g>
      </svg> */}
      </div>
    </div>
  );
};

export default Header;
