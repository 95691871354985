
import { useContext } from "react";
import Button from '../../ui/button/button';
import AuthContext from "../../../store/auth-context";


const LogIn = props => {
  const ctx = useContext(AuthContext);
  return (
    <>
    
    <div id="registerLogin__rightSide">
      <div id="registerLogin_rightSide__topLink">
          <span className="registerLogin_rightSide__topLink__copy">
            Don't have an account?
          </span>
          <span className="registerLogin_rightSide__topLink__link" onClick={ctx.signUpOnClick} >
            Sign Up
          </span>
      </div>
      <div id="registerLogin__rightSide__registerForm">
          <div id="registerLogin__rightSide__registerForm__copy">
            <h1>Sign In</h1>
            <p>Select your type of account...</p>
            <ul>
              <li
                className={
                  ctx.signInRole === "merchant"
                    ? "registerLogin__rightSide__registerForm__merchantPlanner__active"
                    : ""
                }
                onClick={ctx.signInMerchantPlannerOnClick}
              >
                Merchant
              </li>
              <li
                className={
                  ctx.signInRole === "planner"
                    ? "registerLogin__rightSide__registerForm__merchantPlanner__active"
                    : ""
                }
                onClick={ctx.signInMerchantPlannerOnClick}
              >
                Planner
              </li>
            </ul>
            
          </div>
          <div id="registerLogin__rightSide__registerForm__form">
          <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
            <label htmlFor="signInEmail">Email</label>
            <input type="email" id="signInEmail" onChange={ctx.signInEmailOnChange}></input>
          </div>
          <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
            <label htmlFor="signInPassword">Password</label>
            <input type="password" id="signInPassword" onChange={ctx.signInPasswordOnChange}></input>
            <span id="forgotPasswordLink" onClick={ctx.forgotPasswordLinkOnClick}>Forgot password?</span>
          </div>
          
          
         
            
          </div>
        </div>
      <div id="registerLogin__rightSide__registerForm__buttonWrapper">
        <Button className="button-active" symbol="rightArrow" onClick={ctx.signInButtonOnClick}>Sign In</Button>
        </div>
    </div>
    </>
  )
}

export default LogIn;