import RightTriangle from "../../../globals/icons/rightTriangle";

const SplitseesButton = (props) => {
  return (
    <button
      className={props.className}
      name={props.name}
      onClick={props.onClick}
      disabled={props.disabled}
      value={props.value}
    >
      <span>{props.children}</span><RightTriangle />
    </button>
  );
};

export default SplitseesButton;
