import { useContext } from "react";
import Button from "../../ui/button/button"
import AuthContext from "../../../store/auth-context";

const Verify = props => {
  const ctx = useContext(AuthContext);
  return (
    <div id="registerLogin__rightSide">
      <div id="registerLogin_rightSide__topLink">
          <span className="registerLogin_rightSide__topLink__copy">
            Have an account?
          </span>
          <span className="registerLogin_rightSide__topLink__link" onClick={ctx.signInTopLinkOnClick}>
            Sign in
          </span>
      </div>
      <div id="registerLogin__rightSide__registerForm">
          <div id="registerLogin__rightSide__registerForm__copy">
            <h1>Verify your e-mail</h1>
            <p>Please input below the code sent to your email.</p>
          </div>
          <div id="registerLogin__rightSide__registerForm__form">
          
            <input id="verifyInput" type="text" maxLength="5" onChange={ctx.verifyCodeOnChange}></input>
            <p>The code will expire in 60 seconds</p>

            <div className="input-wrapper">
              <label htmlFor="verifyEmailAddress">Email Address</label>
              <input id="verifyEmailAddress" type="email" onChange={ctx.emailInputOnChange} value={ctx.registerEmail} ></input>
            </div>

            
         
            
          </div>
        </div>
      <div id="registerLogin__rightSide__registerForm__buttonWrapper">
        <Button className="button-passive" onClick={ctx.resendOnClick}>Resend</Button>
        <Button className="button-active" onClick={ctx.launchOnClick}>Launch</Button>
        </div>
    </div>
  )
}

export default Verify;