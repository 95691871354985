import "./icons.scss";

const RightArrowIcon = (props) => {
  return (
    <svg id="rightArrowIcon" x="0px" y="0px" viewBox="0 0 100 100">
      <g>
        <g>
          <polygon points="60.3,12.8 52.9,20.1 77.6,44.8 2.5,44.8 2.5,55.2 77.6,55.2 52.9,79.9 60.3,87.2 97.5,50   "></polygon>
        </g>
      </g>
    </svg>
  );
};

export default RightArrowIcon;
