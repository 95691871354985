/* eslint-disable react-hooks/exhaustive-deps */
import { React, useContext, useEffect } from "react";
import { Route, useParams, Switch } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import SplitseesLogo from "../../../globals/icons/splitseesLogo";
// import Button from "../../ui/button/button";
// import RightArrowIcon from "../../../globals/icons/rightArrowIcon";
import InvitationDetails from "./invitationDetails";
import InvitationRsvp from "./invitationRsvp";
import InvitationRsvpNotGoing from "./invitationRsvpNotGoing";
import InvitationRsvpGoing from "./invitationRsvpGoing";

import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';


const Invitations = (props) => {
  let { id } = useParams();
  const ctx = useContext(AuthContext);
  // let stripePromise;
  
  useEffect(() => {
    ctx.getInvitationDeatilsHandler(id);
  }, [ctx.invitationFirstName === ""]);

  return (
    
      <>
      <div id="inivitations__header">
        <SplitseesLogo />
      </div>
      <Switch>
        <Route path="/invitations/:id/rsvp/not-going" exact>
          <InvitationRsvpNotGoing />
        </Route>
        <Route path="/invitations/:id/rsvp/going" exact>
          <Elements stripe={ctx.invtationPaymentIntentId}>
            <InvitationRsvpGoing />
          </Elements>
        </Route>
        <Route path="/invitations/:id/rsvp">
          <InvitationRsvp />
        </Route>
        <Route path="/invitations/:id">
          <InvitationDetails />
        </Route>
      </Switch></>
    
  );
};

export default Invitations;
