const SidebarVenueAccountIcon = (props) => {
  return (
    <svg
      id="sidebarVenueIcon"
      className={props.className}
      viewBox="0 0 847 847"
      x="0px"
      y="0px"

    >
      <g>
        <path
          d="M771 686l-695 0c-28,0 -28,-42 0,-42l695 0c27,0 27,42 0,42zm21 -356l0 250c0,11 -9,21 -21,21l-695 0c-12,0 -21,-10 -21,-21l0 -250c0,-15 14,-25 28,-20l157 57 167 -199c8,-9 24,-9 32,0l167 199 158 -57c13,-5 28,5 28,20zm-375 -113l-163 193 -145 -52c-14,-5 -21,16 -7,21l152 55c4,1 9,0 12,-4l168 -199c9,-11 -8,-26 -17,-14z"
        ></path>
      </g>
    </svg>
  );
};

export default SidebarVenueAccountIcon;
