import {React,useContext } from 'react';
import Button from '../../../ui/button/button';
import AuthContext from "../../../../store/auth-context";

const MerchantProfilePhoto = props => {
  const ctx = useContext(AuthContext);
  return (
    <>
      <div id="merchantProfile__profilePhoto__wrapper">
        <h1>Upload your profile picture</h1>
        <input type="file" onChange={ctx.signedInUserProfilePhotoUploadFileOnChange} />
        <Button className="button-active" onClick={ctx.signedInUserProfilePhotoUploadFileButtonOnClick}>Upload</Button>
      </div>
      
    </>
  )
}

export default MerchantProfilePhoto;