import EyeIcon from "../../../../globals/icons/eyeIcon";

const MerchantVenuesListVenuesLister = (props) => {
  const convertWeekdayIdToDay = id => {
    switch (id) {
      case 0: 
        return "Sunday"
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5: 
        return "Friday"
      case 6: 
        return "Saturday"
      default:
        return "";
    }
  }
  return (
    <>
   
        {props.venues.map((venue) => (
          <div
            className="venue__wrapper"
            key={venue.id}
            // style={{ backgroundImage: "url(" + venue.background_image + ")" }}
          >
            <div className="venue__wrapper__content">
              {/* <div className="venue__wrapper__content__logo__view-icon">

              </div> */}
              <a href={`/venue/${venue.id}`} target="_blank" rel="noopener noreferrer"><EyeIcon /></a>
              <div className="venue__wrapper__content__logo">
                <img
                  className="venue__wrapper__content__logoImage"
                  src={venue.logo}
                  alt=""
                />
              </div>
              <div className="venue__wrapper__content__nameAddress">
                <span className="venue__wrapper__content__nameAddress__name">
                  {venue.name}
                </span>
                <span className="venue__wrapper__content__nameAddress__address">
                  {venue.address_1}
                  <br />
                  {venue.city}
                  <br />
                  {venue.state_abbreviation} {venue.zipcode}
                </span>
              </div>
              <div className="venue__wrapper__content__hours">
                {venue.operation_hours.map((hours) => (
                  <>
                  <span className="venue__wrapper__content__hours__weekday">
                    { convertWeekdayIdToDay(hours.weekday_id)}
                  </span>
                  <span className="venue__wrapper__content__hours__time__start">{hours.start_time} to</span>
                  <span className="venue__wrapper__content__hours__time__end">{hours.end_time}</span>
                  </>
                ))}
              </div>
              <div className="venue__wrapper__content__website">
                { console.log(venue.website)}
                <a className="venue__wrapper__content__website__link" href={venue.website} target="_blank" rel="noreferrer">{venue.website}</a>
              </div>
            </div>
          </div>
        ))}
      
    </>
  );
};

export default MerchantVenuesListVenuesLister;
