/* eslint-disable react-hooks/exhaustive-deps */
import { React, useContext, useEffect } from "react";
import AuthContext from "../../../../store/auth-context";
import MerchantVenuesListVenuesLister from "./merchantVenuesListVenuesLister";
import Button from "../../../ui/button/button";

const MerchantVenueListVenues = (props) => {
  const ctx = useContext(AuthContext);
  useEffect(() => {
    ctx.merchantVenueListVenues();
  }, []);
  
  return (
    <>
      <h1>My Venues</h1>

      {ctx.merchantVenueListVenuesZeroLengthFlag === true && (
        <>
          <div>
            <h1>Looks like you don't have any venues yet</h1>
            <Button className="button-active" onClick={ctx.merchantVenueCreateVenueButtonOnClick}>Create Venue</Button>
          </div>
        </>
      )}
      {ctx.merchantVenueListVenuesZeroLengthFlag !== true && 
        <>
        <MerchantVenuesListVenuesLister venues={ctx.merchantVenueListVenuesFilteredList} />
        <Button className="button-active" onClick={ctx.merchantVenueCreateVenueButtonOnClick}>Create Venue</Button>
        </>
      }
    </>
  );
};

export default MerchantVenueListVenues;
