import {React, useContext } from 'react';
import Button from '../../../ui/button/button';
import AuthContext from "../../../../store/auth-context";


const MerchantProfileLegal = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <>
      {/* <ProfileCard userInfo={props.userInfo}/> */}
      <div id="merchantProfile__legal__wrapper">
        <h1>Privacy policy</h1>
        <p id="merchantProfile__legal__wrapper__privacyPolicy">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel est
          ultrices, ultricies leo eget, molestie lorem. Aenean finibus venenatis
          dolor, sed mattis turpis lacinia in. Vivamus id leo consequat,
          scelerisque lectus vel, lobortis mi. Integer dictum leo in malesuada
          tincidunt. Praesent hendrerit finibus malesuada. Mauris dignissim
          felis at nibh mollis tempus. Morbi euismod malesuada ligula at mattis.
          Proin non volutpat tellus. Aliquam porttitor lorem quis posuere
          convallis. Duis in faucibus metus. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Integer convallis, est ac cursus
          sagittis, arcu nulla pretium est, ut suscipit erat arcu non ipsum.
          Nunc non magna nunc. Quisque a magna egestas, condimentum nulla vitae,
          accumsan metus. Pellentesque lobortis feugiat sollicitudin. Mauris sit
          amet cursus massa, et maximus sapien. Curabitur ac imperdiet orci.
          Morbi convallis massa vitae eros porta finibus. Quisque eget erat
          pellentesque, feugiat justo ac, convallis ex. Sed placerat sem id elit
          ullamcorper lacinia. Praesent nisl sem, fermentum id ornare ac,
          egestas in sem. Maecenas lacinia sit amet odio a aliquam. Interdum et
          malesuada fames ac ante ipsum primis in faucibus. Donec suscipit velit
          non nisi sodales luctus. Cras vehicula tempor ligula facilisis
          scelerisque. Sed posuere nisl et mi vestibulum lobortis. Duis
          convallis tellus vel ante feugiat ultrices vel vitae massa. In
          feugiat, ligula sed viverra viverra, tortor purus faucibus nunc, non
          dictum mi libero eu sem. Sed pulvinar lacus sit amet commodo semper.
          Mauris quis pretium erat. Nulla interdum quis purus ut placerat. In at
          ex nec quam elementum luctus. Integer nec sodales mauris, et mattis
          lectus. Suspendisse ultricies venenatis leo ac vehicula. Curabitur ac
          dapibus odio. Nam sit amet lectus ut enim condimentum consequat. Sed
          nunc neque, consectetur eu pretium eget, venenatis nec orci. Donec
          luctus elit vel enim imperdiet tristique vel eu lorem. Duis efficitur
          urna sed enim sollicitudin, sed pretium libero pharetra. Nunc non
          convallis lorem. In ut ornare dui. Fusce aliquet eu tellus sit amet
          placerat. Phasellus nec vulputate lacus, in rutrum mi. Curabitur at
          tellus tincidunt risus bibendum ullamcorper. Praesent suscipit in
          augue in efficitur. Maecenas laoreet mattis orci sed vehicula. Integer
          viverra congue diam et cursus. Pellentesque quam enim, rutrum quis
          velit in, consectetur tempor leo. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos. Proin ut
          tristique odio, ut pulvinar augue. Vestibulum sapien turpis, mattis in
          risus vel, malesuada rhoncus leo.</p>
          <Button className="button-active" onClick={ctx.signedInUserLegalCloseButtonOnClick}>Close</Button>

      </div>
    </>
  );
};

export default MerchantProfileLegal;
