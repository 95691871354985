import React from 'react';
import './merchantVenueNavPills.scss';

const MerchantVenueNavPills = props => {
  return (
    <div className="merchant__venue__navPills-wrapper">
      <ul>
        <li  className="active"><span>Venue</span></li>
        <li ><span>Your Page</span></li>
      </ul>
    </div>
  );
};

export default MerchantVenueNavPills;