import {React,useContext} from 'react';
import Button from '../../../ui/button/button';
import AuthContext from "../../../../store/auth-context";



const MerchantProfileAccount = (props) => {
  const ctx = useContext(AuthContext);

  return (
    <>
      <div id="merchantProfile__account__wrapper">
        <h1>Venue</h1>
        <p className="merchantProfile__account__wrapper__copy">
          Need to adjust your business hours due to Covid-19? Select “Manage
          Venue’” in your venue section to update.
        </p>
        <h2>Payment Information <span className="merchantProfile__account__wrapper__edit-sub-copy" onClick={ctx.signedInUserAccountBankInformationEditOnClick}>Edit</span></h2>
        {/* <label htmlFor="payment">Bank of America</label>
        <input id="payment" disabled={ctx.signedInUserAccountBankInformationEditStatus}></input>
        <p className="merchantProfile__account__wrapper__subcopy">Direct Deposit</p> */}
        <h2>Personal Details <span className="merchantProfile__account__wrapper__edit-sub-copy" onClick={ctx.signedInUserAccountPersonalDetailsEditOnClick}>Edit</span></h2>
        {/* <label htmlFor="firstName">Name</label>
        <input id="firstName" value={ctx.signedInUserFirstName} disabled></input><br />
        <label htmlFor="lastName">Name</label>
        <input id="lastName" value={ctx.signedInUserLastName} disabled></input><br /> */}
        
        <h2>Password <span className="merchantProfile__account__wrapper__edit-sub-copy" onClick={ctx.signedInUserAccountPasswordResetOnClick}>Edit</span></h2>
        {/* <input id="password" type="text" placeholder="**********" disabled></input> */}
        <Button className="button-active" onClick={ctx.signedInUserAccountCloseButtonOnClick}>Close</Button>
      </div>
    </>
  );
};

export default MerchantProfileAccount;
