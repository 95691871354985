const SidebarBookingsIcon = (props) => {
  return (
    <svg
      id="sidebarBookingsIcon"
      className={props.className}
      x="0px"
      y="0px"
      viewBox="0 0 91 91">
      <g>
        <rect x="30.9" y="18.5" width="3.4" height="4"></rect>
        <rect x="45.1" y="18.5" width="3.4" height="4"></rect>
        <rect x="59.3" y="18.5" width="3.4" height="4"></rect>
        <path d="M21.3,72.8h44.5c3.6,0,6.5-2.9,6.5-6.5V36.5H21.3V72.8z M43.7,50.5l3-9.3l3,9.3h8.9l-7.2,5.1l2.9,8.8L46.8,59l-7.6,5.4   l2.9-8.8l-7.2-5.1H43.7z"></path>
        <polygon points="21.3,33.1 72.2,33.1 72.2,22.5 62.7,22.5 62.7,28.7 59.3,28.7 59.3,22.5 48.5,22.5 48.5,28.7 45.1,28.7 45.1,22.5    34.3,22.5 34.3,28.7 30.9,28.7 30.9,22.5 21.3,22.5  "></polygon>
      </g>
    </svg>
  );
};

export default SidebarBookingsIcon;