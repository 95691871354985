import "./icons.scss";

const UserIcon = (props) => {
  return (
    <svg
      id="userIcon"
      className={props.className}
      viewBox="0 0 128 128"
      x="0px"
      y="0px"
    >
      <g>
        <circle cx="64" cy="39" r="25"></circle>
        <path d="M74,74H54a40,40,0,0,0-40,40H114A40,40,0,0,0,74,74Z"></path>
      </g>
    </svg>
  );
};

export default UserIcon;
