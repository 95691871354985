import { React, useContext } from "react";
// import { Route, useParams, Switch } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
// import SplitseesLogo from "../../../globals/icons/splitseesLogo";
import Button from "../../ui/button/button";
import RightArrowIcon from "../../../globals/icons/rightArrowIcon";

const InvitationDetails = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <>
      <div id="invitations__wrapper">
        <span id="invitations__wrapper__first-name">
          {ctx.invitationFirstName},
        </span>
        <span id="invitations__wrapper__sub-header">
          You have been invited to {ctx.invitationServiceName}.{" "}
          <span>RSVP</span> Below!
        </span>
        <div id="invitations__wrapper__event-details">
          <div className="invitations__wrapper__event-details__detail-wrapper">
            <span className="invitations__wrapper__event-details__detail-wrapper__label">
              Event
            </span>
            <span className="invitations__wrapper__event-details__detail-wrapper__detail">
              {ctx.invitationServiceName}
            </span>
          </div>
          <div className="invitations__wrapper__event-details__detail-wrapper">
            <span className="invitations__wrapper__event-details__detail-wrapper__label">
              Date
            </span>
            <span className="invitations__wrapper__event-details__detail-wrapper__detail">
              {ctx.invitationEventDate}
            </span>
          </div>
          <div className="invitations__wrapper__event-details__detail-wrapper">
            <span className="invitations__wrapper__event-details__detail-wrapper__label">
              Time
            </span>
            <span className="invitations__wrapper__event-details__detail-wrapper__detail">
              {ctx.invitationEventStartTime} to {ctx.invitationEventEndTime}
            </span>
          </div>
          <div className="invitations__wrapper__event-details__detail-wrapper">
            <span className="invitations__wrapper__event-details__detail-wrapper__label">
              Venue
            </span>
          </div>
        </div>
        <div id="invitations__wrapper__event-image">
          <img src={ctx.invitationServiceImage} alt={ctx.invitationFirstName} />
        </div>
        <div id="invitations__wrapper__footer">
          <div className="invitations__wrapper__footer__detail-wrapper">
            <span className="invitations__wrapper__footer__detail-wrapper__label">
              Price
            </span>
            <span className="invitations__wrapper__footer__detail-wrapper__detail">
              <span>$</span>
              {ctx.invtationServicePrice}
              <span>/person</span>
            </span>
          </div>
          <Button className="button-active" onClick={ctx.InvitationDetailsRsvpButtonOnClick}>
            RSVP <RightArrowIcon />
          </Button>
        </div>
      </div>
    </>
  );
};

export default InvitationDetails;
