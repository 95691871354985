import { useContext } from "react";
import Button from "../../ui/button/button";
import AuthContext from "../../../store/auth-context";

const Register = (props) => {
  const ctx = useContext(AuthContext);

  return (
    <>
      <div id="registerLogin__rightSide">
        <div id="registerLogin_rightSide__topLink">
          <span className="registerLogin_rightSide__topLink__copy">
            Have an account?
          </span>
          <span className="registerLogin_rightSide__topLink__link" onClick={ctx.signInTopLinkOnClick}>
            Sign in
          </span>
        </div>
        <div id="registerLogin__rightSide__registerForm">
          <div id="registerLogin__rightSide__registerForm__copy">
            <h1>Register</h1>
            <p>Let's create an account.</p>
            <ul>
              <li
                className={
                  ctx.registerRole === "merchant"
                    ? "registerLogin__rightSide__registerForm__merchantPlanner__active"
                    : ""
                }
                onClick={ctx.merchantPlannerOnClick}
              >
                Merchant
              </li>
              <li
                className={
                  ctx.registerRole === "planner"
                    ? "registerLogin__rightSide__registerForm__merchantPlanner__active"
                    : ""
                }
                onClick={ctx.merchantPlannerOnClick}
              >
                Planner
              </li>
            </ul>
          </div>
          <div id="registerLogin__rightSide__registerForm__form">
            <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
              <label htmlFor="registerEmail">Email *</label>
              <input
                type="email"
                id="registerEmail"
                onChange={ctx.emailInputOnChange}
              ></input>
            </div>
            <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
              <label htmlFor="registerFirstName">First Name *</label>
              <input
                type="text"
                id="registerFirstName"
                onChange={ctx.firstNameInputOnChange}
              ></input>
            </div>
            <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
              <label htmlFor="registerFirstName">Last Name *</label>
              <input
                type="text"
                id="registerLastName"
                onChange={ctx.lastNameInputOnChange}
              ></input>
            </div>
            <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
              <label htmlFor="registerPassword">Password *</label>
              <input
                type="password"
                id="registerPassword"
                onChange={ctx.passwordInputOnChnage}
              ></input>
            </div>
            <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
              <label htmlFor="registerVerifyPassword">Verify Password *</label>
              <input
                type="password"
                id="registerVerifyPassword"
                onChange={ctx.passwordVerifyOnChange}
              ></input>
            </div>
          </div>
        </div>
        <div id="registerLogin__rightSide__registerForm__buttonWrapper">
          <Button
            className="button-active"
            symbol="rightArrow"
            onClick={ctx.onRegister}
          >
            Register
          </Button>
        </div>
      </div>
    </>
  );
};

export default Register;
