import SplitseesLogo from "../../../globals/icons/splitseesLogo";
import { useContext } from "react";
import SplitseesButton from "../../ui/splitsees-button/splitsees-button";
import AuthContext from "../../../store/auth-context";

const FrontDoorLogin = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <div className="front-door__wrapper__content__wrapper front-door__login">
      <SplitseesLogo color="splitsees-purple" className="front-door__logo" />
      <div className="front-door__role-selection">
        <span>Sign in as:</span>
        <div className="splitsees__inputWrapper">
          <input
            type="radio"
            id="loginRoleMerchant"
            value="merchant"
            name="loginRole"
            onChange={ctx.signInMerchantPlannerOnClick}

          ></input>
          <label htmlFor="loignRoleMerchant">Merchant</label>
        </div>
        <div className="splitsees__inputWrapper">
          <input
            type="radio"
            id="loginRolePlanner"
            name="loginRole"
            value="planner"
            onChange={ctx.signInMerchantPlannerOnClick}
          ></input>
          <label htmlFor="loginRolePlanner">Planner</label>
        </div>
      </div>
      <fieldset>
        <div className="splitsees__inputWrapper">
          <input
            type="email"
            id="loginEmail"
            onChange={ctx.signInEmailOnChange}
            placeholder="email address"
          ></input>
        </div>
        <div className="splitsees__inputWrapper">
          <input
            type="password"
            id="loginPassword"
            onChange={ctx.signInPasswordOnChange}
            placeholder="password"
          ></input>
        </div>
        <span
        id="front-door__login__forgot-password--link"
        className="front-door__links--small"
        onClick={ctx.forgotPasswordLinkOnClick}
      >
        forgot password?
      </span>
      </fieldset>
      
      <SplitseesButton className="tall" onClick={ctx.signInButtonOnClick}>
        Log In
      </SplitseesButton>
      <span
        id="front-door__login__sign-up--link"
        className="front-door__links--small"
        onClick={ctx.signUpOnClick}
      >
        or sign up!
      </span>
    </div>
  );
};

export default FrontDoorLogin;
