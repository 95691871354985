import { useContext } from "react";
import Button from '../../ui/button/button';
import AuthContext from "../../../store/auth-context";

const ForgotPassword = props => {
  const ctx = useContext(AuthContext);
  return (
    <div id="registerLogin__rightSide">
      <div id="registerLogin_rightSide__topLink">
          <span className="registerLogin_rightSide__topLink__copy">
            Don't have an account?
          </span>
          <span className="registerLogin_rightSide__topLink__link" onClick={ctx.signUpOnClick} >
            Sign Up
          </span>
      </div>
      <div id="registerLogin__rightSide__registerForm">
          <div id="registerLogin__rightSide__registerForm__copy">
            <h1>Forgot Password</h1>
            <p>Enter the email address tied to your account and we will remind you!</p>
          </div>
          <div id="registerLogin__rightSide__registerForm__form">
            <div className="registerLogin__rightSide__registerForm__form__inputWrapper">
              <label htmlFor="forgotPasswordEmail">Email</label>
              <input type="email" id="forgotPasswordEmail" onChange={ctx.forgotPasswordEmailOnChange}></input>
            </div>
          </div>
        </div>
      <div id="registerLogin__rightSide__registerForm__buttonWrapper">
        <Button className="button-active" symbol="rightArrow" onClick={ctx.forgotPasswordButtonOnClick}>Send</Button>
        </div>
    </div>
  )
}

export default ForgotPassword;