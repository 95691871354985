import {React,useContext} from 'react';
import Button from '../../../ui/button/button';
import AuthContext from "../../../../store/auth-context";

const PlannerProfileAccount = props => {
  const ctx = useContext(AuthContext);

  return (
    <>
      <div id="merchantProfile__account__wrapper">
        <h1>Account</h1>
        <h2>Personal Details <span className="merchantProfile__account__wrapper__edit-sub-copy" onClick={ctx.signedInUserAccountPersonalDetailsEditOnClick}>Edit</span></h2>
        <h2>Password <span className="merchantProfile__account__wrapper__edit-sub-copy" onClick={ctx.signedInUserAccountPasswordResetOnClick}>Edit</span></h2>
        <Button className="button-active" onClick={ctx.signedInUserAccountCloseButtonOnClick}>Close</Button>

      </div>
    </>
  )
}

export default PlannerProfileAccount;