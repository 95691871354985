const PublicVenueBooking = props => {
  return (
    <>
      <div id="publicVenue__service__wrapper">
        <h1>Yas</h1>
      </div>
    </>
  )
}

export default PublicVenueBooking;