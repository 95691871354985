import { Route, Switch } from "react-router";
import MerchantServicesAddService from "./merchantServiceAddService";
import MerchantServicesAddServiceBasicInfo from "./merchantServicesAddServiceBasicInfo";
import MerchantServicesAddServiceDescription from "./merchantServicesAddServiceDescription";
import MerchantServicesAddServiceLaunch from "./merchantServicesAddServiceLaunch";
import MerchantServicesAddServicePrice from "./merchantServicesAddServicePrice";
import MerchantServicesList from "./merchantServicesList";

const ViewMerchantServices = (props) => {
  return (
    <>
      <div id="merchantServices__wrapper">
        <Switch>
          <Route path="/merchant/services" exact>
            <MerchantServicesList />
          </Route>
          <Route path="/merchant/services/add-service" exact>
            <MerchantServicesAddService />
          </Route>
          <Route path="/merchant/services/add-service/basic-info">
            <MerchantServicesAddServiceBasicInfo />
          </Route>
          <Route path="/merchant/services/add-service/description">
            <MerchantServicesAddServiceDescription />
          </Route>
          <Route path="/merchant/services/add-service/price">
            <MerchantServicesAddServicePrice />
          </Route>
          <Route path="/merchant/services/add-service/launch">
            <MerchantServicesAddServiceLaunch />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default ViewMerchantServices;
