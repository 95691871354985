import ProfileCard from "../../../ui/profile/profileCard";

const MerchantProfileAccountChangePassword = props => {
  return (
    <>
      <ProfileCard />
      <div id="merchantProfileAccount__changePassword__wrapper">
        <h1>Change your password</h1>
      </div>
    </>
  )
}

export default MerchantProfileAccountChangePassword;