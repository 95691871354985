import { React } from "react";
// import AuthContext from "../../../store/auth-context";

const InvitationRsvpNotGoing = () => {
  return (
    <div id="invitations-rsvp-notGoing__wrapper">
      <h1>You Will Be Missed!</h1>
      <span id="invitations-rsvp-notGoing__wrapper__form__sub-header">
        So sorry to hear that you won’t join us, but we totally understand!
      </span>
    </div>
  );
};

export default InvitationRsvpNotGoing;
