import { React, useContext } from "react";
import Button from "../../../ui/button/button";
import AuthContext from "../../../../store/auth-context";

const PlannerProfileAccountPersonalDetails = (props) => {
  const ctx = useContext(AuthContext);

  return (
    <>
      <div id="merchantProfileAccount__personalDetails__wrapper">
        <h1>Edit your personal details</h1>
        {/* @TODO FORM VALIDATION - at the very least input types, later add in some regex patterns and button disable */}
        <div className="merchantProfileAccount__personalDetails__inputWrapper">
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            onChange={
              ctx.signedInUserAccountPersonalDetailsFirstNameFieldOnChange
            }
            placeholder={ctx.signedInUserFirstName}
          />
        </div>
        <div className="merchantProfileAccount__personalDetails__inputWrapper">
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            onChange={
              ctx.signedInUserAccountPersonalDetailsLastNameFieldOnChange
            }
            placeholder={ctx.signedInUserLastName}
          />
        </div>

        {/* <div className="merchantProfileAccount__personalDetails__inputWrapper">
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            onChange={ctx.signedInUserAccountPersonalDetailsEmailFieldOnChange}
            value={ctx.signedInUserAccountPersonalDetailsEmail}
          />
        </div> */}

        {/* <div className="merchantProfileAccount__personalDetails__inputWrapper">
          <label htmlFor="addess">Address</label>
          <input
            id="address"
            onChange={
              ctx.signedInUserAccountPersonalDetailsAddressFieldOnChange
            }
            value={ctx.signedInUserAccountPersonalDetailsAddress}
          />
        </div> */}

        <div className="merchantProfileAccount__personalDetails__inputWrapper">
          <label htmlFor="city">City</label>
          <input
            id="city"
            onChange={ctx.signedInUserAccountPersonalDetailsCityFieldOnChange}
            placeholder={ctx.signedInUserCity}
          />
        </div>

        {/* <div className="merchantProfileAccount__personalDetails__inputWrapper">
          <label htmlFor="state">State</label>
          <select
            id="state"
            onChange={ctx.signedInUserAccountPersonalDetailsStateFieldOnChange}
          >
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div> */}

        <div className="merchantProfileAccount__personalDetails__inputWrapper">
          <label htmlFor="zipCode">Zip Code</label>
          <input
            id="zipCode"
            onChange={ctx.signedInUserAccountPersonalDetilsZipCodeFieldChange}
            placeholder={ctx.signedInUserZipCode}
          />
        </div>

        <Button
          className="button-active"
          onClick={ctx.signedInUserAccountPersonalDetailsSaveButtonOnClick}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default PlannerProfileAccountPersonalDetails;
