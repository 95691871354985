import { React, useContext } from "react";
import "./App.scss";
import Sidebar from "./components/ui/sidebar/sidebar";
import RegisterLoginWrapper from "./components/views/registerLogin/registerLoginWrapper";
import AuthContext from "./store/auth-context";
import Header from "./components/ui/header/header";
import MainContent from "./components/ui/mainContent/mainContent";
import Modal from "./components/ui/modal/modal";
import { useRouteMatch } from "react-router";
import { Switch, Route } from "react-router-dom";
import Invitations from "./components/views/invitation/invitation";
import FrontDoorWrapper from "./components/views/frontDoor/frontDoorWrapper";



function App() {
  const ctx = useContext(AuthContext);
  let match = useRouteMatch({
    path: "/venue/*",
    strict: false,
    sensitive: true
  })
  let inviteUrl = useRouteMatch({
    path: "/invitations/*",
    strict: false,
    sensitive: true
  })

  // console.log(inviteUrl);
  // console.log(match);

  return (
    <>
      {ctx.modalDisplay === true && <Modal />}
      
      {((ctx.isLoggedIn === false) && (inviteUrl !== null)) && (
        <>
      
          <Switch>
            <Route path="/invitations/:id">
              <Invitations />
            </Route>
          </Switch> 
  
        </>
      )}
      {((ctx.isLoggedIn === false) && (inviteUrl === null)) && (
        <>
          {/* <RegisterLoginWrapper /> */}
          <FrontDoorWrapper />

        </>
      )}
      {((ctx.isLoggedIn === true) && (match === null)) && (
        <>
          <Header />
          <main>
            <Sidebar />
            <MainContent />
          </main>
        </>
      )}
      {((ctx.isLoggedIn === true) && (match !== null)) && (
        <>

        <RegisterLoginWrapper />
        </>
      )}
      

    </>
  );
}

export default App;
