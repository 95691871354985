const RightChevronIcon = (props) => {
  return (
    <svg
      id="rightChevronIcon"
      className={props.className}
      // height="100px"
      // width="100px"
      // fill="#000000"
      // xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      // version="1.1"
      // x="0px"
      // y="0px"
      viewBox="0 0 100 100"
      // enable-background="new 0 0 100 100"
      // xml:space="preserve"
    >

          <path id="rightChevronIconPath" d="M25.894,14.811C39.22,28.137,52,41.464,66,54.79c0-2.829,0-5.657,0-8.485C53,59.266,39.95,72.228,26.988,85.189    c-5.477,5.477,2.946,13.961,8.422,8.485C48.373,80.713,61.303,67.751,74.265,54.79c2.312-2.312,2.295-6.174-0.016-8.485    C60.922,32.979,47.65,19.652,34.323,6.326C28.847,0.85,20.417,9.334,25.894,14.811L25.894,14.811z"></path>

    </svg>
  );
};

export default RightChevronIcon;
