import { Switch, Route, Redirect } from "react-router-dom";
import FrontDoorForgotPassword from "./frontDoorForgotPassord";
import FrontDoorForgotPasswordReset from "./frontDoorForgotPasswordReset";
import FrontDoorLogin from "./frontDoorLogIn";
import FrontDoorRegister from "./frontDoorRegister";
import FrontDoorVerify from "./frontDoorVerify";
// import SplitseesLogo from "../../../globals/icons/splitseesLogo";
// import ForgotPassword from "./forgotPassword";
// import ForgotPasswordReset from "./forgotPasswordReset";
// import LogIn from "./logIn";
// import Register from "./register";
// import Verify from "./verify";
import PublicVenue from "../publicVenue/publicVenue";
import PublicVenueBooking from "../publicVenue/publicVenueBooking";

const FrontDoorWrapper = (props) => {
  return (
    <div className="front-door__wrapper">
      <Switch>
        <Route path="/register" exact>
          <FrontDoorRegister />
        </Route>
        <Route path="/register/verify" exact>
          <FrontDoorVerify />
        </Route>
        <Route path="/login" exact>
          <FrontDoorLogin />
        </Route>
        <Route path="/forgot-password" exact>
          <FrontDoorForgotPassword />
        </Route>
        <Route path="/forgot-password/reset">
          <FrontDoorForgotPasswordReset />
        </Route>
        <Route path="/venue/:id" exact>
          <PublicVenue />
        </Route>
        <Route path="/venue/:id/service/:sid" exact>
          <PublicVenueBooking />
        </Route>
        <Route path="/*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </div>
  );
};

export default FrontDoorWrapper;
