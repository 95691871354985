import { useContext } from "react";
// import Button from "../../ui/button/button";
import AuthContext from "../../../store/auth-context";
import SplitseesLogo from "../../../globals/icons/splitseesLogo";
import SplitseesButton from "../../ui/splitsees-button/splitsees-button";

const FrontDoorRegister = (props) => {
  const ctx = useContext(AuthContext);
  return (
    <div className="front-door__wrapper__content__wrapper  front-door__register">
      <SplitseesLogo
        color="splitsees-purple"
        className="front-door__logo"
      />
      <div className="front-door__role-selection">
        <span>Sign up as:</span>
        <div className="splitsees__inputWrapper">
          <input
            type="radio"
            id="registerRoleMerchant"
            value="merchant"
            name="registerRole"
            onChange={ctx.merchantPlannerOnClick}
          ></input>
          <label htmlFor="registerRoleMerchant">Merchant</label>
        </div>
        <div className="splitsees__inputWrapper">
          <input
            type="radio"
            id="registerRolePlanner"
            name="registerRole"
            value="planner"
            onChange={ctx.merchantPlannerOnClick}
          ></input>
          <label htmlFor="registerRolePlanner">Planner</label>
        </div>
      </div>

      <fieldset>
        <div className="splitsees__inputWrapper">
          <input
            type="email"
            id="registerEmail"
            onChange={ctx.emailInputOnChange}
            placeholder="email address"
          ></input>
        </div>
        <div className="splitsees__inputWrapper">
          {/* <label htmlFor="registerFirstName">First Name *</label> */}
          <input
            type="text"
            id="registerFirstName"
            onChange={ctx.firstNameInputOnChange}
            placeholder="first name"
          ></input>
        </div>
        <div className="splitsees__inputWrapper">
          {/* <label htmlFor="registerFirstName">Last Name *</label> */}
          <input
            type="text"
            id="registerLastName"
            onChange={ctx.lastNameInputOnChange}
            placeholder="last name"
          ></input>
        </div>
        <div className="splitsees__inputWrapper">
          {/* <label htmlFor="registerPassword">Password *</label> */}
          <input
            type="password"
            id="registerPassword"
            onChange={ctx.passwordInputOnChnage}
            placeholder="desired password"
          ></input>
        </div>
        <div className="splitsees__inputWrapper">
          {/* <label htmlFor="registerVerifyPassword">Verify Password *</label> */}
          <input
            type="password"
            id="registerVerifyPassword"
            onChange={ctx.passwordVerifyOnChange}
            placeholder="confirm password"
          ></input>
        </div>
      </fieldset>
      <SplitseesButton className="tall" onClick={ctx.onRegister}>
        Sign Up
      </SplitseesButton>
    </div>
  );
};

export default FrontDoorRegister;
